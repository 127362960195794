/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from 'react';
import { useNavigate } from "react-router-dom";
import { useThemeStore } from '../../../../store';
import AdminSidebarHeader from "./admin-sidebar-header/admin-sidebar-header.component";
import AdminSidebarItem from "./admin-sidebar-item/admin-sidebar-item.component";
import "./admin-sidebar.styles.scss";


// The properties of each individual sidebar item.
export interface AdminSidebarItemProps {
    index: number;
    title: string;
    icon: string;
    color: string;
    active: boolean;
    link: string;
    inFooter: boolean;
    onClick?: () => void;
}

// The properties that should be passed to the sidebar component.
export interface AdminSidebarProps {
    items: AdminSidebarItemProps[];
}

const AdminSidebarComponent = (props: AdminSidebarProps) => {

    const navigate = useNavigate();
    const themeStore = useThemeStore();

    const handleSidebarItemClick = (item: AdminSidebarItemProps): void => {
        if (item.onClick) {
            item.onClick();
        }

        navigate(item.link);
    }

    return (
        <div className={`admin-layout-sidebar`}>
            <AdminSidebarHeader />

            {
                props.items.map((item) => {
                    if (!item.inFooter) {
                        return (
                            <AdminSidebarItem
                                title={item.title}
                                icon={item.icon}
                                color={item.color}
                                active={item.active}
                                onClick={() => handleSidebarItemClick(item)}
                                key={item.index}
                            />
                        );
                    }
                })
            }

            <div className="admin-sidebar-footer">
                {
                    props.items.map((item) => {
                        if (item.inFooter) {
                            return (
                                <AdminSidebarItem
                                    title={item.title}
                                    icon={item.icon}
                                    color={item.color}
                                    active={item.active}
                                    onClick={() => handleSidebarItemClick(item)}
                                    key={item.index}
                                />
                            );
                        }
                    })
                }
            </div>
        </div>
    );
}

export default AdminSidebarComponent;