import React from "react";
import {LoaderBase} from "./styles";

const CloudFormerLoader = () => {
    return (
        <LoaderBase>
            <div className="cloudFormercontainer">

                <div className="cloudFormerh1Container">

                    <div className="cloudFormercube h1 w1 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w1 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w1 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w2 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w2 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w2 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w3 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w3 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h1 w3 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>
                </div>

                <div className="cloudFormerh2Container">

                    <div className="cloudFormercube h2 w1 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w1 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w1 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w2 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w2 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w2 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w3 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w3 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h2 w3 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>
                </div>

                <div className="cloudFormerh3Container">

                    <div className="cloudFormercube h3 w1 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w1 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w1 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w2 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w2 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w2 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w3 l1">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w3 l2">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>

                    <div className="cloudFormercube h3 w3 l3">
                        <div className="cloudFormerface cloudFormertop"></div>
                        <div className="cloudFormerface cloudFormerleft"></div>
                        <div className="cloudFormerface cloudFormerright"></div>
                    </div>
                </div>
            </div>
        </LoaderBase>
    )
}

export default CloudFormerLoader