import * as React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {CenteredBase} from "./styles";
import Loader from "../../../components/Loader/Loader";
import ConnectorsApiService from "../services/ConnectorsApi.service";
import DataSharingService from "../../../services/DataSharing.service";

class ConnectorsServiceAccount extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.makeApiRequest(this.props.token.token);
    }

    makeApiRequest(access_token) {
        ConnectorsApiService.apiRequest(
            access_token,
            this.props.requestUrl,
            this.props.requestBody,
            this.props.selectedSchema.HTTP_request
        ).then((result) => {
            DataSharingService.setObject(result, 'requestOutput');
            this.props.navigate('/connectors/request-output');
        });
    }

    render() {
        return (
            <CenteredBase>
                <Loader message="Redirecting... Please be patient while you will be redirected."></Loader>
            </CenteredBase>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    const requestUrl = DataSharingService.getAttribute('requestUrl');
    const requestBody = DataSharingService.getObject('requestBody');
    const selectedSchema = DataSharingService.getObject('selectedSchema');
    return <ConnectorsServiceAccount
        navigate={navigate}
        token={useLocation().state.token}
        requestUrl={requestUrl}
        requestBody={requestBody}
        selectedSchema={selectedSchema}
    />;
}
export default WithNavigate;