import * as React from 'react';
import {CenteredBase} from "./styles";
import Loader from "../../../components/Loader/Loader";
import {useNavigate, useSearchParams} from "react-router-dom";
import ConnectorsApiService from "../services/ConnectorsApi.service";
import DataSharingService from "../../../services/DataSharing.service";
import {useEffect} from "react";

class ConnectorsOAuth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            config: props.config,
            authCode: props.authcode,
            selectedSchema: props.selectedSchema,
            requestUrl: props.requestUrl,
            requestBody: props.requestBody,
        }
    }

    componentDidMount() {
        this.requestToken().then((result) => {
            this.makeApiRequest(result['access_token']);
        });
    }

    async requestToken(){
        return await ConnectorsApiService.exchangeCodeForAccessToken(
            this.state.authCode,
            this.state.config.client_id,
            this.state.config.client_secret,
            'https://staging-devote.springbokagency.com/connectors/oauthorization',
            'authorization_code'
        );
    }

    makeApiRequest(access_token) {
        ConnectorsApiService.apiRequest(
            access_token,
            this.state.requestUrl,
            this.state.requestBody,
            this.state.selectedSchema.HTTP_request
        ).then((result) => {
            DataSharingService.setObject(result, 'requestOutput');
            this.props.navigate('/connectors/request-output');
        });
    }

    render() {
        return (
            <CenteredBase>
                <Loader message="Redirecting... Please be patient while you will be redirected."></Loader>
            </CenteredBase>
        );
    }
}
function WithNavigate() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const authCode = searchParams.get('code');
    const config = DataSharingService.getObject('config');
    const requestUrl = DataSharingService.getAttribute('requestUrl');
    const requestBody = DataSharingService.getObject('requestBody');
    const selectedSchema = DataSharingService.getObject('selectedSchema');
    let redirect = false;
    useEffect(() => {
        if ((config === undefined || config === null) || (authCode === undefined || authCode === null)) {
            navigate('/connectors/source-selection', {
                state: {
                    messages: [
                        {severity: 'error', summary: 'Something went wrong', detail: 'Token cannot be requested. Try again.', life: 3000}
                    ]
                }
            });
            redirect = true;
        }
    });
    if (redirect) { return; }
    return <ConnectorsOAuth
        navigate={navigate}
        config={config}
        requestUrl={requestUrl}
        requestBody={requestBody}
        selectedSchema={selectedSchema}
        authcode={authCode}/>
}

export default WithNavigate
