import * as React from 'react';
import {useNavigate} from "react-router-dom";
import DataSharingService from "../../../services/DataSharing.service";
import {DisplayBody, FitBody} from "./styles";

class ConnectorsRequestOutput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            requestOutput: props.requestOutput,
        }
    }

    componentDidMount() {
        let requestBodyElement = document.getElementById("request_output");
        if (requestBodyElement) {
            requestBodyElement.innerHTML = this.highLightSyntax(JSON.stringify(this.state.requestOutput, null, 4));
        }
    }

    highLightSyntax(json) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            let cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }

    render(){
        return (
            <FitBody>
                <h1 style={{color:"black"}}>Request Output:</h1>
                <DisplayBody>
                    <code id={"request_output"}></code>
                </DisplayBody>
            </FitBody>
        );
    }
}
function WithNavigate() {
    return <ConnectorsRequestOutput requestOutput={DataSharingService.getObject('requestOutput')} navigate={useNavigate()}/>
}

export default WithNavigate