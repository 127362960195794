import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import { useRoles } from "../../../hooks/use-roles.hook";
import { useUsers } from "../../../hooks/use-users";
import { UserEntry } from "../../../pages/users/admin-users.component";
import DialogTitle from "../dialog-title";
import "../dialog.styles.scss";

export interface RolePermission {
    id: string;
    name: string;
    enabled: boolean;
    description: string;
}

export interface UserRole {
    id: string;
    name: string;
    description: string;
    permissions: RolePermission[];
}

export interface CreateUserData extends UserEntry {
    chosen_roles: {
        id: string;
        value: string;
        checked: boolean;
    }[];
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    createUser: (data: UserEntry) => Promise<void>;
}

const CreateUserDialog = ({ isOpen, onClose, createUser }: Props) => {
    const { data, updateField } = useDialogData<CreateUserData>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        roles: [],
        chosen_roles: [],
        id: "",
    });
    const { roles } = useRoles();

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Create User"
                    description="Add a new user to the Devote platform."
                    icon="pi pi-user-plus"
                />
            }
            visible={isOpen}
            style={{ width: "45rem" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(
                                data,
                                ["firstName", "lastName", "email", "password"],
                                onClose,
                                createUser
                            );
                        }}
                    >
                        Create
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="user-first-name" className="block">
                            First name
                        </label>
                        <InputText
                            id="user-first-name"
                            aria-describedby="user-first-name"
                            onChange={(e) => {
                                updateField("firstName", e.target.value);
                            }}
                            value={data.firstName}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="user-last-name" className="block">
                            Last name
                        </label>
                        <InputText
                            id="user-last-name"
                            aria-describedby="user-last-name"
                            onChange={(e) => {
                                updateField("lastName", e.target.value);
                            }}
                            value={data.lastName}
                        />
                    </div>
                </div>

                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="user-email" className="block">
                            Email
                        </label>
                        <InputText
                            id="user-email"
                            aria-describedby="user-email"
                            onChange={(e) => {
                                updateField("email", e.target.value);
                            }}
                            value={data.email}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="user-password" className="block">
                            Password
                        </label>
                        <InputText
                            id="user-password"
                            aria-describedby="user-password"
                            type="password"
                            onChange={(e) => {
                                updateField("password", e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="roles-selector input-field">
                    <label htmlFor="roles" className="block">
                        Roles
                    </label>

                    <div className="roles-selector__container">
                        {roles.map((role) => {
                            const isChecked =
                                data.chosen_roles.find((r) => r.value === role.name)?.checked ||
                                false;

                            return (
                                <div className="roles-selector__item" key={role.name}>
                                    <label htmlFor={`checkbox-${role.name}`}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${role.name}`}
                                            checked={isChecked}
                                            onChange={(e) => {
                                                const roles = data.chosen_roles.filter(
                                                    (r) => r.value !== role.name
                                                );
                                                roles.push({
                                                    id: role.id,
                                                    value: role.name,
                                                    checked: e.target.checked,
                                                });
                                                updateField("chosen_roles", roles);
                                            }}
                                        />
                                        <span className="p-checkbox-label">{role.name}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateUserDialog;