import * as React from 'react';
import {Button} from "primereact/button";
import { Dialog} from 'primereact/dialog';
import {CenteredBase, DivRow} from "./styles";
import {useNavigate, useLocation} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import ConnectorsApiService from "../services/ConnectorsApi.service";
import {InputText} from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import {Toast} from "primereact/toast";

class ConnectorsSourceConfig extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            setOpen: true,
            sourceConfigs: null,
            selectedSource: props.selectedSource,
            selectedPreset: null,
            isDragOver: false,
            setIsDragOver: false,
            serviceAccount: null,
            fileName: '',
            config: {
                presetName: '',
                start_date: '',
                end_date: '',
                client_id: '',
                client_secret: '',
                scope: '',
                api_key: ''
            }
        }
    }

    async componentDidMount() {
        await ConnectorsApiService.getConfigFromSource(this.state.selectedSource.id).then(
            configs => {
                this.setState({sourceConfigs: configs});
            }
        )
    }

    save = async () => {
        if (this.state.config.presetName === '' || this.state.config.start_date === '' || this.state.config.end_date === '') {
            this.showErrorMessage('Check preset name, start and date or contact your provider.');
            return;
        }
        if ((this.state.config.client_id === '' && this.state.config.client_secret === '' && this.state.config.scope === '') &&
            this.state.config.api_key === '') {
            this.showErrorMessage('Provide at least one authentication method.');
            return;
        }
        if ((this.state.config.client_id !== '' || this.state.config.client_secret !== '' || this.state.config.scope !== '') && (this.state.config.client_id === '' || this.state.config.client_secret === '' || this.state.config.scope === '')) {
            this.showErrorMessage('Enter all values for OAuth 2.0 or use Api key.');
            return;
        }
        await ConnectorsApiService.createPreset(
            this.state.config.presetName,
            this.state.config.start_date,
            this.state.config.end_date,
            this.state.config.client_id,
            this.state.config.client_secret,
            this.state.config.scope,
            this.state.config.api_key,
            this.state.selectedSource.id
        );
        this.submit();
    }

    showErrorMessage(detail) {
        this.toast.show({severity:'error', summary: 'Failed saving preset', detail: detail, life: 4000});
    }

    submit = () => {
        if (this.state.serviceAccount) {
            this.props.navigate('/connectors/schema-config', { state: { serviceAccount: this.state.serviceAccount, selectedSource: this.state.selectedSource}});
        } else {
            this.props.navigate('/connectors/schema-config', { state: { config: this.state.config, selectedSource: this.state.selectedSource}});
        }
    }

    handleClose = () => {
        this.setState({setOpen: false, open: false});
        this.props.navigate('/connectors/source-selection');
    }

    clearFields = () => {
        this.setState({
            config: {
                presetName: '',
                start_date: '',
                end_date: '',
                client_id: '',
                client_secret: '',
                scope: '',
                api_key: ''
            }
        });
        this.setState({
            selectedPreset: null
        });
    }

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({
            setIsDragOver: true
        });
    };

    handleDragLeave = (event) => {
        event.preventDefault();
        this.setState({
            setIsDragOver: false
        });
    };

    handleDrop = (event) => {
        event.preventDefault();
        this.setState({
            setIsDragOver: false,
            serviceAccount: event.dataTransfer.files[0],
            fileName: event.dataTransfer.files[0].name
        });
    };

    handleChangeOnFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const fileContent = event.target.result;
            this.setState({
                serviceAccount: fileContent,
                fileName: file.name
            });
        };
        reader.readAsText(file);
    }


    handleChangeOnSelect = (event) => {
        this.setState({ selectedPreset: event.target.value });
        if (!event.target.value) { return }
        this.setState({
            config: {
                presetName: event.target.value.presetName,
                start_date: new Date(event.target.value.config.start_date),
                end_date: new Date(event.target.value.config.end_date),
                client_id: event.target.value.config["api_credentials"].client_id,
                client_secret: event.target.value.config["api_credentials"].client_secret,
                scope: event.target.value.config["api_credentials"].scope,
                api_key: event.target.value.config["api_credentials"].api_key
            }
        });
    }

    render() {
        return (
            <CenteredBase>
                <Toast ref={(el) => this.toast = el}/>
                <CenteredBase>
                    <Dialog header={`${this.state.selectedSource.name} - Configuration`} style={{ width: '40vw' }} visible={this.state.open} onHide={this.handleClose}>
                        <p style={{marginBottom: "8px", fontSize: "13px"}}><i>If a service account is provided, it will be used. Else the oauth 2.0 method will be used.</i></p>
                        <h4 style={{marginBottom: "2px"}}>Preset</h4>
                        <DivRow>
                            <div className="field" style={{display: "flex", flexDirection: "column", paddingRight: "16px"}}>
                                <label style={{marginBottom: "4px"}}>Select preset</label>
                                <Dropdown value={this.state.selectedPreset}
                                          options={this.state.sourceConfigs}
                                          onChange={(e) => this.handleChangeOnSelect(e)}
                                          placeholder="Select a preset"
                                          optionLabel="presetName"
                                          filter
                                          showClear
                                          filterBy="presetName"/>
                            </div>

                            <div className="field" style={{display: "flex", flexDirection: "column"}}>
                                <label htmlFor="presetName" style={{marginTop: "4px", marginBottom: "4px"}}>Preset name</label>
                                <InputText id="presetName"
                                           value={this.state.config.presetName}
                                           style={{ color: !!this.state.selectedPreset ? 'white !important' : 'black !important' }}
                                           onChange={(event) => {
                                               this.setState({
                                                   config: {
                                                       ...this.state.config,
                                                       presetName: event.target.value
                                                   }
                                               });
                                           }}
                                           disabled={!!this.state.selectedPreset}
                                           placeholder="Preset name"/>
                            </div>
                        </DivRow>

                        <h4 style={{marginTop: "6px", marginBottom: "2px"}}>Range</h4>
                        <DivRow>
                            <div className="field" style={{display: "flex", flexDirection: "column", marginRight: "14px"}}>
                                <label style={{marginBottom: "4px"}}>Start date</label>
                                <Calendar id="start_date"
                                          value={this.state.config.start_date}
                                          onChange={(event) => {
                                              this.setState({
                                                  config: {
                                                      ...this.state.config,
                                                      start_date: event.target.value
                                                  }
                                              });
                                          }}
                                          showIcon
                                          placeholder="Start date"/>
                            </div>

                            <div className="field" style={{display: "flex", flexDirection: "column"}}>
                                <label style={{marginTop: "4px", marginBottom: "4px"}}>End date</label>
                                <Calendar id="end_date"
                                          value={this.state.config.end_date}
                                          onChange={(event) => {
                                              this.setState({
                                                  config: {
                                                      ...this.state.config,
                                                      end_date: event.target.value
                                                  }
                                              });
                                          }}
                                          showIcon
                                          placeholder="End date"/>
                            </div>
                        </DivRow>

                        <h4 style={{marginTop: "6px", marginBottom: "2px"}}>API credentials</h4>
                        <fieldset style={{borderRadius: 8 + "px"}}>
                            <legend>Service account</legend>
                            <div className="field" style={{display: "flex", flexDirection: "column"}}>
                                <div
                                    onDragOver={(event) => {
                                        this.handleDragOver(event);
                                    }}
                                    onDragLeave={(event) => {
                                        this.handleDragLeave(event);
                                    }}
                                    onDrop={(event) => {
                                        this.handleDrop(event);
                                    }}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "120px",
                                        border: "dashed 2px",
                                        borderColor: this.state.isDragOver ? "green" : "gray",
                                        borderRadius: "8px",
                                        padding: "16px",
                                        marginBottom: "16px",
                                    }}
                                >
                                    <p>Drop a .txt or .json file here, or click to select a file.</p>
                                    <input
                                        type="file"
                                        accept=".txt,.json"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            this.handleChangeOnFileSelect(event);
                                        }}
                                        ref={(input) => (this.fileInput = input)}
                                    />
                                    <Button onClick={() => this.fileInput.click()}>Select file</Button>
                                    <p style={{marginTop: "6px"}}>Selected file: {this.state.fileName}</p>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset style={{borderRadius: 8 + "px", marginTop: 12 + "px", }}>
                            <legend>OAuth 2.0</legend>
                            <div className="field" style={{display: "flex", flexDirection: "column"}}>
                                <label style={{marginTop: "4px", marginBottom: "4px"}}>Client ID</label>
                                <InputText id="client_id"
                                           value={this.state.config.client_id}
                                           onChange={(event) => {
                                               this.setState({
                                                   config: {
                                                       ...this.state.config,
                                                       client_id: event.target.value
                                                   }
                                               });
                                           }}
                                           placeholder="Client ID"/>
                            </div>

                            <div className="field" style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "8px",
                                marginBottom: "8px"
                            }}>
                                <label style={{marginTop: "4px", marginBottom: "4px"}}>Client secret</label>
                                <InputText id="client_secret"
                                           value={this.state.config.client_secret}
                                           onChange={(event) => {
                                               this.setState({
                                                   config: {
                                                       ...this.state.config,
                                                       client_secret: event.target.value
                                                   }
                                               });
                                           }}
                                           placeholder="Client secret"/>
                            </div>

                            <div className="field" style={{display: "flex", flexDirection: "column"}}>
                                <label style={{marginTop: "4px", marginBottom: "4px"}}>Scope</label>
                                <InputText id="scope"
                                           value={this.state.config.scope}
                                           onChange={(event) => {
                                               this.setState({
                                                   config: {
                                                       ...this.state.config,
                                                       scope: event.target.value
                                                   }
                                               });
                                           }}
                                           placeholder="Scope"/>
                            </div>
                        </fieldset>

                        <fieldset style={{borderRadius: 8 + "px", marginTop: 12 + "px", position: "relative" }}>
                            <legend>Api key</legend>
                            <div className="field" style={{display: "flex", flexDirection: "column", marginTop: "4px", marginBottom: "4px"}}>
                                <label>API key</label>
                                <InputText id="api_key"
                                           value={this.state.config.api_key}
                                           onChange={(event) => {
                                               this.setState({
                                                   config: {
                                                       ...this.state.config,
                                                       api_key: event.target.value
                                                   }
                                               });
                                           }}
                                           disabled
                                           placeholder="Api key"/>
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    top: -10,
                                    left: 0,
                                    width: "100%",
                                    height: "115%",
                                    backgroundColor: "rgba(128, 128, 128, 0.5)",
                                    display: "flex",
                                    color: "red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    borderRadius: 8 + "px"
                                }}
                            >
                                This feature is not supported.
                            </div>
                        </fieldset>

                        <div style={{display: "flex", justifyContent: "space-around", marginTop: "12px", paddingRight: "36px", paddingLeft: "36px"}}>
                            <Button onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={this.clearFields} style={{marginLeft:"5px", marginRight:"5px"}}>
                                Clear fields
                            </Button>
                            <Button onClick={this.save} disabled={!!this.state.selectedPreset} style={{marginRight:"5px"}}>
                                Save & Submit
                            </Button>
                            <Button onClick={this.submit}>
                                Submit
                            </Button>
                        </div>
                    </Dialog>
                </CenteredBase>
            </CenteredBase>
        );
    }
}

function WithNavigate() {
    return <ConnectorsSourceConfig selectedSource={useLocation().state} navigate={useNavigate()}/>
}

export default WithNavigate
