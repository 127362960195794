/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { useNavigate } from "react-router-dom";
import AdvantivMenuBar from '../../features/advantiv/components/advantiv-menu-bar.component';
import CloudFormerMenuBar from "../../features/cloudformer/components/cloudformer-menu-bar.component";
import './navbar.styles.scss'


class Navbar extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            activeTool: props.activeTool,
            activePage: props.activePage
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50px', backgroundColor: '#fff', borderBottom: '1px solid rgba(128, 128, 128, 0.5)' }}>

                {/*  This part is the logo  */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '50px', maxWidth: '50px', minHeight: '50px', maxHeight: '50px', backgroundColor: '#2e3236' }}>
                    { this.state.activeTool === "Advantiv" &&
                        <i className='fa-solid fa-arrows-to-eye' style={{ color: '#18c942'}} />
                    }
                    { this.state.activeTool === "Connectors" &&
                        <i className='pi pi-sitemap' style={{ color: '#d39c68'}} />
                    }
                    { this.state.activeTool === "CloudFormer" &&
                        <i className='pi pi-cloud' style={{ color: '#fff400'}} />
                    }
                </div>

                {/*  This part is the title of the app (Advantiv)  */}

                { this.state.activeTool === "Advantiv" && <AdvantivMenuBar /> }
                { this.state.activeTool === "CloudFormer" && <CloudFormerMenuBar /> }
            </div>
        )
    }
}

function WithNavigate(props) {
    return <Navbar activeTool={props.activeTool} activePage={props.activePage} navigate={useNavigate()}/>
}

export default WithNavigate;
