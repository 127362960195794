import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../../store";

const ProtectedRouteComponent = () => {

    const userIsLoggedIn = useAuthStore(state => state.isLoggedIn);

    return userIsLoggedIn ? (<Outlet />) : (<Navigate to="/login" />);
}

export default ProtectedRouteComponent;
