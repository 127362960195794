import {findInputError} from "../../utils/findInputError";
import {isFormInvalid} from "../../utils/isFormInvalid";
import {Controller, useFormContext} from 'react-hook-form';
import {AnimatePresence, motion} from 'framer-motion';
import {MdError} from 'react-icons/md';
import {Tooltip} from "primereact/tooltip";
import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";

interface InputProps {
    name: string
    type: string
    id: string
    description: string
    validationRules: Object
    options?: Array<string>
}
export const Input = (inputProps: InputProps) => {
    const {
        register,
        control,
        formState: {errors},
    } = useFormContext()

    const inputErrors = findInputError(errors, inputProps.id);
    const isInvalid = isFormInvalid(inputErrors);

    const parsePatternToRegex = (validationRules: Object) => {
        if (validationRules["pattern"]) {
            const pattern = validationRules["pattern"];
            if (typeof pattern.value === 'string') {
                validationRules["pattern"].value = RegExp(pattern.value);
            }
        }
        return validationRules;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '0.5rem',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <label style={{
                    fontSize: "14px",
                    color: "#333",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    display: "flex",
                    alignItems: "end"
                }}>
                    <span style={{marginRight: "16px"}}>{inputProps.name}</span>
                    <Tooltip target=".tooltip-target"/>
                    <span className="tooltip-target"
                          data-pr-tooltip={inputProps.description}>
                        <i className="pi pi-question-circle" style={{color: "orange"}}></i>
                    </span>
                </label>
                <AnimatePresence mode="wait" initial={false}>
                    {isInvalid && (
                        <InputError
                            message={inputErrors.error.message}
                            key={inputErrors.error.message}
                        />
                    )}
                </AnimatePresence>
            </div>
            {inputProps.type === "string" ? (
                <InputText
                    id={inputProps.id}
                    type="text"
                    style={{
                        backgroundColor: "#cccccc",
                        color: "black",
                        maxHeight: "40px",
                        height: "40px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    placeholder={inputProps.name}
                    {...register(inputProps.id, parsePatternToRegex(inputProps.validationRules))}
                />
            ): inputProps.type === "number" ? (
                <InputText
                    id={inputProps.id}
                    type="number"
                    style={{
                        backgroundColor: "#cccccc",
                        color: "black",
                        maxHeight: "40px",
                        height: "40px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                    placeholder={inputProps.name}
                    {...register(inputProps.id, parsePatternToRegex(inputProps.validationRules))}
                />
            ) : inputProps.type === "json" ? (
                <InputTextarea
                    id={inputProps.id}
                    cols={30}
                    style={{
                        backgroundColor: "#cccccc",
                        color: "black",
                        width: "100%",
                        height: "40px",
                        padding: "10px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        resize: "vertical"
                    }}
                    placeholder={inputProps.name}
                    {...register(inputProps.id, inputProps.validationRules)}
                />
            ) : inputProps.type === "select" ? (
                <Controller
                    name={inputProps.id}
                    control={control}
                    rules={inputProps.validationRules}
                    render={({ field }) => (
                        <Dropdown
                            {...field}
                            id={inputProps.id}
                            options={inputProps.options}
                            placeholder={inputProps.name}
                            style={{
                                backgroundColor: "#cccccc",
                                color: "black",
                                width: "100%",
                                height: "40px",
                                paddingTop: "4.5px",
                                verticalAlign: "middle",
                                display: "flex",
                                maxHeight: "40px",
                                borderRadius: "8px",
                                border: "1px solid #ccc",
                                fontSize: "14px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    )}
                />
            ) : null}
        </div>
    )
}

const InputError = ({message}) => {
    return (
        <motion.p style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            paddingRight: '1.2rem',
            paddingLeft: '1.2rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#ff2e2e',
            backgroundColor: '#ffd0d0',
            borderRadius: '0.4rem',
        }}
            {...framer_error}
        >
            <MdError/>
            {message}
        </motion.p>
    )
}

const framer_error = {
    initial: {opacity: 0, y: 10},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: 10},
    transition: {duration: 0.2},
}
