import { useEffect, useState } from "react";
import AdminTable from "../../components/admin-table/admin-table.component";
import AdminTitle from "../../components/admin-title/admin-title.component";
import { UserRole } from "../../components/dialogs/create-dialogs/create-user.component";
import EditRoleDialog from "../../components/dialogs/edit-dialogs/edit-role.component";
import CreateRoleDialog from "../../components/dialogs/create-dialogs/create-role.component";
import useTableFilter from "../../hooks/table-filter.hook";
import useDetailsPane from "../../hooks/use-details-pane";
import { useRoles } from "../../hooks/use-roles.hook";
import useCopyToClipboard from "../../hooks/copy-to-clipboard.hook";
import { DetailsPaneDataType } from "../../../../store/details-pane-store";
import "../../admin.styles.scss";

const AdminRolesPage = () => {
    const {
        roles,
        meta,
        createRole,
        removeRole,
        updateRole,
    } = useRoles();

    const [filterValue, setFilterValue] = useState<string>("");
    const tableEntries = useTableFilter(roles, filterValue);
    const detailsPane = useDetailsPane<UserRole>(DetailsPaneDataType.role, roles);
    const [createRoleDialogOpen, setCreateRoleDialogOpen] =
        useState<boolean>(false);
    const { copy } = useCopyToClipboard();

    useEffect(() => {
        // Get select query from url
        const urlParams = new URLSearchParams(window.location.search);
        const selectQuery = urlParams.get("select");

        // If select query is present, open details pane
        if (selectQuery) {
            const selectedRole = roles.some((role) => role.id === selectQuery);
            selectedRole && detailsPane.open(selectQuery);
        }
    }, [roles]);

    return (
        <>
            <div className="admin-layout-application-window">
                <AdminTitle
                    title="Manage Roles"
                    description="View, Create, Update and Delete roles within Devote."

                />

                <div className={`content-grid ${!detailsPane.isOpen && "collapsed"}`} style={{ marginTop: '15px', padding: '0px 50px' }}>
                    <div>
                        <AdminTable
                            column_names={[
                                {
                                    key: "name",
                                    header: "Role Name",
                                },
                                {
                                    key: "description",
                                    header: "Role Description",
                                },
                            ]}
                            value={tableEntries}
                            amountOfRows={meta?.take || 12}
                            onRowSelect={detailsPane.open}
                            selection={detailsPane.data}
                            selectedRowKey="id"
                            isActive={detailsPane.isOpen}
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            buttonLabel="Create Role"
                            buttonIcon="pi pi-plus"
                            onButtonClick={() => setCreateRoleDialogOpen(true)}
                        />

                    </div>

                    <div className="role-details-pane details-pane">
                        <div className="pane-title-container">
                            <p className="pane-title">Role Details</p>
                            <i className="pi pi-times" onClick={detailsPane.close}></i>
                        </div>

                        <div className="pane-segment">
                            <div className="top-row">
                                <div>
                                    <h3 className="pane-heading">Role</h3>
                                    <p>{detailsPane.data?.name}</p>
                                </div>

                                <div className="actions-container">
                                    <p className="edit-link" onClick={detailsPane.edit.open}>
                                        Edit
                                    </p>
                                    <p
                                        className="edit-link"
                                        onClick={() =>
                                            detailsPane.data && removeRole(detailsPane.data.id)
                                        }
                                    >
                                        Delete
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="pane-segment">
                            <h3 className="pane-heading">Description</h3>
                            <div className="identifier-container">
                                <p className="identifier">{detailsPane.data?.description}</p>
                            </div>
                        </div>

                        <div className="pane-segment">
                            <h3 className="pane-heading">Role Identifier</h3>
                            <div
                                className="identifier-container"
                                onClick={() => {
                                    detailsPane.data && copy(detailsPane.data?.id);
                                }}
                            >
                                <p className="identifier">{detailsPane.data?.id}</p>
                                <i className="pi pi-copy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CreateRoleDialog
                isOpen={createRoleDialogOpen}
                onClose={() => setCreateRoleDialogOpen(false)}
                createRole={createRole}
            />

            {detailsPane.data && (
                <EditRoleDialog
                    isOpen={detailsPane.edit.isOpen}
                    onClose={detailsPane.edit.close}
                    initialData={detailsPane.data}
                    updateRole={updateRole}
                />
            )}
        </>
    );
};

export default AdminRolesPage;