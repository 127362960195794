import React, { useCallback, useMemo, useState } from "react";
import "../../admin.styles.scss";
import "./admin-users.styles.scss";
import AdminTable, { GenericTableEntry } from "../../components/admin-table/admin-table.component";
import AdminTitle from "../../components/admin-title/admin-title.component";
import useTableFilter from "../../hooks/table-filter.hook";
import useCopyToClipboard from "../../hooks/copy-to-clipboard.hook";
import CreateUserDialog, { UserRole } from "../../components/dialogs/create-dialogs/create-user.component";
import EditUserDialog, { EditUserData } from "../../components/dialogs/edit-dialogs/edit-user.component";
import useDetailsPane from "../../hooks/use-details-pane";
import { DetailsPaneDataType } from "../../../../store/details-pane-store";
import { useUsers } from "../../hooks/use-users";

export interface UserEntry {
    firstName: string;
    lastName: string;
    id: string;
    email: string;
    roles: UserRole[];
    password?: string;
}

const AdminUsersPage = () => {
    const {
        meta,
        users,
        removeUser,
        createUser,
        updateUser,
        dataLoading,
        nextPage,
        previousPage,
        setPage,
    } = useUsers();

    const parsedEntries: GenericTableEntry[] = useMemo(() => {
        return users.map((entry) => {
            return {
                id: entry.id,
                user_name: `${entry.firstName} ${entry.lastName}`,
                email: entry.email,
            };
        });
    }, [users]);

    const [filterValue, setFilterValue] = useState<string>("");
    const tableEntries = useTableFilter(parsedEntries, filterValue);
    const [createUserDialogOpen, setCreateUserDialogOpen] =
        useState<boolean>(false);
    const [initialEditUserData, setInitialEditUserData] =
        useState<EditUserData | null>(null);

    const detailsPane = useDetailsPane<any>(DetailsPaneDataType.user, users);

    const { copy } = useCopyToClipboard();

    const initials = `${detailsPane.data?.firstName[0]}${detailsPane.data?.lastName[0]}`;

    const onOpenEdit = useCallback(() => {
        if (!detailsPane.data) return;

        setInitialEditUserData({
            ...detailsPane.data,
            roles: detailsPane.data.roles.map((role) => {
                return {
                    ...role,
                    value: role.name,
                    checked: true,
                };
            }),
        });

        detailsPane.edit.open();
    }, [detailsPane.data]);

    return (
        <>
            <div className="admin-layout-application-window">
                <AdminTitle
                    title="Manage Users"
                    description="View, Create, Update and Delete users within Devote."

                />

                <div className={`content-grid ${!detailsPane.isOpen && "collapsed"}`}  style={{ marginTop: '15px', padding: '0px 50px' }}>
                    <div>
                        <AdminTable
                            column_names={[
                                {
                                    key: "user_name",
                                    header: "User Name",
                                },
                                {
                                    key: "email",
                                    header: "Email",
                                },
                            ]}
                            value={tableEntries}
                            amountOfRows={meta?.take || 12}
                            onRowSelect={detailsPane.open}
                            selection={detailsPane.data}
                            isActive={detailsPane.isOpen}
                            selectedRowKey="id"
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            buttonLabel="Create User"
                            buttonIcon="pi pi-user-plus"
                            onButtonClick={() => setCreateUserDialogOpen(true)}
                        />
                    </div>
                    <div className="details-pane">
                        <div className="pane-title-container">
                            <p className="pane-title">User Details</p>
                            <i className="pi pi-times" onClick={detailsPane.close}></i>
                        </div>

                        <div className="about-user pane-segment">
                            <div className="user-entry">
                                <div className="top-row">
                                    <div className="user-data">
                                        <div className="initials-container">
                                            <p>{initials}</p>
                                        </div>

                                        <div className="details-container">
                                            <p className="name">
                                                {detailsPane.data?.firstName}{" "}
                                                {detailsPane.data?.lastName}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="actions-container">
                                        <p className="edit-link" onClick={onOpenEdit}>
                                            Edit
                                        </p>
                                        <p
                                            className="edit-link"
                                            onClick={() =>
                                                detailsPane.data && removeUser(detailsPane.data.id)
                                            }
                                        >
                                            Delete
                                        </p>
                                    </div>
                                </div>

                                <div className="email">
                                    <i className="pi pi-envelope" />
                                    <a href={`mailto:${detailsPane.data?.email}`}>
                                        {detailsPane.data?.email}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="user-identifier pane-segment">
                            <h3 className="pane-heading">User Identifier</h3>
                            <div
                                className="identifier-container"
                                onClick={() => {
                                    detailsPane.data && copy(detailsPane.data?.id);
                                }}
                            >
                                <p className="identifier">{detailsPane.data?.id}</p>
                                <i className="pi pi-copy" />
                            </div>
                        </div>

                        <div className="user-roles pane-segment">
                            <h3 className="pane-heading">User Roles</h3>
                            <div className="role-item">
                                <i className="pi pi-users" />
                                <p>Admin</p>
                            </div>
                            <div className="role-item">
                                <i className="pi pi-users" />
                                <p>User</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateUserDialog
                isOpen={createUserDialogOpen}
                onClose={() => setCreateUserDialogOpen(false)}
                createUser={createUser}
            />
            {initialEditUserData && (
                <EditUserDialog
                    isOpen={detailsPane.edit.isOpen}
                    onClose={detailsPane.edit.close}
                    initialData={initialEditUserData}
                    updateUser={updateUser}
                />
            )}
        </>
    );
};

export default AdminUsersPage;