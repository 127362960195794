import {create} from 'zustand';
import { devtools, persist } from "zustand/middleware";

export interface ThemeState {
    mode: 'light' | 'dark';
    sidebar: 'collapsed' | 'expanded';

    setSidebar: (mode: 'collapsed' | 'expanded') => void;

    toggle: () => void;
    toggleSidebar: () => void;
}

let themeStore: any = (set): ThemeState => ({
    mode: 'light',
    sidebar: 'expanded',

    setSidebar: (mode: 'collapsed' | 'expanded') => set((state) => ({ sidebar: mode })),
    toggle: () => set((state) => ({ mode: state.mode === 'light' ? 'dark': 'light' })),
    toggleSidebar: () => set((state) => ({ sidebar: state.sidebar === 'collapsed' ? 'expanded': 'collapsed' })),
});


themeStore = devtools(themeStore);
themeStore = persist(themeStore, { name: 'devote_theme_store' });

const useThemeStore = create(themeStore);

export default useThemeStore;
