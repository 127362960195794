/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import {create} from 'zustand';
import * as helpers from './auth-store-helpers';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';




// Defines the initial state of the store.
const initialState = {
    isLoggedIn: false,
}

// Create the store.
let authstore = create(
    persist(
        (set, get) => ({
            ...initialState,

            login: (accessToken, refreshToken) => {
                helpers.setAccessToken(accessToken);
                helpers.setRefreshToken(refreshToken);

                set({ 
                    isLoggedIn: true 
                });
            },

            logout: () => {
                set({
                    isLoggedIn: false
                })
            },

            reset: () => {
                set({
                    ...initialState
                });
            }
        }),
        {
            name: 'devote_auth_store',
            storage: createJSONStorage(() => localStorage)
        }
    )
);

const useAuthStore: any = authstore;


export default useAuthStore;
