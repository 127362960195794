import { Input } from "./Input";
import { FormProvider, useForm } from 'react-hook-form';
import {Button} from "primereact/button";
import ProductProperty from "../../types/ProductProperty";
import React from "react";

export const Form = ({
     products,
     selectedProduct,
     submitForm
}) => {
    const methods = useForm()
    const onSubmit = methods.handleSubmit(data => {
        methods.reset();
        submitForm(data);
    });

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={e => e.preventDefault()}
                noValidate
                autoComplete="off"
                className="container"
            >
                <fieldset style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    background: "#f0f0f0",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                }}>
                    <legend style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#333",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)"
                    }}>
                        {selectedProduct} Configuration
                    </legend>
                    <div style={{
                        display: 'grid',
                        gap: '1rem',
                        gridTemplateColumns: "1fr 1fr"
                    }}>
                        {products.map((productProperty: ProductProperty) => (
                            <Input key={productProperty.id} {...productProperty}/>
                        ))}
                    </div>
                </fieldset>
                <div style={{marginTop: "12px", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Button
                        onClick={onSubmit}
                        style={{
                            padding: '0.5rem',
                            borderRadius: '0.5rem',
                            fontWeight: 'bold',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            paddingLeft: '1.2rem',
                            paddingRight: '1.2rem'
                        }}
                    >
                        Initialize {selectedProduct}
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
