/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import AdminTitle from "../../components/admin-title/admin-title.component";
import ApplicationCard from "../../../../components/application-card/application-card.component";
import "./admin-home.styles.scss";

const AdminHomePage = () => {

    return (
        <div className="admin-layout-application-window">
            <AdminTitle
                title="Devote Control Panel"
                description="Manage the Devote Platform with the admin tools from here."
            />

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '80px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px', justifyContent: 'flex-start' }}>
                    <ApplicationCard link={'/admin/projects'} title={'Projects'} icon={'fa-solid fa-cube'} iconColor={'#737a7a'} description={"Administer projects within Springbok Devote"} />
                    <ApplicationCard link={'/admin/users'} title={'Users'} icon={'pi pi-fw pi-user'} iconColor={'#737a7a'} description={"Administer users within Springbok Devote"} />
                    <ApplicationCard link={'/admin/roles'} title={'Roles'} icon={'fa-solid fa-id-badge'} iconColor={'#737a7a'} description={'Administer roles within Springbok Devote'} />
                    <ApplicationCard link={'/admin/permissions'} title={'Permissions'} icon={'fa-solid fa-shield-cat'} iconColor={'#737a7a'} description={"Administer permissions within Springbok Devote"} />
                </div>
            </div>
        </div>
    );
};

export default AdminHomePage;