/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from 'react';
import * as helpers from './store/auth-store/auth-store-helpers';
import { IdentityService } from "./api/identity-service";
import { useAuthStore, useProjectStore, useCampaignStore } from "./store";

/**
 * Custom hooks that automatically refreshes the JWT before it expires.
 *
 * @returns {boolean} isRefreshing - A boolean indicating whether the JWT is currently being refreshed.
 */
export function useRefreshToken() {

    // Keeps track of whether the JWT is currently being refreshed.
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    // Use the auth store.
    const authStore = useAuthStore();
    const projectStore = useProjectStore();
    const campaignStore = useCampaignStore();

    // The useEffect hooks that sets up a timer to refresh the JWT before it expires.
    React.useEffect(() => {
        async function refreshToken(): Promise<void> {
            // Handle an edge case in which a second call is made while already refreshing.
            if (isRefreshing) {
                return;
            }

            // Set the indicator that the JWT is being refreshed.
            setIsRefreshing(true);

            const identityService = new IdentityService();
            const response = await identityService.refreshToken(helpers.getRefreshToken()!);

            // If the response is not ok, we can't refresh the JWT.
            if (!response.ok) {
                authStore.reset();
                projectStore.reset();
                campaignStore.reset();
                setIsRefreshing(false);
                return;
            }

            // Get the new access and refresh tokens from the response.
            const { access_token, refresh_token } = await response.json();

            // Store the new tokens in local storage.
            helpers.setAccessToken(access_token);
            helpers.setRefreshToken(refresh_token);

            // Set the timeout for the next refresh to 1 minute before the JWT expires.
            setTimeout(refreshToken, (calculateTimeRemaining(helpers.getAccessTokenDecoded()) * 1000 - (30 * 1000)));

            // Set the indicator that the JWT is no longer being refreshed.
            setIsRefreshing(false);
        }


        // Handle an edge case where the local storage values are not properly set.
        if (
            helpers.getAccessToken() === undefined || 
            helpers.getRefreshToken() === undefined ||
            helpers.getAccessToken() === 'undefined' ||
            helpers.getRefreshToken() === 'undefined' ||
            helpers.getAccessToken() === null ||
            helpers.getRefreshToken() === null
         ) {
            authStore.reset();
            projectStore.reset();
            campaignStore.reset();
            return;
        }

        // Check if the access token is already expired.
        if (calculateTimeRemaining(helpers.getAccessTokenDecoded() <= 0)) {
            refreshToken();
        } else {
            // Set the timeout for the next refresh to 30 seconds before the JWT expires.
            setTimeout(refreshToken, (calculateTimeRemaining(helpers.getAccessTokenDecoded()) * 1000 - (30 * 1000)));
        }
    }, []);

    return isRefreshing;
}


function calculateTimeRemaining(claims) {
    const currentTime = Math.floor(Date.now() / 1000);
    return claims.exp - currentTime;
}
