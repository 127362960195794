/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from 'react';
import { useNavigate } from "react-router-dom";
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import './project-select-list-item.styles.scss';


const ProjectSelectListItem = ({ project }) => {

    const projectStore = useProjectStore();
    const navigate = useNavigate()

    const handleSelectProject = (project: IProject) => {
        projectStore.setSelectedProject(project);

        // Navigate 0 does a hard refresh of the page.
        navigate(0);
    }

    return (
        <div className='project-select-list-item'>
            <i className='fa-solid fa-cube project-select-list-item-icon' />

            <div className='project-select-list-item-text-container'>
                <p className='project-select-list-item-name' onClick={() => handleSelectProject(project)}>{ project.name }</p>
                <p className='project-select-list-item-id'>{ project.id.split('.')[4] }</p>
            </div>

        </div>
    )
}

export default ProjectSelectListItem;
