/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { springbok_logo_white_yellow } from '../../../../assets';
import "./disclaimer.styles.scss";

export const Disclaimer = () => {

    return (
        <section className="footer">
            <div className="footer-container">
                <div className="footer-inner">
                    <img src={springbok_logo_white_yellow} alt='springbok_logo_yellow' />

                    <p>
                        <span>Disclaimer: </span>
                        Springbok Devote is still in the development phase, and some parts may be buggy or incomplete
                    </p>
                </div>
            </div>
        </section>
    )
}
