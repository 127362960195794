import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { IProject } from "../../../../interfaces/project.interface";
import { useProjectStore } from "../../../../store";
import { AdvantivService } from "../../../../api/advantiv-service";
import { CampaignType } from "../../models/campaign-type";
import { InputNumber } from "primereact/inputnumber";
import { ApiResponse } from "../../../../api/base/api-response";
import { ICampaign } from "../../models/campaign";
import { Calendar } from 'primereact/calendar';

const CreateCampaignDialogComponent = ({ setDialogVisible }) => {

    // Project.
    const project: IProject = useProjectStore(state => state.selectedProject)

    // Form state.
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

    // Form fields.
    const [campaignName, setCampaignName] = React.useState<string>('');
    const [campaignDescription, setCampaignDescription] = React.useState<string>('');
    const [campaignValue, setCampaignValue] = React.useState(10000);
    const [campaignType, setCampaignType] = React.useState<CampaignType>(CampaignType.BUDGET);
    const [campaignCountry, setCampaignCountry] = React.useState<string>('NL');
    const [campaignStartDate, setCampaignStartDate] = React.useState<Date>(new Date());

    // Form submission.
    const submitForm = () => {
        setFormIsSubmitting(true);

        const advantivService = new AdvantivService();
        advantivService.createCampaign({
            projectId: project.id,
            name: campaignName,
            description: campaignDescription,
            type: campaignType,
            value: campaignValue,
            countryCode: campaignCountry,
            date: campaignStartDate
        }).then((apiResponse: ApiResponse<ICampaign>) => {
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                location.reload();
            }
        })
    }

    return (
        <div style={{ width: '350px' }}>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Name:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Description:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)} />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Type:</p>
                <Dropdown

                    style={{ width: '100%' }}
                    value={campaignType}
                    options={[{ label:'Budget', value: "BUDGET"}, { label: 'Target', value: "TARGET"}]}
                    filter
                    filterBy="label"
                    placeholder="Type"
                    onChange={(e) => setCampaignType(e.target.value)}
                />
            </div>
            
            

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Type:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={campaignValue}
                    onChange={(e) => setCampaignValue(e.value!)}
                />
            </div>

            <div style={{ marginTop: '10px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Country:</p>
                <Dropdown
                    style={{ width: '100%' }}
                    value={campaignCountry}
                    options={[
                        {"label": "Netherlands", "value": "NL"},
                        {"label": "Belgium", "value": "BE"},
                        {"label": "United Kingdom", "value": "UK"}
                    ]}
                    filter
                    filterBy="label"
                    placeholder="Country"
                    onChange={(e) => setCampaignCountry(e.target.value)}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Start Date:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(campaignStartDate)}
                    onChange={(e) => {setCampaignStartDate(e.target.value as Date )}}
                />
            </div>


            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitForm()} />)
                }
                
             </div>
        </div>
    )
}

export default CreateCampaignDialogComponent;