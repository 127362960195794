/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import { IEnvironment } from "../environment/environment";
import { EnvironmentService } from "../environment/environment.service";
import { IProject } from "../interfaces/project.interface";
import { GenericMetaOptions } from "./identity-service";
import { MetaField } from "./utils/identity-hook.utils";
import { ApiResponse } from "./base/api-response";

/**
 * Wrapper around the resource management service API.
 */
export class ResourceManagementService {
    private readonly environment: IEnvironment;

    constructor() {
        this.environment = new EnvironmentService().getEnvironment();
    }

    public async getProjects(options?: GenericMetaOptions): Promise<{
        data: IProject[];
        meta: MetaField;
    }> {
        const serverResponse = await fetch(`${this.environment.backends.resourceManagementService}/projects?order=ASC&page=1&take=10`);
        const response = await serverResponse.json();
        const result = response.data as IProject[];

        // Store the amount of projects fetched.
        let page = response.meta.page;
        let pageCount = result.length;

        if (pageCount !== 0) {
            while (pageCount / page === 10) {
                const serverResponse = await fetch(`${this.environment.backends.resourceManagementService}/projects?order=ASC&page=${page + 1}&take=10`);
                const response = await serverResponse.json();
                result.push(...response.data as IProject[]);

                // Update fetch parameters.
                pageCount = result.length;
                page = response.meta.page;
            }
        }

        return response;
    }

    public async createProject(name: string, description: string): Promise<ApiResponse<IProject>> {
        const serverResponse = await fetch(
            `${this.environment.backends.resourceManagementService}/projects`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    displayName: name
                }),
            }
        );

        return new ApiResponse<IProject>(serverResponse);
    }

    public async removeProject(id: string): Promise<ApiResponse<null>> {
        const serverResponse = await fetch(
            `${this.environment.backends.resourceManagementService}/projects/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return new ApiResponse(serverResponse);
    }
}
