/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import LoginComponent from "./features/default/pages/login/login.component";
import { useRefreshToken } from "./refresh-token.hook";
import Homepage from "./features/default/pages/homepage/homepage.component";
import AppLayout from "./features/default/layouts/app-layout/app-layout.component";
import ProtectedRouteComponent from "./components/protected-route/protected-route.component";
import { useAuthStore, useThemeStore } from "./store";
import AdvantivHome from "./features/advantiv/pages/advantiv-home/advantiv-home.component";
import AdvantivCampaignOverview from "./features/advantiv/pages/advantiv-campaign-overview/advantiv-campaign-overview.component";
import AdvantivTvimpactFactor from "./features/advantiv/pages/advantiv-tvimpact-factor/advantiv-tvimpact-factor.component"
import SpringbokGPTHome from './features/springbok-gpt/pages/springbok-gpt-home.component'
import ConnectorsHome from "./features/connectors/pages/ConnectorsHome";
import ConnectorsSourceSelection from "./features/connectors/pages/ConnectorsSourceSelection";
import ConnectorsSourceConfig from "./features/connectors/pages/ConnectorsSourceConfig";
import ConnectorsSchemaConfig from "./features/connectors/pages/ConnectorsSchemaConfig";
import ConnectorsOAuth from "./features/connectors/pages/ConnectorsOAuth";
import ConnectorsRequestOutput from "./features/connectors/pages/ConnectorsRequestOutput";
import ConnectorsServiceAccount from "./features/connectors/pages/ConnectorsServiceAccount";
import ResetPasswordComponent from "./features/default/pages/reset-password/reset-password.component";
import CloudFormerMainPage from "./features/cloudformer/pages/cloudformer-mainpage.component";
import AdminHomePage from "./features/admin/pages/home/admin-home.component";
import AdminUsersPage from "./features/admin/pages/users/admin-users.component";
import AdminRolesPage from "./features/admin/pages/roles/admin-roles.component";
import AdminProjectsPage from "./features/admin/pages/projects/admin-projects.component";
import AdminPermissionsPage from "./features/admin/pages/permissions/admin-permissions.component";
import AdminLayout from "./features/default/layouts/admin-layout/admin-layout.component";


const App = () => {

    const isUserLoggedIn = useAuthStore(state => state.isLoggedIn);
    const themeStore = useThemeStore();

    // Leave this function here at the top of the component tree.
    // It will make sure access tokens are automatically refreshed.
    useRefreshToken();

    // This effect will trigger when the window is loaded and sets logic for automatic sidebar collapse.
    React.useEffect(() => {
        const handleResize = () => {
            if (window.outerWidth < 960 && themeStore.sidebar === 'expanded') {
                themeStore.setSidebar('collapsed');
            }
        };

        handleResize(); // set initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Routes>
            <Route exact path="/" element={<Navigate to="/login" /> }/>
            <Route exact path="/reset-password" element={<ResetPasswordComponent /> }/>
            <Route exact path='/login' element={isUserLoggedIn ? <Navigate to='/home' /> : <LoginComponent /> }/>


            <Route element={<ProtectedRouteComponent />}>
                <Route element={<AppLayout /> }>
                    <Route exact path='/home' element={<Homepage /> }/>
                    <Route path="/connectors" element={<ConnectorsHome/>}/>
                    <Route path="/connectors/source-selection" element={<ConnectorsSourceSelection/>}/>
                    <Route path="/connectors/source-config" element={<ConnectorsSourceConfig/>}/>
                    <Route path="/connectors/schema-config" element={<ConnectorsSchemaConfig/>}/>
                    <Route path="/connectors/oauthorization" element={<ConnectorsOAuth/>}/>
                    <Route path="/connectors/service-account" element={<ConnectorsServiceAccount/>}/>
                    <Route path="/connectors/request-output" element={<ConnectorsRequestOutput/>}/>
                    <Route path="/cloudformer" element={<CloudFormerMainPage/>}/>
                    <Route exact path='/springbok-gpt' element={<SpringbokGPTHome /> } />
                    <Route path='/springbok-gpt/:id' element={<SpringbokGPTHome /> } />
                    <Route exact path='/advantiv' element={<AdvantivHome /> }/>
                    <Route exact path='/advantiv/campaign' element={<AdvantivCampaignOverview /> }/>
                    <Route exact path='/advantiv/tv-impactfactor' element={<AdvantivTvimpactFactor /> }/>
                    <Route exact path='/settings' element={<Homepage /> }/>
                    <Route exact path='/help' element={<Homepage /> }/>
                </Route>

                <Route element={<AdminLayout />}>
                    <Route exact path="/admin" element={<Navigate to="/admin/home" /> }/>
                    <Route exact path='/admin/home' element={<AdminHomePage /> }/>
                    <Route exact path='/admin/projects' element={<AdminProjectsPage /> }/>
                    <Route exact path='/admin/users' element={<AdminUsersPage /> }/>
                    <Route exact path='/admin/roles' element={<AdminRolesPage /> }/>
                    <Route exact path='/admin/permissions' element={<AdminPermissionsPage /> }/>
                </Route>
            </Route>

            <Route path="*" element={<Navigate to="/home" /> }/>
        </Routes>
    );
};

export default App;
