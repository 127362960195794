import { useCallback, useState } from "react";

export default function useDialogData<T>(initialValue: T) {
    const [data, setData] = useState<T>(initialValue);

    const updateField = useCallback((fieldName: string, value: any) => {
        setData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    }, []);

    return {
        data,
        updateField,
        setData,
    };
}

export function validateData<T>(requiredFields: string[], data: T) {
    return requiredFields.every((field) => {
        // check if not undefined, null or empty string when trimmed
        return (
            data[field] !== undefined &&
            data[field] !== null &&
            data[field].toString().trim() !== ""
        );
    });
}

export const submitData = <T>(
    data: T,
    requiredFields: string[],
    onClose: () => void,
    cb?: (data: T) => Promise<any>
) => {
    if (!validateData(requiredFields, data)) {
        alert(`Missing required fields.`);
        return;
    }
    cb && cb(data).then(() => onClose());
};