/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import { IEnvironment } from "../environment/environment";
import { EnvironmentService } from "../environment/environment.service";
import {
    CreateUserData,
    RolePermission,
    UserRole,
} from "../features/admin/components/dialogs/create-dialogs/create-user.component";
import { UserEntry } from "../features/admin/pages/users/admin-users.component";
import { genericGetRequest, getAccessToken, getDefaultHeaders } from "./utils";

export interface GenericMetaOptions {
    take?: number;
    page?: number;
    order?: "ASC" | "DESC";
}

export enum ENDPOINTS {
    USERS = "users",
    ROLES = "roles",
    PERMISSIONS = "permissions",
}

/**
 * Wrapper around the identity service API.
 */
export class IdentityService {
    private readonly environment: IEnvironment;

    constructor() {
        this.environment = new EnvironmentService().getEnvironment();
    }

    private async genericGetRequest<T>(
        uri: string,
        options?: GenericMetaOptions
    ): Promise<T> {
        const url = new URL(`${this.environment.backends.identityService}/${uri}`);
        if (options) {
            Object.keys(options).forEach((key) =>
                url.searchParams.append(key, options[key] as string)
            );
        }

        // @ts-ignore
        return genericGetRequest<T>(url.toString());
    }
    private async genericPaginatedGetRequest<T>(
        uri: string,
        options?: GenericMetaOptions
    ) {
        const url = new URL(`${this.environment.backends.identityService}/${uri}`);
        if (options) {
            Object.keys(options).forEach((key) =>
                url.searchParams.append(key, options[key] as string)
            );
        }

        const result = await genericGetRequest<T>(url.toString());

        return {
            data: result.data as T,
            meta: result.meta,
        };
    }

    public async login(username: string, password: string): Promise<Response> {
        return await fetch(
            `${this.environment.backends.identityService}/oauth/token`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    username: username,
                    password: password,
                    client_id: this.environment.oauth.clientId,
                    grant_type: "password",
                }),
            }
        );
    }

    public async refreshToken(refreshToken: string): Promise<Response> {
        return await fetch(
            `${this.environment.backends.identityService}/oauth/token`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    refresh_token: refreshToken,
                    client_id: this.environment.oauth.clientId,
                    grant_type: "refresh_token",
                }),
            }
        );
    }

    public async getUser(id: string) {
        return await this.genericGetRequest<UserEntry>(`users/${id}`);
    }

    public async getUsers(options?: GenericMetaOptions) {
        return await this.genericPaginatedGetRequest<UserEntry[]>("users", options);
    }

    public async createUser(user: CreateUserData) {
        const response = await fetch(
            `${this.environment.backends.identityService}/users`,
            {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify(user),
            }
        );

        const result = await response.json();
        if (result.error) {
            throw Error(result.error);
        }

        for (const role of user.chosen_roles) {
            if (!role.id || !role.checked) continue;
            await this.addRoleToUser(result.id, role.id);
        }

        return result;
    }

    public async addRoleToUser(userId: string, roleId: string) {
        return await fetch(
            `${this.environment.backends.identityService}/users/${userId}/roles/${roleId}`,
            {
                method: "POST",
                headers: getDefaultHeaders(),
            }
        );
    }

    public async updateUser(user: UserEntry) {
        alert("No API route for this action yet.");
    }

    public async deleteUser(id: string) {
        return await fetch(
            `${this.environment.backends.identityService}/users/${id}`,
            {
                method: "DELETE",
                headers: getDefaultHeaders(),
            }
        );
    }

    public async getRoles(options?: GenericMetaOptions) {
        return await this.genericPaginatedGetRequest<UserRole[]>("roles", options);
    }

    public async createRole(role: UserRole) {
        return await fetch(`${this.environment.backends.identityService}/roles`, {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify(role),
        });
    }

    public async removePermissionFromRole(roleId: string, permissionId: string) {
        return await fetch(
            `${this.environment.backends.identityService}/roles/${roleId}/permissions/${permissionId}`,
            {
                method: "DELETE",
                headers: getDefaultHeaders(),
            }
        );
    }

    public async addPermissionToRole(roleId: string, permissionId: string) {
        return await fetch(
            `${this.environment.backends.identityService}/roles/${roleId}/permissions/${permissionId}`,
            {
                method: "POST",
                headers: getDefaultHeaders(),
            }
        );
    }

    public async updateRole(role: UserRole) {
        // Get all current permissions for the role
        const currentRole = (await this.getRoles()).data.find(
            (r) => r.id === role.id
        );

        if (!currentRole) {
            throw Error("Role not found");
        }

        const removedPermissions = currentRole.permissions.filter(
            (p) => !role.permissions.map((p) => p.id).includes(p.id)
        );

        const addedPermissions = role.permissions.filter(
            (p) => !currentRole.permissions.map((p) => p.id).includes(p.id)
        );

        // Remove all permissions that are not in the new role
        for (const permission of removedPermissions) {
            await this.removePermissionFromRole(role.id, permission.id);
        }

        // Add all permissions that are not in the current role
        for (const permission of addedPermissions) {
            await this.addPermissionToRole(role.id, permission.id);
        }

        return await fetch(
            `${this.environment.backends.identityService}/roles/${role.id}`,
            {
                method: "PUT",
                headers: getDefaultHeaders(),
                body: JSON.stringify(role),
            }
        );
    }

    public async deleteRole(id: string) {
        return await fetch(
            `${this.environment.backends.identityService}/roles/${id}`,
            {
                method: "DELETE",
                headers: getDefaultHeaders(),
            }
        );
    }

    private async getPermissions(options?: GenericMetaOptions) {
        return await this.genericPaginatedGetRequest<RolePermission[]>(
            `permissions`,
            options
        );
    }

    private async createPermission(permission: RolePermission) {
        return await fetch(
            `${this.environment.backends.identityService}/permissions`,
            {
                method: "POST",
                headers: getDefaultHeaders(),
                body: JSON.stringify(permission),
            }
        );
    }

    private async updatePermission(permission: RolePermission) {
        return await fetch(
            `${this.environment.backends.identityService}/permissions/${permission.id}`,
            {
                method: "PUT",
                headers: getDefaultHeaders(),
                body: JSON.stringify(permission),
            }
        );
    }

    private async deletePermission(id: string) {
        return await fetch(
            `${this.environment.backends.identityService}/permissions/${id}`,
            {
                method: "DELETE",
                headers: getDefaultHeaders(),
            }
        );
    }

    public async callIntrospect() {
        const token = getAccessToken();
        if (!token) {
            throw Error("No token found.");
        }

        return await fetch(
            `${this.environment.backends.identityService}/oauth/introspect`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    token,
                }),
            }
        );
    }

    public async resetPassword(passwordResetCode, newPassword): Promise<Response> {
        return await fetch(`${this.environment.backends.identityService}/users/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                passwordResetToken: passwordResetCode,
                password: newPassword,
            })
        });
    }

    // Map for the get, create, update and delete actions for each endpoint.
    // Used in /src/api/utils/identity-hook-utils.ts
    public endpoints = {
        [ENDPOINTS.PERMISSIONS]: {
            get: this.getPermissions.bind(this),
            create: this.createPermission.bind(this),
            update: this.updatePermission.bind(this),
            delete: this.deletePermission.bind(this),
        },
        [ENDPOINTS.ROLES]: {
            get: this.getRoles.bind(this),
            create: this.createRole.bind(this),
            update: this.updateRole.bind(this),
            delete: this.deleteRole.bind(this),
        },
        [ENDPOINTS.USERS]: {
            get: this.getUsers.bind(this),
            create: this.createUser.bind(this),
            update: this.updateUser.bind(this),
            delete: this.deleteUser.bind(this),
        },
    };
}

export const identityService = new IdentityService();
