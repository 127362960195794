import * as React from 'react';

/* This class serves as a sharing service to share data via localstorage. */
class DataSharingService extends React.Component {

    /**
     * It takes a value and a value name, converts the value to a string, and stores it in the browser's local storage.
     * @param value - The value you want to store.
     * @param valueName - The name of the value you want to store.
     */
    static setObject(value, valueName) {
        window.localStorage.setItem(valueName, JSON.stringify(value));
    }

    /**
     * If the valueName exists in localStorage, return the valueName as a JSON object, otherwise return null.
     * @param valueName - The name of the value you want to get from local storage.
     * @returns The value of the key in localStorage.
     */
    static getObject(valueName) {
        let dataObject = window.localStorage.getItem(valueName);
        return dataObject !== undefined ? JSON.parse(dataObject) : null;
    }

    /**
     * It takes two arguments, a value and a value name, and then sets the value name to the value
     * @param value - The value you want to store.
     * @param valueName - The name of the value you want to set.
    */
    static setAttribute(value, valueName) {
        window.localStorage.setItem(valueName, value);
    }

    /**
     * It returns the value of the attribute with the name specified by the valueName parameter
     * @param valueName - The name of the value you want to get.
     * @returns The value of the attribute.
     */
    static getAttribute(valueName) {
        return window.localStorage.getItem(valueName);
    }
}
export default DataSharingService;