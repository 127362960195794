import styled, {keyframes} from "styled-components";

export const LoaderBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const eatLight = keyframes`
  0% , 49% { border-right-color:red }
  50% , 100% { border-right-color: transparent }
`;

const moveLight = keyframes`
  0% { left: 75px ; opacity: 1}
  50% { left: 0; opacity: 1 }
  52%, 100% { left: -5px; opacity: 0; }
`;

export const LoaderIcon = styled.span`
  position: relative;
  border: 24px solid red;
  border-radius: 50%;
  box-sizing: border-box;
  animation: ${eatLight} 1s linear infinite;
  ::after , ::before {
    content: '';
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-sizing: border-box;
    opacity: 0;
    animation: ${moveLight} 2s linear infinite;
  }
  ::before {
    animation-delay: 1s;
  }
`;