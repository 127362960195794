/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import Navbar from "../../../../components/navbar/navbar.component"
import TvImpactSettings from "../settings.json"
import { AdvantivService } from "../../../../api/advantiv-service";
import { PaginatedResponse } from "../../../../api/base/paginated-response";
import { ApiResponse } from "../../../../api/base/api-response";
import { EditImpactFactorRequest } from "../../models/EditImpactFactorRequest";
import { IChannel, IImpactFactor } from "../../models/campaign";
import { IProject } from "../../../../interfaces/project.interface";
import useProjectStore from "../../../../store/project-store/project-store";
import { ProgressSpinner } from "primereact/progressspinner";


const AdvantivTvimpactFactor = () => {

    const toast = React.useRef(null);
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);

    const [globalFilter, setGlobalFilter] = React.useState('');
    const [loadProjectTvBaselines, setLoadProjectTvBaselines] = React.useState<boolean>(true)
    const [projectTvBaselines, setProjectTvBaselines] = React.useState<IImpactFactor[]>([])
    const [channels, setChannels] = React.useState<IChannel[]>([]);
    const [parsedChannels, setParsedChannels] = React.useState<{label:string, value:string}[]>([])
    const [selectedTvBaselineItems, setSelectedTvBaselineItems] = React.useState<IImpactFactor[]>([]);
    const [channelNewItemDialog, setChannelNewItemDialog] = React.useState(false);
    const [newCampaignChannel, setNewCampaignChannel] = React.useState('')
    

    React.useEffect(() => {
        setLoadProjectTvBaselines(true)
        const advantivService = new AdvantivService();
        
        advantivService.listLineItems('nl').then(async resp => {
            let data = await resp.getBody()
            let tmpChannels: {label:string, value:string}[] = []
            setChannels(data['data'])
            data['data'].forEach(item => {
                let k = {
                    label: item['name'] as string,
                    value: item['id'] as string
                }
                tmpChannels.push(k)
            })
            setParsedChannels(tmpChannels)
            
            advantivService.listImpactFactors(selectedProject.id).then(async(response: ApiResponse<PaginatedResponse<IImpactFactor[]>>) => {
                if (!response.hasError()){
                    await response.getBody().then((paginatedResponse: PaginatedResponse<IImpactFactor[]>) => {
                        setProjectTvBaselinesValues(paginatedResponse['data'], data['data'])
                        setLoadProjectTvBaselines(false)
                    })
                }
            })
        })

    }, []);

    const impactFactorBodyTemplate = (e) => {
        return (Math.round(e.rowData[e.key] * 100) / 100).toFixed(3)
    }

    const tvImpactFactorBodyTemplate = (e) => {
        // return 1/0.8
        // return 1/e.rowData[e.key]
        return (Math.round(1/e.rowData[e.key] * 100) / 100).toFixed(3)
    }

    const setProjectTvBaselinesValues = (values, listChannels:IChannel[]=[]) => {
        if(listChannels.length == 0){
            listChannels=channels
        }
        console.log(listChannels, values)
        let tmp_list: IImpactFactor[] = []
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            element.adType = Number(element.adType)
            element.avgPercWithSound = Number(element.avgPercWithSound)
            element.avgPixelsInView = Number(element.avgPixelsInView)
            element.avgScreenCoverage = Number(element.avgScreenCoverage)
            element.avgViewingTime = Number(element.avgViewingTime)
            element.relativeStrengthCreative = Number(element.relativeStrengthCreative)
            element.impactFactor = element.adType * element.avgPixelsInView * element.avgViewingTime * element.avgPercWithSound * element.avgScreenCoverage * element.relativeStrengthCreative
            element.tvFactor = element.adType + element.avgPixelsInView + element.avgViewingTime + element.avgPercWithSound + element.avgScreenCoverage + element.relativeStrengthCreative
            element.channelName = listChannels.find(e => e.id == element.channelDefinitionId)?.name
            tmp_list.push(element)
        }
        setProjectTvBaselines(tmp_list)
    }

    const TvImpactEditor = (options, key) => {
        let items = TvImpactSettings[key]['items']
        let selectOptions: {label:string, value:string}[] = []
        items.forEach(item => {
            let k = {
                label: item['text'] as string,
                value: item['factor']
            }
            selectOptions.push(k)
        })
        console.log(options.rowData)
        console.log(key)
        console.log(options.rowData[key])

        
        return <Dropdown style={{ width: '7em' }} options={selectOptions} value={Number(options.rowData[key])} onChange={(e) => options.editorCallback(e.target.value)}/>
    }

    const wrappedDataBodyTemplate = (e) => {
        let value = e.rowData[e.key]
        if(value == null || value == undefined){
            value = 1
        }
        let textObj = TvImpactSettings[e.key]['items'].find(j => j.factor == value)
        if(e.key == 'avgScreenCoverage' && e.rowData.channelName == 'Instream video - Talpa | 15 sec'){
            // console.log("_________---")
            // console.log(e)
            // console.log(textObj)
            // console.log(TvImpactSettings[e.key]['items'])
        }
        if(textObj != undefined){
            return <span><b>{textObj['text']}</b> <small>{textObj['factor']}</small></span>
        }
        return ''
    }

    const avg_pixel_viewBodyTemplate = (rowData) =>  {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'avgPixelsInView'})} 
    const avg_viewing_listingBodyTemplate = (rowData) => {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'avgViewingTime'})} 
    const avg_display_soundBodyTemplate = (rowData) => {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'avgPercWithSound'})} 
    const adtypeBodyTemplate = (rowData) => {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'adType'})} 
    const avg_screencoverageBodyTemplate = (rowData) => {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'avgScreenCoverage'})} 
    const relative_strength_creativeBodyTemplate = (rowData) =>  {return wrappedDataBodyTemplate({"rowData":rowData, 'key':'relativeStrengthCreative'})} 
    const impactFactorTemplate = (rowData) => {return impactFactorBodyTemplate({"rowData":rowData, 'key':'impactFactor'})}
    const tvFactorTemplate = (rowData) => {return tvImpactFactorBodyTemplate({"rowData":rowData, 'key':'impactFactor'})}

    
    const addChannel = () => {
        setChannelNewItemDialog(true)
    }

    const channelNewItemDialogHide = () => {
        setChannelNewItemDialog(false)
    }

    const channelNewItemDialogAdd = () => {
        let advantivService = new AdvantivService()
        advantivService.createImpactFactor(selectedProject.id, {channelDefinitionId: newCampaignChannel, 
            adType: 1, 
            avgPercWithSound: 1, 
            avgPixelsInView: 1, 
            avgViewingTime: 1, 
            avgScreenCoverage: 1, 
            relativeStrengthCreative: 1 
        } as EditImpactFactorRequest).then(e => {
            projectTvBaselines.push(e)
            setProjectTvBaselinesValues(projectTvBaselines)
            setChannelNewItemDialog(false)
            setNewCampaignChannel('')
        })
    }

    const deleteProjectTvBaselineItems = () => {        
        const advantivService = new AdvantivService();
        for (let index = 0; index < selectedTvBaselineItems.length; index++) {
            const element = selectedTvBaselineItems[index];
            advantivService.deleteImpactFactor(selectedProject.id, element.id)
        }
        setSelectedTvBaselineItems([])
        let _items = projectTvBaselines.filter(val => !selectedTvBaselineItems.includes(val));
        setProjectTvBaselinesValues(_items)
        setSelectedTvBaselineItems([])
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
            <Button icon="pi pi-plus" className="p-button p-button-success mr-2" onClick={() => addChannel()} value="Add channel"/>
            <Button icon="pi pi-minus" className="p-button p-button-danger mr-2" onClick={() => deleteProjectTvBaselineItems()} disabled={!selectedTvBaselineItems || !selectedTvBaselineItems.length}value="Delete channels"/>                           
        </div>
    );
 
    const channelNewItemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={channelNewItemDialogHide} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={channelNewItemDialogAdd} />
        </React.Fragment>
    );

    const onRowEditComplete = (editObject) => {
        let advantivService = new AdvantivService()
        let obj = editObject.newData

        advantivService.editImpactFactor(selectedProject.id, {id: obj.id, 
                channelDefinitionId: obj.channelDefinitionId, 
                adType:obj.adType, 
                avgPercWithSound: obj.avgPercWithSound, 
                avgPixelsInView: obj.avgPixelsInView, 
                avgViewingTime: obj.avgViewingTime, 
                avgScreenCoverage: obj.avgScreenCoverage, 
                relativeStrengthCreative: obj.relativeStrengthCreative
        } as EditImpactFactorRequest).then(k => {
            let index = projectTvBaselines.findIndex(e => e.id == obj.id)
            projectTvBaselines[index] = k
            setProjectTvBaselinesValues(projectTvBaselines)
        })
    }


    return (
        <>
            <Navbar activeTool={'Advantiv'} activePage={'tvimpactfactor'}/>
            <div className="layout-application-window" style={{ padding: '0px 40px' }}>
                
                {/* The top section of the campaigns page. */}
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                    <h1 style={{ margin: '50px 0 0 0', padding: '0', fontWeight: '500', color: '#000' }}>Manage your TV Impact Factors</h1>
                    <h5 style={{ margin: '5px 0 0 0', padding: '0', fontWeight: '400', color: '#525252' }}>Use the table below to create, update or delete TV Impact Factors</h5>
                </div>

                <div>
                    {
                        loadProjectTvBaselines ? (
                            <p style={{ marginTop: '0px', fontSize: '14px', textAlign: 'center' }}>
                                <ProgressSpinner strokeWidth="2" />
                                Please wait a moment, we are loading your TV impact factors
                            </p>
                        ) : (

                        <div style={{ width: '100%', marginTop: '50px' }}>
                            
                            <DataTable 
                                value={projectTvBaselines} 
                                columnResizeMode="fit"
                                size="small"
                                showGridlines={true}
                                stripedRows={true}
                                dataKey="id"
                                editMode="row" 
                                responsiveLayout="scroll"  
                                paginator 
                                rows={10} 
                                rowsPerPageOptions={[5, 10, 25]}
                                globalFilter={globalFilter} 
                                globalFilterFields={['channelName']} 
                                header={header}
                                selection={selectedTvBaselineItems} 
                                onSelectionChange={(e) => setSelectedTvBaselineItems(e.value as any)}
                                onRowEditComplete={onRowEditComplete}
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                                <Column field="channelName" header="Channel" sortable style={{ minWidth: '12rem' }} filter={true} ></Column>
                                <Column field="avgPixelsInView" header="Avg pixels in view" sortable editor={(options) => TvImpactEditor(options, 'avgPixelsInView' )} body={avg_pixel_viewBodyTemplate} style={{ minWidth: '8rem' }} ></Column>
                                <Column field="avgViewingTime" header="Avg viewing/ listing time" sortable editor={(options) => TvImpactEditor(options, 'avgViewingTime')} style={{ minWidth: '8rem' }} body={avg_viewing_listingBodyTemplate}></Column>
                                <Column field="avgPercWithSound" header="Avg % displayed with sound" sortable editor={(options) => TvImpactEditor(options, 'avgPercWithSound')} style={{ minWidth: '8rem' }} body={avg_display_soundBodyTemplate}></Column>
                                <Column field="adType" header="Adtype" sortable editor={(options) => TvImpactEditor(options, 'adType')} style={{ minWidth: '10rem' }} body={adtypeBodyTemplate}></Column>
                                <Column field="avgScreenCoverage" header="Avg screencoverage" sortable editor={(options) => TvImpactEditor(options, 'avgScreenCoverage')} style={{ minWidth: '8rem' }} body={avg_screencoverageBodyTemplate}></Column>
                                <Column field="relativeStrengthCreative" header="Relativ strength creative" sortable editor={(options) => TvImpactEditor(options, 'relativeStrengthCreative')} style={{ minWidth: '8rem' }} body={relative_strength_creativeBodyTemplate}></Column>
                                <Column field="impactFactor" header="Impact Factor" sortable style={{ minWidth: '10rem' }} body={impactFactorTemplate}></Column>
                                <Column field="tvFactor" header="Tv Factor" sortable style={{ minWidth: '10rem' }} body={tvFactorTemplate}></Column>
                                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </div>
            
                        )
                    }
                </div>
            </div>


            <Toast ref={toast} position="top-right" />

            <Dialog visible={channelNewItemDialog} style={{ width: '450px' }} header="Add a channel" modal className="p-fluid" footer={channelNewItemDialogFooter} onHide={channelNewItemDialogHide}>
                <h4>Please, select a channel</h4>
                <p style={{ marginTop: '10px', fontSize: '14px', textAlign: 'left' }}>
                    When you have select a channel you can calculate the tv impact factor
                </p>
                <Dropdown 
                    style={{ width: '100%' }} 
                    options={parsedChannels} 
                    value={newCampaignChannel} 
                    placeholder={"Select a channel"}
                    onChange={(e) => {
                        console.log(e)
                        setNewCampaignChannel(e.target.value)
                    }}
                />
            
            </Dialog>
        </>
    )
}


export default AdvantivTvimpactFactor;
