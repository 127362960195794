import React from "react";
import ConnectorsApiService from "../services/ConnectorsApi.service";
import {CenteredBase, DivRow} from "./styles";
import {Button} from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import {useLocation, useNavigate} from "react-router-dom";
import { Toast } from 'primereact/toast';

class ConnectorsSourceSelection extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            allSources: null,
            selectedSource: null
        }
    }

    async componentDidMount() {
        this.showMessages(this.props.messages);
        await ConnectorsApiService.getAllSources().then(
            sources => {
                this.setState({allSources: sources});
            }
        )
    }

    handleChangeOnSelect = (event) => {
        this.setState({ selectedSource: event.target.value });
    }

    configureSource = () => {
        this.props.navigate('/connectors/source-config', { state: this.state.selectedSource });
    }

    showMessages = (messages) => {
        this.toast.show(messages);
    }

    render() {
        return (
            <CenteredBase>
                <Toast ref={(el) => this.toast = el}/>
                <CenteredBase>
                    <h1 style={{color:"black"}}>Select a datasource:</h1>
                    <DivRow>
                        <Dropdown value={this.state.selectedSource}
                                  options={this.state.allSources}
                                  onChange={(e) => this.handleChangeOnSelect(e)}
                                  optionLabel="name"
                                  filter
                                  showClear
                                  filterBy="name"
                                  placeholder="Select a datasource"/>
                        <Button style={{marginLeft: 12 + "px"}} label="Configure" onClick={this.configureSource}
                                disabled={!this.state.selectedSource}>
                            <i className={"pi pi-cog button-icon"} style={{marginLeft: 8 + "px"}}></i>
                        </Button>
                    </DivRow>
                </CenteredBase>
            </CenteredBase>
        );
    }
}

function WithNavigate() {
    return <ConnectorsSourceSelection messages={useLocation().state?.messages ?? null} navigate={useNavigate()}/>
}

export default WithNavigate