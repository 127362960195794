import React from "react";
import CConnectorsTitle from "../components/title/connectorsTitle";
import {Link} from "react-router-dom";
import {CenteredBase, CollapseSubItem} from "./styles";
import {Panel} from "primereact/panel";

const ConnectorsHome = () => {
    return (
            <CenteredBase>
            <CConnectorsTitle></CConnectorsTitle>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                height: 'calc(100vh - 60px)'
            }}>
                <Panel header='Welcome' toggleable={true}
                       style={{marginBottom: '20px', minWidth: '800px', maxWidth: '800px'}}>
                    <div style={{paddingTop: 10 + "px", paddingBottom: 10 + "px"}}>
                        <p>
                            Welcome to the Connectors tool.
                        </p>
                        <p style={{marginTop:"12px", marginBottom:"12px"}}>
                            With this tool a connection will be made between a given data- source and destination.
                        </p>
                        <p>
                            Select and configure the data- source and destination, Connectors will handle everything
                            else ;)
                        </p>
                    </div>
                </Panel>
                <Panel header="Getting started" toggleable={true}
                       style={{marginTop: '15px', marginBottom: '35px', minWidth: '800px', maxWidth: '800px'}}>
                    <div style={{paddingTop: 10 + "px", paddingBottom: 10 + "px"}}>
                        <h4>
                            Let's start by configuring your source!
                        </h4>
                        <Link to={'/connectors/source-selection'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CollapseSubItem style={{border: '1px solid black', padding:"16px", marginTop:"10px"}}>
                                <div>
                                    <span>
                                        <i className="fa-solid fa-gear" style={{color: '#4baf23', fontSize: 32+"px", marginRight: "12px"}}></i>
                                    </span>
                                    <h3>
                                        Start configuration
                                    </h3>
                                    <span style={{marginLeft: 'auto'}}><i className="fa-solid fa-arrow-right"
                                                                          style={{color: '#4baf23', fontSize: 32+"px"}}/></span>
                                </div>
                                <p style={{marginTop:"18px"}}>
                                    Click here to select and configure your source.
                                </p>
                            </CollapseSubItem>
                        </Link>
                    </div>
                </Panel>
            </div>
        </CenteredBase>
    );
};

export default ConnectorsHome