/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AdminSidebarComponent, { AdminSidebarItemProps } from "../../../admin/components/admin-sidebar/admin-sidebar.components";
import './admin-layout.styles.scss';


const AdminLayout = () => {

    const location = useLocation();
    const navigate = useNavigate();

    // Use this method to navigate back from the admin panel to the devote webapp.
    const returnToDevote = () => {
        navigate('home');
    }

    const [sidebarItems, setSidebarItems] = React.useState<AdminSidebarItemProps[]>([
        // Top Items
        { index: 1, title: 'Home', icon: 'pi pi-home', color: '#e8e8e8', active: false, inFooter: false, link: '/admin/home' },

        // Middle Items
        { index: 2, title: 'Projects', icon: 'fa-solid fa-cube', color: '#e8e8e8', active: false, inFooter: false, link: '/admin/projects' },
        { index: 3, title: 'Users', icon: 'pi pi-fw pi-user', color: '#e8e8e8', active: false, inFooter: false, link: '/admin/users' },
        { index: 4, title: 'Roles', icon: 'fa-solid fa-id-badge', color: '#e8e8e8', active: false, inFooter: false, link: '/admin/roles' },
        { index: 5, title: 'Permissions', icon: 'fa-solid fa-shield-cat', color: '#e8e8e8', active: false, inFooter: false, link: '/admin/permissions' },

        // Footer Items
        { index: 6, title: 'Return to Devote', icon: 'pi pi-sign-out', color: '#e8e8e8', active: false, inFooter: true, link: '/home', onClick: returnToDevote },
    ]);

    // This effect is used to setup the sidebar items when the end-user accesses a page using the URL.
    React.useEffect(() => {
        const newSidebarItems: AdminSidebarItemProps[] = []

        let currentActiveItem = sidebarItems.find(item => item.active);

        let nextActiveItem = sidebarItems.find(item => location.pathname.includes(item.link));

        if (currentActiveItem) {
            currentActiveItem.active = false;
        }
        if (nextActiveItem) {
            nextActiveItem.active = true;
        }

        if ((currentActiveItem === nextActiveItem) && nextActiveItem) {
            newSidebarItems.push(nextActiveItem);
        } else {
            if (currentActiveItem) {
                newSidebarItems.push(currentActiveItem);
            }
            if (nextActiveItem) {
                newSidebarItems.push(nextActiveItem);
            }
        }

        const sidebarItemsWithoutCurrentAndNext = sidebarItems.filter(item => item.index !== currentActiveItem?.index && item.index !== nextActiveItem?.index);
        newSidebarItems.push(...sidebarItemsWithoutCurrentAndNext);

        // Sort in ascending order by index.
        newSidebarItems.sort((a, b) => a.index - b.index);

        // Update the sidebar items.
        setSidebarItems(newSidebarItems);
    }, [location]);

    return (
        <div>
            <AdminSidebarComponent items={sidebarItems} />
            <div className='admin-layout'>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
