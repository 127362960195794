import React from "react";
import Navbar from "../../../components/navbar/navbar.component";
import CloudFormerApiService from "../services/CloudFormerApi.service";
import {Dropdown} from "primereact/dropdown";
import Product from "../types/Product";
import ProductProperty from "../types/ProductProperty";
import {Dialog} from "primereact/dialog";
import CloudFormerLoader from "../components/loader/cloudformer-loader";
import {Form} from "../components/form/Form";
import {ProgressBar} from "primereact/progressbar";

interface State {
    products: Array<Product>
    selectedProduct: Product | undefined
    productConfiguration: Array<ProductProperty>
    dialogVisible: boolean
    dialogMessage: string | undefined
    progressValue: number
}

class CloudFormerMainPage extends React.Component<{}, State> {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: undefined,
            productConfiguration: [],
            dialogVisible: false,
            dialogMessage: '',
            progressValue: 0
        }
    }

    async componentDidMount() {
        await CloudFormerApiService.getAllProducts().then(
            products => {
                this.setState({products: products.products});
            }
        )
    }

    handleChangeOnSelectProduct = (event) => {
        this.setState({selectedProduct: event.target.value});
        if (event.target.value === undefined) { return; }
        CloudFormerApiService.getProductConfiguration(event.target.value.id).then(
            productConfiguration => {
                this.setState({productConfiguration: productConfiguration.properties});
            }
        )
    }

    submit = async (data: Object) => {
        this.setState({
            dialogVisible: true
        });
        await CloudFormerApiService.terraformGenerate(this.state.selectedProduct!.name, data).then(
            response => response.blob())
            .then(blob => {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = `${this.state.selectedProduct!.name}_terraform.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .then(() => {
                this.setState({
                    dialogVisible: false
                });
            });
        // CloudFormerApiService.createDirectory(this.state.selectedProduct!.id)
        //     .then((createDirectoryResult) => {
        //         this.setState({progressValue: this.state.progressValue + 10});
        //         CloudFormerApiService.copyFiles(this.state.selectedProduct!.id, createDirectoryResult.directory)
        //             .then((copyFilesResult) => {
        //                 this.setState({progressValue: this.state.progressValue + 20});
        //                 CloudFormerApiService.terraformInit(this.state.selectedProduct!.id, data, createDirectoryResult.directory)
        //                     .then((terraformInitResult) => {
        //                         this.setState({progressValue: this.state.progressValue + 30});
        //                         CloudFormerApiService.terraformPlan(this.state.selectedProduct!.id, data, createDirectoryResult.directory)
        //                             .then((terraformPlanResult) => {
        //                                 this.setState({progressValue: this.state.progressValue + 10});
        //                                 //TODO: Ask for confirmation based on plan result
        //                                 CloudFormerApiService.terraformApply(this.state.selectedProduct!.id, data, createDirectoryResult.directory)
        //                                     .then((terraformApplyResult) => {
        //                                         this.setState({progressValue: this.state.progressValue + 30});
        //                                         this.setState({
        //                                             dialogVisible: true,
        //                                             dialogMessage: terraformApplyResult.error ? terraformApplyResult.error : terraformApplyResult.output,
        //                                         });
        //                                     });
        //                             });
        //                     });
        //             });
        //     });
    }

    closeDialog = () => {
        this.setState({
            dialogVisible: false,
            dialogMessage: '',
        });
    }

    render() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}>
                <Navbar activeTool={'CloudFormer'}/>

                <div style={{
                    textAlign: "center",
                    backgroundColor: "#f0f0f0",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    boxShadow: "0 4px 6px #c8c8c8",
                    borderRadius: "10px",
                    width: "18vw",
                    margin: "6px"
                }}>
                    <h1 style={{
                        fontSize: "2vw",
                        fontWeight: "bold",
                        margin: "0",
                        color: "#333",
                        textShadow: "2px 2px 2px #c8c8c8",
                    }}>
                        CloudFormer
                    </h1>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "20px",
                    boxShadow: "0 2px 4px #c8c8c8",
                    borderRadius: "8px",
                    background: "#f0f0f0",
                    padding: "10px",
                }} className="p-float-label">
                    <Dropdown
                        id="dd-product"
                        value={this.state.selectedProduct}
                        style={{
                            width: "300px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px"
                        }}
                        options={this.state.products}
                        onChange={(e) => this.handleChangeOnSelectProduct(e)}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Select a product"
                    />
                    <label style={{fontSize: "14px", color: "#333", paddingLeft: "12px"}}>
                        Select a Product
                    </label>
                </div>
                {this.state.selectedProduct !== undefined && (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        boxShadow: "0 4px 6px #c8c8c8",
                        borderRadius: "10px",
                        background: "#f0f0f0",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "60px",
                        paddingRight: "60px",
                        marginTop: "20px",
                        width: "80%",
                    }}>
                        {this.state.productConfiguration.length > 0 &&
                            <Form products={this.state.productConfiguration} selectedProduct={this.state.selectedProduct.name} submitForm={this.submit}/>
                        }
                    </div>
                )}

                <Dialog
                    visible={this.state.dialogVisible}
                    onHide={this.closeDialog}
                    header="CloudFormer Initialization Response"
                    style={{
                        width: "70vw",
                        height: "80vh",
                    }}
                >
                    {this.state.dialogMessage === '' ? (
                        <div style={{
                            height: "100%",
                            width: "100%",
                            margin: "0px",
                            padding: "0px",
                            display: "flex",
                            textAlign: "center",
                            flexDirection: "column"
                        }}>
                            <CloudFormerLoader></CloudFormerLoader>
                            <h1>Please hold on while CloudFormer will initialize your product!</h1>
                            <ProgressBar value={this.state.progressValue}></ProgressBar>
                        </div>
                    ) : null }
                    <div>{this.state.dialogMessage}</div>
                </Dialog>
            </div>
        );
    }
}

export default CloudFormerMainPage