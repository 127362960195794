import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import { RolePermission } from "../create-dialogs/create-user.component";
import DialogTitle from "../dialog-title";
import "../dialog.styles.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    initialData?: RolePermission;
    updatePermission: (data: RolePermission) => Promise<any>;
}

const EditPermissionDialog = ({
                                  isOpen,
                                  onClose,
                                  initialData,
                                  updatePermission,
                              }: Props) => {
    const { data, updateField, setData } = useDialogData<RolePermission>(
        initialData ?? {
            id: "",
            name: "",
            description: "",
            enabled: false,
        }
    );

    useEffect(() => {
        if (initialData) setData(initialData);
    }, [initialData, setData]);

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Edit Permission"
                    description="Edit a permission in the Devote platform."
                    icon="pi pi-pencil"
                />
            }
            visible={isOpen}
            style={{ width: "45rem" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(
                                data,
                                ["id", "name", "description", "enabled"],
                                onClose,
                                updatePermission
                            );
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="permission-name" className="block">
                            Name
                        </label>
                        <InputText
                            id="permission-name"
                            aria-describedby="permission-name"
                            onChange={(e) => {
                                updateField("name", e.target.value);
                            }}
                            value={data.name}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="permission-description" className="block">
                            Description
                        </label>
                        <InputText
                            id="permission-description"
                            aria-describedby="permission-description"
                            onChange={(e) => {
                                updateField("description", e.target.value);
                            }}
                            value={data.description}
                        />
                    </div>
                </div>
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="permission-enabled" className="block">
                            Enabled
                        </label>
                        <InputSwitch
                            checked={data.enabled}
                            onChange={(e) => {
                                updateField("enabled", e.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default EditPermissionDialog;