/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import { IEnvironment } from "./environment";

export const Environment: IEnvironment = {

    backends: {
        identityService: 'https://staging-devote.springbokagency.com/api/identity',
        resourceManagementService: 'https://staging-devote.springbokagency.com/api/resourcemanagement',
        advantivService: 'https://staging-devote.springbokagency.com/api/advantiv',
        connectorsService: 'https://staging-devote.springbokagency.com/api/connectors',
        cloudFormerService: 'https://staging-devote.springbokagency.com/api/cloudformer'
    },

    oauth: {
        clientId: "35847df49c380f602eaaf13bb3a4f006"
    },

}