/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { Panel } from "primereact/panel";
import { springbok_circle_yellow } from "../../../../assets";
import { Dialog } from "primereact/dialog";
import CreateCampaignDialogComponent from "../../components/create-campaign-dialog/create-campaign-dialog.component";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../../components/navbar/navbar.component"
import './advantiv-home.styles.scss';

const AdvantivHome = () => {

    const [displayCreateCampaignDialog, setDisplayCreateCampaignDialog] = React.useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Navbar activeTool={'Advantiv'} activePage={'home'} />
            <div className="layout-application-window">

                <div style={{ marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: 'calc(100vh - 50px)' }}>
                    <img style={{ marginBottom: '35px' }} src={springbok_circle_yellow} alt="Advantiv Logo" width={55}  />


                    {/* The welcome panel */}
                    <Panel className="advantiv-welcome-panel" header='Welcome' toggleable={true}>
                        <div className="advantiv-welcome-panel-text-container">
                            <p className="advantiv-welcome-panel-text">Welcome to <strong>Advantiv 4 Branding</strong></p>
                            <br />
                            <p className="advantiv-welcome-panel-text">This application allows you to calculate the budget and reach required for your campaigns. Once you've created a campaign, you can monitor its progress and compare the expected outcomes to the actual results here. </p>
                            <br />
                            <p className="advantiv-welcome-panel-text">With the data-rich insights provided by Advantiv 4 Branding, you'll have the information you need to make informed decisions and optimize your campaigns.</p>
                        </div>
                    </Panel>


                    {/* The getting started panel */}
                    <Panel className="advantiv-welcome-panel" header='Getting started' toggleable={true} style={{ paddingBottom: '30px'}}>
                        <div style={{ padding: '15px 10px' }}>
                            <div>
                                <p className="advantiv-welcome-panel-text">To get started with advantiv, you can either create a campaign or view an existing campaign</p>
                            </div>


                            <div className="advantiv-homepage-cards">
                                
                                {/* The card for calculating tv factor. */}
                                <div className="advantiv-homepage-card-container" onClick={() => navigate('/advantiv/tv-impactfactor')}>
                                    <div className="advantiv-homepage-card">
                                        <p className="advantiv-homepage-card-title">Calculate tv impact factor's</p>
                                        <span className="advantiv-homepage-card-arrow">
                                            <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </div>
                        
                                    <p className="advantiv-homepage-card-description">Calculate tv impact factors by clicking here.</p>
                                </div>


                                {/* The card for navigating to campaigns. */}
                                <div className="advantiv-homepage-card-container" onClick={() => navigate('/advantiv/campaign')}>
                                    <div className="advantiv-homepage-card">
                                        <p className="advantiv-homepage-card-title">View calculated campaigns</p>
                                        <span className="advantiv-homepage-card-arrow">
                                            <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </div>
                        
                                    <p className="advantiv-homepage-card-description">Get insight in your campaign by clicking here.</p>
                                </div>

                            </div>

                        </div>
                    </Panel>

                    <Dialog header='Create new campaign' visible={displayCreateCampaignDialog} onHide={() => {setDisplayCreateCampaignDialog(false)}}>
                        <CreateCampaignDialogComponent setDialogVisible={setDisplayCreateCampaignDialog} />
                    </Dialog>
                </div>
            </div>

        </>
    )
}


export default AdvantivHome;
