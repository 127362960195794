import React from 'react';
import GoogleOAuth2Service from "./oauth-clients/Google-OAuth2.service.js";

class OAuth2Service extends React.Component {

    static requestOAuth2Code = (client_id, redirect_uri, scope) => {

        try {
            GoogleOAuth2Service.requestOAuth2Code(client_id, redirect_uri, scope);
        } catch (e) {
            console.log(e);
        }
    }
}
export default OAuth2Service;