import { ICampaign } from '../../features/advantiv/models/campaign';
import {create} from 'zustand';
import { devtools, persist } from "zustand/middleware";

export interface ProjectState {
    selectedCampaignId: string | undefined;

    setSelectedCampaignId: (campaignId: string) => void;

    reset: () => void;
}

let campaignStore: any = (set): ProjectState => ({
    selectedCampaignId: undefined,

    setSelectedCampaignId: (campaignId: string) => set((state) => ({ ...state, selectedCampaignId: campaignId })),

    reset: () => set({
        selectedCampaignId: undefined
    }),
});


campaignStore = devtools(campaignStore);
campaignStore = persist(campaignStore, { name: 'devote_campaign_store' });

const useCampaignStore = create(campaignStore);

export default useCampaignStore;
