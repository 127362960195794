/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useEffect, useState } from "react";
import { DataRowType } from "../../../../store/details-pane-store";
import "./admin-table.styles.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export interface GenericTableEntry {
    [key: string]: string;
}

interface Props {
    column_names: {
        key: string;
        header: string;
    }[];
    value: any[];
    amountOfRows: number;
    onRowSelect?: (e: any) => void;
    selection?: DataRowType;
    selectedRowKey?: string;
    isActive?: boolean;
    filterValue: string;
    setFilterValue: (value: string) => void;
    buttonLabel: string;
    buttonIcon: string;
    onButtonClick?: () => void;
}

const AdminTable = (props: Props) => {
    const [displayEntries, setDisplayEntries] = useState<(GenericTableEntry | {})[]>([]);

    // We must fill the remaining entries with empty objects to make sure the table
    // is always full. Otherwise, the table will not stay at a fixed height.
    useEffect(() => {
        if (props.value.length % props.amountOfRows === 0 && props.value.length !== 0) {
            setDisplayEntries(props.value);
            return;
        }

        const remainingEntries = props.value.length === 0 ? 0 : props.value.length % props.amountOfRows;

        const newArray = [...props.value, ...[...Array(props.amountOfRows - remainingEntries)].map(() => ({}))];

        setDisplayEntries(newArray);
    }, [props.value, props.amountOfRows]);

    const rowClassParser = (data: any, key: string) => {
        return data[key] === props.selection?.[key] &&
        data[key] !== undefined &&
        props.selection?.[key] !== undefined
            ? "selected-row"
            : "";
    };

    return (
        <>
            <div className="top-bar">
                <div className="controls-container">
                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                        <i className="pi pi-search" />
                        <InputText
                            placeholder="Search"
                            style={{ width: "100%" }}
                            onChange={(e) => props.setFilterValue(e.target.value)}
                            value={props.filterValue}
                        />
                    </span>
                    <Button
                        label={props.buttonLabel}
                        icon={props.buttonIcon}
                        onClick={props.onButtonClick}
                    />
                </div>
            </div>

            <DataTable
                className="users-table"
                size='normal'
                selectionMode='single'
                showGridlines={true}
                stripedRows={true}
                paginator={true}
                rows={10}
                value={displayEntries}
                onRowSelect={({ data }) => props.onRowSelect?.(data.id)}
                rowClassName={(data) =>
                    props.selectedRowKey && props.isActive ? rowClassParser(data, props.selectedRowKey) : ""
                }
            >
                {props.column_names.map((column_name) => {
                    return (
                        <Column
                            key={column_name.key}
                            field={column_name.key}
                            header={column_name.header}
                        />
                    );
                })}
            </DataTable>
        </>
    );
};

export default AdminTable;
