/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import jwt_decode from 'jwt-decode';

export const setAccessToken = (accessToken: string): void => {
    localStorage.setItem('devote_access_token', accessToken);
}

export const getAccessToken = (): string | null => {
    return localStorage.getItem('devote_access_token');
}

export const setRefreshToken = (refreshToken: string): void => {
    localStorage.setItem('devote_refresh_token', refreshToken);
}

export const getRefreshToken = (): string | null => {
    return localStorage.getItem('devote_refresh_token');
}

export const getAccessTokenDecoded = (): any => {
    const token = getAccessToken();
    if (token) {
        return jwt_decode(token);
    }
    return null;
}

export const isLoggedIn = (): boolean => {
    return localStorage.getItem('access_token') !== null;
}
