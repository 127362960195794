/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Messages } from "primereact/messages";
import { IdentityService } from "../../../../api/identity-service";
import { springbok_logo_white_orange } from '../../../../assets';
import { Button } from 'primereact/button';
import './reset-password.styles.scss'


const ResetPasswordComponent = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [passwordResetCode, setPasswordResetCode] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatedPassword, setRepeatedPassword] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [resetSuccessful, setResetSuccessful] = React.useState<boolean>(false);
    const messagesRef = React.useRef<Messages>(null);

    React.useEffect(() => {
        setPasswordResetCode(searchParams.get('code') || '');
    }, []);


    const submit = (event) => {
        event.preventDefault();
        setLoading(true);

        if (password !== repeatedPassword) {
            showPasswordMismatchError();
            return;
        }

        new IdentityService().resetPassword(passwordResetCode, password).then((response: Response) => {
            setLoading(false);

            if (!response.ok) {
                response.json().then((data) => {
                    if (data.message) {
                        showPasswordResetError(data.message);
                    } else {
                        showPasswordResetError();
                    }
                    
                });

                return;
            }

            setResetSuccessful(true);
        });
    }

    const navigateToLoginPage = () => {
        navigate('/login');
    }
    
    const showPasswordMismatchError = () => {
        messagesRef.current?.replace([{
            severity: 'error',
            detail: 'Passwords do not match.',
            sticky: true
        }]);
    }

    const showPasswordResetError = (error?: string) => {
        messagesRef.current?.replace([{
            severity: 'error',
            detail: error ?? 'Something went wrong with the request, please try again later.',
            sticky: true
        }]);
    }

    return (
        <>
        {
            resetSuccessful ? (
                <div className='reset-password-container'>
                    <img className='logo' src={springbok_logo_white_orange} alt='logo' />

                    <p className='title'>DEVOTE</p>

                    <p style={{'color': 'white'}}>Your password has been set successfully.</p>

                    <p style={{'color': 'white'}}>Please click the button below to login</p>

                    <Button style={{ marginTop: '30px' }} className='reset-password-form-submit-button' label='Login' type="button" onClick={navigateToLoginPage} />

                </div>
            ) :

            <div className='reset-password-container'>
                <img className='logo' src={springbok_logo_white_orange} alt='logo' />

                <p className='title'>DEVOTE</p>

                <p style={{'color': 'white'}}>Please fill out the form below to set your password.</p>

                <form className='reset-password-form' onSubmit={(e) => submit(e)}>
                    <span className='reset-password-form-field p-input-icon-left'>
                        <i className="fa fa-key reset-password-form-field-icon" />
                        <input className='reset-password-form-field-input' type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </span>
                    <span className='reset-password-form-field p-input-icon-left'>
                        <i className="fa fa-key reset-password-form-field-icon" />
                        <input className='reset-password-form-field-input' type="password" placeholder="Repeat Password" onChange={(e) => setRepeatedPassword(e.target.value)} />
                    </span>

                    <Button className='reset-password-form-submit-button' label='Set Password' type="submit" loading={loading} />
                </form>

                <Messages className="reset-password-form-error" ref={messagesRef} />
            </div>
        }
   

        </>
    );
}

export default ResetPasswordComponent;
