import { useCallback, useEffect, useMemo, useState } from "react";
import useDetailsPaneStore, { DetailsPaneDataType, } from "../../../store/details-pane-store";

export default function useDetailsPane<T>(
    type: DetailsPaneDataType,
    data: T[]
) {
    const [selectedId, setSelectedId] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [shouldOpen, setShouldOpen] = useState<boolean>(false);

    const detailsPaneStore = useDetailsPaneStore();

    // Get the selected data from the data array based on the selected id
    const selectedData = useMemo(
        () => data.find((d) => (d as any).id === selectedId),
        [data, selectedId]
    );

    // Load the selected id and open state from the store (local storage)
    useEffect(() => {
        const storedData = detailsPaneStore.data[type];
        if (!storedData) return;

        const { id, isOpen } = storedData;
        setSelectedId(id);
        setShouldOpen(isOpen);
    }, []);

    // Only automatically open the pane if the data is loaded
    useEffect(() => {
        if (!shouldOpen || !selectedData) return;
        setIsOpen(true);
        setShouldOpen(false);
    }, [selectedData, shouldOpen]);

    // Update the store (local storage) when the selected id or open state changes
    useEffect(() => {
        if (!selectedId) return;
        detailsPaneStore.setData(type, selectedId, isOpen);
    }, [selectedId, isOpen]);

    // Close the pane if the selected data is no longer available
    useEffect(() => {
        if (selectedData) return;
        setIsOpen(false);
    }, [selectedData]);

    // Open the pane with the given id
    const openDetailsPane = useCallback((id?: string) => {
        if (!id) {
            setIsOpen(false);
            return;
        }

        setSelectedId(id);
        setIsOpen(true);
    }, []);

    return {
        data: selectedData,
        setData: setSelectedId,
        isOpen,
        setIsOpen,
        open: openDetailsPane,
        close: () => setIsOpen(false),
        edit: {
            isOpen: isEditOpen,
            open: () => setIsEditOpen(true),
            close: () => setIsEditOpen(false),
        },
    };
}