/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from 'react';
import { springbok_circle_pink } from '../../../../../assets';
import { springbok_circle_purple } from '../../../../../assets';
import { springbok_circle_brown } from '../../../../../assets';
import { springbok_circle_orange } from '../../../../../assets';
import { springbok_circle_yellow } from "../../../../../assets";
import "./admin-sidebar-header.styles.scss";


const AdminSidebarHeader = () => {

    const logos = [
        springbok_circle_pink,
        springbok_circle_purple,
        springbok_circle_brown,
        springbok_circle_orange,
        springbok_circle_yellow
    ]

    const randomInteger = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return (
        <div className="admin-sidebar-header">
            <img className="admin-sidebar-header-logo" src={logos[randomInteger(0, 4)]} alt="springbok-logo"/>
            <p className="admin-sidebar-header-text" style={{ color: 'white', fontSize: '13px', marginLeft: '15px' }}>Devote Control Panel</p>
        </div>
    );

}

export default AdminSidebarHeader;
