import {CBaseTitle, CTitle} from "./styles";
import React, { Component }  from 'react';


const CConnectorsTitle = () => {

    return (
        <CBaseTitle>
            <CTitle><h1>Connectors</h1></CTitle>
        </CBaseTitle>
    )
}

export default CConnectorsTitle