/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import html2canvas from "html2canvas";
import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { Chart } from "primereact/chart";
import './advantiv-chart.styles.scss';


const AdvantivChart = ({ title, options, data}) => {

    const chartRef = React.useRef<any>(null);

    // This effect will resize the chart when the window is resized.
    React.useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current) {
                // From the chartRef, get the canvas element.
                const element: HTMLDivElement = chartRef.current.getElement();
                const canvas: HTMLCanvasElement = element.getElementsByTagName('canvas')[0];
                const parentElement = element.parentElement?.parentElement?.parentElement;

                // offsetWidth and offsetHeight are the width and height of the parent element in real time.
                const parentWidth = parentElement?.offsetWidth;
                const parentHeight = parentElement?.offsetHeight;

                // Set aspect ratios of charts for different screen sizes.
                let aspectRatio = 1/1;
                let reductionPercentage = 1;

                if (window.outerWidth < 1140) {
                    aspectRatio = 1/1;
                    reductionPercentage = 1;
                } else if (window.outerWidth < 1550) {
                    aspectRatio = 5/4;
                    reductionPercentage = 0.97;
                } else if (window.outerHeight < 1725) {
                    aspectRatio = 16/9;
                    reductionPercentage = 0.9;
                } else {
                    aspectRatio = 16/9;
                    reductionPercentage = 0.7;
                }

                let chartWidth = Math.min(parentWidth!, parentHeight! * aspectRatio);
                let chartHeight = Math.min(chartWidth / aspectRatio);

                // Change the canvas size to match the parent element.
                canvas.style.width = `${chartWidth * reductionPercentage}px`;
                canvas.style.height = `${chartHeight * reductionPercentage}px`;

                // Sometimes the chart extends beyond the parent element. This is a hack to fix that.
                canvas.style.maxWidth = '100%';
                canvas.style.maxHeight = parentHeight! * 0.8 + 'px';
            }
        }

        resizeChart(); // set initial value
        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        }
    }, []);


    const downloadChart = () => {
        if (chartRef.current) {
            const element: HTMLDivElement = chartRef.current.getElement();
            const canvas: HTMLCanvasElement = element.getElementsByTagName('canvas')[0];
        
            html2canvas(canvas).then((canvasContent) => {
                const imageData = canvasContent.toDataURL('image/jpg');
                const imageLink = document.createElement('a');

                if (typeof imageLink.download === 'string') {
                    imageLink.href = imageData;
                    const imageName = title.replaceAll(" ", "_").replaceAll("+", "and").replace("&", "and").toLowerCase();
                    imageLink.download = `advantiv_${imageName.toLowerCase()}.jpg`;

                    document.body.appendChild(imageLink);
                    imageLink.click();
                    document.body.removeChild(imageLink);
                } else {
                    window.open(imageData);
                }
            });
        }   
    }

    const cardHeader = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'flex-start', alignItems: 'center', width: '100%' }}>
                <div style={{ width: '80%' }}>
                    <p>{title}</p>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <Button onClick={downloadChart}>Export</Button>
                </div>
            </div>
        )
    }

    return (
        <Card className="advantiv-chart-card" title={cardHeader()}>
            <Chart ref={chartRef} options={options} data={data} />
        </Card>
      );
};

export default AdvantivChart;
