import React from 'react';
import "./sidebar-footer.styles.scss";
import { Dialog } from 'primereact/dialog';
import ProjectSelectDialog from "../../project-select-dialog/project-select-dialog.component";
import { useProjectStore, useThemeStore } from "../../../store";

const SidebarFooter = () => {

    const themeStore = useThemeStore();
    const [projectDialogVisible, setProjectDialogVisible] = React.useState(false);

    const projectStore = useProjectStore();

    const openProjectDialog = () => {
        setProjectDialogVisible(true);
    }

    return (
        <>
            {
                themeStore.sidebar === "collapsed" ?
                (
                    <div className="sidebar-project-container">
                        <i className='fa-solid fa-cube project-icon'  onClick={() => setProjectDialogVisible(true)} />
                    </div>
                ) :
                (
                    <div className="sidebar-project-container">
                        <i className='fa-solid fa-cube project-icon' />
                        <p className='sidebar-project-text'>{ projectStore?.selectedProject?.name ?? '' }</p>
                        <div className="select-project-button-container" style={{marginLeft: "auto", marginRight: "15px"}}>
                            <i className="pi pi-arrow-circle-right" style={{ fontSize: "18px", color: "#fff" }}
                            onClick={() => setProjectDialogVisible(true)}/>
                        </div>
                    </div>
                )
            }

            <Dialog onHide={() => setProjectDialogVisible(false)} visible={projectDialogVisible} header="Select Project" modal={true} footer={null}>
                <ProjectSelectDialog />
            </Dialog>

        </>

    );
}

export default SidebarFooter;
