import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { ICampaign } from "../../models/campaign";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { AdvantivService } from '../../../../api/advantiv-service';
import { EditCampaignRequest } from '../../models/edit-campaign-request';
import { useProjectStore } from '../../../../store';
import { IProject } from '../../../../interfaces/project.interface';

interface ICreateCampaignDialogComponentProps {
    campaign: ICampaign;
    setDialogVisible: (visible: boolean) => void;
}

const EditCampaignDialogComponent = ({ campaign, setDialogVisible }: ICreateCampaignDialogComponentProps) => {

    // Form state.
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [formCampaign, setFormCampaign] = React.useState<ICampaign>(campaign);

    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    
    const submitForm = () => {
        console.log(formCampaign)
        setFormIsSubmitting(true);
        let advantivService = new AdvantivService()
        advantivService.editCampaign({
            projectId: selectedProject.id,
            campaignId: formCampaign.id,
            name: formCampaign.name,
            type: formCampaign.type,
            value: formCampaign.value,
            minimumAge: formCampaign.minimumAge,
            maximumAge: formCampaign.maximumAge,
            duration: formCampaign.duration,
            minimalTVF: formCampaign.minimalTVF,
            date: formCampaign.date
        } as EditCampaignRequest).then(e => {
            console.log(e)
            setFormIsSubmitting(false)
        })
    }

    console.log(campaign);

    return (
        <div style={{ width: '350px' }}>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Name:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={formCampaign.name}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, name: e.target.value })}}
                />
            </div>


            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p>Type:</p>
                <Dropdown
                    style={{ width: '100%' }}
                    value={formCampaign.type}
                    options={[{ label:'Budget', value: "BUDGET"}, { label: 'Target', value: "TARGET"}]}
                    filter
                    showClear
                    filterBy="label"
                    placeholder="Type"
                    onChange={(e) => setFormCampaign({ ...formCampaign, type: e.target.value })}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Value:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formCampaign.value}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, value: e.value! })}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Minimum age:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formCampaign.minimumAge}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, minimumAge: e.value! })}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Maximum age:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formCampaign.maximumAge}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, maximumAge: e.value! })}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Duration:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formCampaign.duration}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, duration: e.value! })}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Contact Frequency:</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={formCampaign.minimalTVF}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, minimalTVF: e.value! })}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Start Date:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(formCampaign.date)}
                    onChange={(e) => {setFormCampaign({ ...formCampaign, date: e.target.value as Date })}}
                />
            </div>


            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitForm()} />)
                }

            </div>
        </div>
    )
}

export default EditCampaignDialogComponent;