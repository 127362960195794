/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import { EnvironmentService } from "../environment/environment.service";
import { AdvantivReport } from "../features/advantiv/models/report";
import {ICampaign, IChannel, IImpactFactor, ISetting, ITVBaseline} from "../features/advantiv/models/campaign";
import { CreateCampaignRequest } from "../features/advantiv/models/create-campaign-request";
import { EditCampaignRequest } from "../features/advantiv/models/edit-campaign-request";
import { ApiResponse } from "./base/api-response";
import { EditChannelRequest } from "../features/advantiv/models/edit-channel-request";
import { EditTVBaselineRequest } from "../features/advantiv/models/edit-tvbaseline-request";
import { PaginatedResponse } from "./base/paginated-response";
import { EditImpactFactorRequest } from "../features/advantiv/models/EditImpactFactorRequest";
// import { AdvantivLineitem } from "features/advantiv/classes/advantivReport";


export class AdvantivService {

    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = new EnvironmentService().getEnvironment().backends.advantivService;
        console.log(this.baseUrl)
        // this.baseUrl = 'http://localhost:9002/api/advantiv'
    }


    // Campaigns
    public async listCampaigns(projectId: string): Promise<ApiResponse<PaginatedResponse<ICampaign[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return new ApiResponse<PaginatedResponse<ICampaign[]>>(serverResponse);
    }

    public async createCampaign(request: CreateCampaignRequest): Promise<ApiResponse<ICampaign>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': request.projectId
            },
            body: JSON.stringify({
                name: request.name,
                description: request.description,
                type: request.type,
                value: request.value,
                countryCode: request.countryCode
            })
        });

        return new ApiResponse<ICampaign>(serverResponse);
    }

    public async editCampaign(request: EditCampaignRequest): Promise<ApiResponse<ICampaign>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${request.campaignId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': request.projectId
            },
            body: JSON.stringify({
                name: request.name,
                type: request.type,
                value: request.value,
                minimumAge: request.minimumAge,
                maximumAge: request.maximumAge,
                duration: request.duration,
                minimalTVF: request.minimalTVF,
                date: request.date
            })
        });

        return await serverResponse.json();
    }

    public async deleteCampaign(projectId: string, campaignId: string): Promise<ApiResponse<void>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return new ApiResponse(serverResponse);
    }

    public async runCampaign(projectId: string, campaignId: string): Promise<ApiResponse<AdvantivReport>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/run`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return new ApiResponse(serverResponse);
    }


    // Campaign channels
    public async listCampaignChannels(projectId: string, campaignId:string) : Promise<ApiResponse<PaginatedResponse<IChannel[]>>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/channels`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return new ApiResponse<PaginatedResponse<IChannel[]>>(serverResponse);
    }

    public async editCampaignChannel(projectId, campaignid, request: EditChannelRequest): Promise<ApiResponse<IChannel>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignid}/channels/${request.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                name: request.name,
                TVF: request.TVF,
                CPM: request.CPM,
                scaleFactor: request.scaleFactor,
                cost: request.cost,
                enabled: request.enabled,
                capping: request.capping,
                locked: request.locked,
                type: request.type
            })
        });

        return new ApiResponse<IChannel>(serverResponse);
    }


    public async deleteCampaignChannel(projectId, campaignid, id:string) {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignid}/channels/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            }
        });
        

        return {};
    }

    public async createCampaignChannel(projectId:string, campaignId: string, request: EditChannelRequest): Promise<ApiResponse<IChannel>> {
        console.log(request)
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/channels/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                name: request.name,
                TVF: request.TVF,
                CPM: request.CPM,
                scaleFactor: request.scaleFactor,
                cost: request.cost,
                enabled: request.enabled,
                capping: request.capping,
                locked: request.locked,
                type: request.type
            })
        });

        return new ApiResponse<IChannel>(serverResponse);
    }


    public async editCampaignTVBaseline(projectId, campaignid, request: EditTVBaselineRequest): Promise<ApiResponse<ITVBaseline>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignid}/settings/${request.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                label: request.label,
                minimumAge: request.minimumAge,
                percentage: request.percentage
            })
        });

        return serverResponse.json();
    }


    public async deleteCampaignTVBaseline(projectId:string, campaignId: string, tvbaselineId:string): Promise<ApiResponse<{}>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/settings/${tvbaselineId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });
        return new ApiResponse<ITVBaseline>(serverResponse);
    }

    public async createCampaignTVBaseline(projectId:string, campaignId: string, request: EditTVBaselineRequest): Promise<ApiResponse<ITVBaseline>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/settings/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                label: request.label,
                minimumAge: request.minimumAge,
                percentage: request.percentage
            })
        });
        return new ApiResponse<ITVBaseline>(serverResponse);
    }

    // Campaign settings 
    public async listCampaignTvBaselineItems(projectId: string, campaignId:string) : Promise<ApiResponse<PaginatedResponse<ISetting[]>>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/settings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return new ApiResponse<PaginatedResponse<ISetting[]>>(serverResponse);
    }

    public async getCampaignStatistics(projectId: string, campaignId: string): Promise<ApiResponse<AdvantivReport>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/reportings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            }
        });

        return new ApiResponse<AdvantivReport>(serverResponse);
    }


    // Line Items
    public async listLineItems(countryCode: string): Promise<ApiResponse<IChannel[]>> {
        const serverResponse = await fetch(`${this.baseUrl}/lineitem-definitions/${countryCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
            }
        })

        return new ApiResponse<IChannel[]>(serverResponse);
    }
    // impact factors
    public async listImpactFactors(projectId: string) : Promise<ApiResponse<PaginatedResponse<IImpactFactor[]>>>{
        const serverResponse = await fetch(`${this.baseUrl}/impact-factors`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            }
        });

        return new ApiResponse<PaginatedResponse<IImpactFactor[]>>(serverResponse);
    }

    public async createImpactFactor(projectId: string, request: EditImpactFactorRequest) : Promise<IImpactFactor> {
        const serverResponse = await fetch(`${this.baseUrl}/impact-factors`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                channelDefinitionId: request.channelDefinitionId,
                adType: request.adType,
                avgPixelsInView: request.avgPixelsInView,
                avgViewingTime: request.avgViewingTime,
                avgPercWithSound: request.avgPercWithSound,
                avgScreenCoverage: request.avgScreenCoverage,
                relativeStrengthCreative: request.relativeStrengthCreative
            })
        });

        return await serverResponse.json();
    }

    public async editImpactFactor(projectId: string, request: EditImpactFactorRequest) : Promise<IImpactFactor> {
        console.log(request)
        const serverResponse = await fetch(`${this.baseUrl}/impact-factors/${request.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
            body: JSON.stringify({
                id: request.id,
                channelDefinitionId : request.channelDefinitionId,
                adType: request.adType,
                avgPixelsInView: request.avgPixelsInView,
                avgViewingTime: request.avgViewingTime,
                avgPercWithSound: request.avgPercWithSound,
                avgScreenCoverage: request.avgScreenCoverage,
                relativeStrengthCreative: request.relativeStrengthCreative
            })
        });

        return await serverResponse.json();
    }

    public async deleteImpactFactor(projectId: string, id: string) : Promise<ApiResponse<IImpactFactor>> {
        const serverResponse = await fetch(`${this.baseUrl}/impact-factors/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Project-Id': projectId
            },
        });

        return await serverResponse.json();
    }
}