import { useState } from "react";
import "../../admin.styles.scss";
import AdminTable from "../../components/admin-table/admin-table.component";
import AdminTitle from "../../components/admin-title/admin-title.component";
import { RolePermission } from "../../components/dialogs/create-dialogs/create-user.component";
import EditPermissionDialog from "../../components/dialogs/edit-dialogs/edit-permission.component";
import { usePermissions } from "../../hooks/use-permissions.hook";
import CreatePermissionDialog from "../../components/dialogs/create-dialogs/create-permission.component";
import useTableFilter from "../../hooks/table-filter.hook";
import useDetailsPane from "../../hooks/use-details-pane";
import useCopyToClipboard from "../../hooks/copy-to-clipboard.hook";
import { DetailsPaneDataType } from "../../../../store/details-pane-store";

const AdminPermissionsPage = () => {
    const {
        meta,
        createPermission,
        removePermission,
        updatePermission,
        permissions,
        dataLoading,
        nextPage,
        previousPage,
        setPage,
    } = usePermissions();

    const [filterValue, setFilterValue] = useState<string>("");
    const tableEntries = useTableFilter(permissions, filterValue);
    const detailsPane = useDetailsPane<RolePermission>(
        DetailsPaneDataType.permission,
        permissions
    );
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const { copy } = useCopyToClipboard();

    return (
        <>
            <div className="admin-layout-application-window">
                <AdminTitle
                    title="Manage Permissions"
                    description="View, Create, Update and Delete permissions within Devote."
                />

                <div className={`content-grid ${!detailsPane.isOpen && "collapsed"}`} style={{ marginTop: '15px', padding: '0px 50px' }}>
                    <div>
                        <AdminTable
                            column_names={[
                                {
                                    key: "name",
                                    header: "Permission Name",
                                },
                                {
                                    key: "description",
                                    header: "Permission Description",
                                },
                                {
                                    key: "enabled",
                                    header: "Permission Enabled",
                                },
                            ]}
                            value={tableEntries}
                            amountOfRows={meta?.take || 12}
                            onRowSelect={detailsPane.open}
                            selection={detailsPane.data}
                            isActive={detailsPane.isOpen}
                            selectedRowKey="id"
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            buttonLabel="Create Permission"
                            buttonIcon="pi pi-plus"
                            onButtonClick={() => setCreateDialogOpen(true)}
                        />
                    </div>

                    <div className="role-details-pane details-pane">
                        <div className="pane-title-container">
                            <p className="pane-title">Permission Details</p>
                            <i className="pi pi-times" onClick={detailsPane.close}></i>
                        </div>

                        <div className="pane-segment">
                            <div className="top-row">
                                <div>
                                    <h3 className="pane-heading">Name</h3>
                                    <p>{detailsPane.data?.name}</p>
                                </div>

                                <div className="actions-container">
                                    <p className="edit-link" onClick={detailsPane.edit.open}>
                                        Edit
                                    </p>
                                    <p
                                        className="edit-link"
                                        onClick={() =>
                                            detailsPane.data && removePermission(detailsPane.data.id)
                                        }
                                    >
                                        Delete
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="pane-segment">
                            <h3 className="pane-heading">Description</h3>
                            <div className="identifier-container">
                                <p className="identifier">{detailsPane.data?.description}</p>
                            </div>
                        </div>

                        <div className="pane-segment">
                            <h3 className="pane-heading">Permission Identifier</h3>
                            <div
                                className="identifier-container"
                                onClick={() => {
                                    detailsPane.data && copy(detailsPane.data?.id);
                                }}
                            >
                                <p className="identifier">{detailsPane.data?.id}</p>
                                <i className="pi pi-copy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CreatePermissionDialog
                isOpen={createDialogOpen}
                onClose={() => setCreateDialogOpen(false)}
                createPermission={createPermission}
            />

            {detailsPane.data && (
                <EditPermissionDialog
                    isOpen={detailsPane.edit.isOpen}
                    onClose={detailsPane.edit.close}
                    initialData={detailsPane.data}
                    updatePermission={updatePermission}
                />
            )}
        </>
    );
};

export default AdminPermissionsPage;