import { useCallback, useEffect, useState } from "react";
import { ResourceManagementService } from "../../../api/resource-management-service";
import { IProject } from "../../../interfaces/project.interface";
import { MetaField } from "./use-permissions.hook";

export function useProjects(doFetch: boolean = true, take: number = 12) {
    const resourceManagementService = new ResourceManagementService();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [data, setData] = useState<IProject[]>([]);
    const [meta, setMeta] = useState<MetaField | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchData = useCallback(() => {
        if (!doFetch) return;
        setIsLoading(true);
        resourceManagementService
            .getProjects({ take, page: currentPage })
            .then((result) => {
                setData(result.data);
                setMeta(result.meta);
            })
            .finally(() => setIsLoading(false));
    }, [doFetch, take, currentPage]);

    useEffect(fetchData, [take, currentPage]);

    const create = useCallback((data: IProject) => {
        return resourceManagementService
            .createProject(data.name, data.description)
            .then(fetchData);
    }, []);

    const remove = useCallback((id: string) => {
        const isConfirmed = window.confirm(
            "Are you sure you want to delete this project?"
        );
        if (!isConfirmed) return Promise.resolve();
        return resourceManagementService.removeProject(id).then(fetchData);
    }, []);

    const nextPage = useCallback(() => {
        if (meta && meta.hasNextPage) {
            setCurrentPage(currentPage + 1);
        }
    }, [meta, currentPage]);

    const previousPage = useCallback(() => {
        if (meta && meta.hasPreviousPage) {
            setCurrentPage(currentPage - 1);
        }
    }, [meta, currentPage]);

    const setPage = useCallback(
        (page: number) => {
            if (meta && page >= 1 && page <= meta.pageCount) {
                setCurrentPage(page);
            }
        },
        [meta]
    );

    return {
        meta,
        projects: data,
        createProject: create,
        removeProject: remove,
        updateProject: () => {
            alert("No API route for this action yet.");
            throw new Error("Not implemented");
        },
        dataLoading: isLoading,
        nextPage,
        previousPage,
        setPage,
    };
}