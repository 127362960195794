import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { RolePermission, UserRole } from "../features/admin/components/dialogs/create-dialogs/create-user.component";
import { UserEntry } from "../features/admin/pages/users/admin-users.component";
import { IProject } from "../interfaces/project.interface";

export enum DetailsPaneDataType {
    user = "user",
    project = "project",
    role = "role",
    permission = "permission",
}

interface DetailsPaneState {
    data: {
        [DetailsPaneDataType.user]?: {
            id: string;
            isOpen: boolean;
        };
        [DetailsPaneDataType.project]?: {
            id: string;
            isOpen: boolean;
        };
        [DetailsPaneDataType.role]?: {
            id: string;
            isOpen: boolean;
        };
        [DetailsPaneDataType.permission]?: {
            id: string;
            isOpen: boolean;
        };
    };
    setUser: (id: string, isOpen: boolean) => void;
    setProject: (id: string, isOpen: boolean) => void;
    setRole: (id: string, isOpen: boolean) => void;
    setPermission: (id: string, isOpen: boolean) => void;
}

export type DataRowType = UserEntry | IProject | UserRole | RolePermission;

const useDetailsPaneStore = create<DetailsPaneState>()(
    devtools(
        persist(
            (set) => ({
                data: {
                    user: undefined,
                    project: undefined,
                    role: undefined,
                    permission: undefined,
                },
                setUser: (id: string, isOpen: boolean) =>
                    set((state) => ({
                        ...state,
                        data: {
                            ...state.data,
                            user: {
                                id,
                                isOpen,
                            },
                        },
                    })),
                setProject: (id: string, isOpen: boolean) =>
                    set((state) => ({
                        ...state,
                        data: {
                            ...state.data,
                            project: {
                                id,
                                isOpen,
                            },
                        },
                    })),

                setRole: (id: string, isOpen: boolean) =>
                    set((state) => ({
                        ...state,
                        data: {
                            ...state.data,
                            role: {
                                id,
                                isOpen,
                            },
                        },
                    })),
                setPermission: (id: string, isOpen: boolean) =>
                    set((state) => ({
                        ...state,
                        data: {
                            ...state.data,
                            permission: {
                                id,
                                isOpen,
                            },
                        },
                    })),
            }),
            {
                name: "details_pane_storage",
            }
        )
    )
);

const useDetailsPaneStoreWrapper = () => {
    const detailsPaneStore = useDetailsPaneStore();
    const { data, setUser, setProject, setRole, setPermission } =
        detailsPaneStore;

    const setData = (type: DetailsPaneDataType, id: string, isOpen: boolean) => {
        switch (type) {
            case DetailsPaneDataType.user:
                setUser(id, isOpen);
                break;

            case DetailsPaneDataType.project:
                setProject(id, isOpen);
                break;

            case DetailsPaneDataType.role:
                setRole(id, isOpen);
                break;

            case DetailsPaneDataType.permission:
                setPermission(id, isOpen);
                break;

            default:
                break;
        }
    };

    return {
        data,
        setData,
    };
};

export default useDetailsPaneStoreWrapper;