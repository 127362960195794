/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from 'react';
import { ResourceManagementService } from "../../api/resource-management-service";
import { IProject } from "../../interfaces/project.interface";
import ProjectSelectListItem from "./project-select-list-item/project-select-list-item.component";
import ProjectSelectHeader from "./project-select-header/project-select-header.component";
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from "primereact/progressspinner";
import './project-select-dialog.styles.scss';

const ProjectSelectDialog = () => {

    const resourceManagementService = new ResourceManagementService();

    const [projects, setProjects] = React.useState<IProject[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [filterText, setFilterText] = React.useState<string>('');

    React.useEffect(() => {
        setLoading(true)
        resourceManagementService.getProjects().then((res: any) => {
            setProjects(res.data);
            setLoading(false)
        });
    }, []);

    return projects.length === 0 ? (
        <div style={{ width: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ width: '500px', padding: '20px' }}>

                {
                    loading ? (
                        <div className='project-select-loading-container'>
                            <ProgressSpinner strokeWidth={'3'} />
                            <p className='project-select-loading-text'>
                                Your projects are on their way, just a moment...
                            </p>
                        </div>
                    ) : 
                    (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <i className='fa-solid fa-shield-cat golden-badge' />

                            <p className='project-select-welcome-text'>
                                Welcome to the project selection page!
                                <br/><br/>
                                It looks like you haven't been added to any projects yet, in order to join a project, please reach out to your administrator.
                            </p>

            
                        </div>
                    )
                }
            </div>
        </div>
    ) : (

        <div className='project-select-dialog-container'>
            <ProjectSelectHeader filterText={filterText} setFilterText={setFilterText} />
            <TabView className='project-select-dialog-tabview'>
                <TabPanel header="All">
                    <div className='project-select-dialog-list'>
                        {
                            filterText ?
                                projects.filter((project: IProject) => (project.name.toLowerCase().includes(filterText.toLowerCase())))
                                    .map((project: IProject) => (<ProjectSelectListItem key={project.id} project={project} />))
                                :
                            projects.map((project: IProject) => (
                                <ProjectSelectListItem key={project.id} project={project} />
                            ))
                        }
                    </div>
                </TabPanel>
            </TabView>
        </div>


    );
}

export default ProjectSelectDialog;