/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./admin-title.styles.scss";
import React from "react";


interface AdminTitleProps {
    title: string;
    description: string;
}


const AdminTitle = (props: AdminTitleProps) => {
    return (
        <>
            <div className='admin-title-container'>
                <h1 style={{ color: '#fff' }}>{props.title}</h1>
                <h2 style={{ color: '#fff', fontSize: '14px', fontWeight: '400' }}>{props.description}</h2>
            </div>
        </>
    );
};

export default AdminTitle;