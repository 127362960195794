import React from 'react';
import {EnvironmentService} from "../../../environment/environment.service";

class ConnectorsApiService extends React.Component {

    static API_URL = new EnvironmentService().getEnvironment().backends.connectorsService;

    static async getAllSources() {
        return await (await fetch(`${this.API_URL}/datasources`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async getConfigFromSource(id) {
        return await (await fetch(`${this.API_URL}/config/${id}`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async createPreset(presetName, start_date, end_date, client_id, client_secret, scope, api_key, sourceId) {
        return await (await fetch(`${this.API_URL}/config`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({ presetName, start_date, end_date, client_id, client_secret, scope, api_key, sourceId }),
        })).json();
    }

    static async getAllSchemasForSource(sourceId) {
        return await (await fetch(`${this.API_URL}/datasources/${sourceId}/dataschemas`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async exchangeCodeForAccessToken(code, client_id, client_secret, redirect_uri, grant_type) {
        const requestBody = new URLSearchParams({
            code,
            client_id,
            client_secret,
            redirect_uri,
            grant_type,
        });
        return await (await fetch(`${this.API_URL}/oauth2-access-token/exchange-code`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }

    static async requestAccessTokenWithServiceAccount(serviceAccount) {
        const requestBody = new URLSearchParams({
            serviceAccount
        });
        return await (await fetch(`${this.API_URL}/oauth2-access-token/service-account`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }

    static async apiRequest(access_token, request_url, request_body, request_method) {
        const requestBody = new URLSearchParams({
            access_token,
            request_url,
            request_body,
        });
        return await (await fetch(`${this.API_URL}/api-request/${request_method.toLowerCase()}`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }
}
export default ConnectorsApiService;
