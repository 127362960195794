/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from 'react';
import { InputText } from "primereact/inputtext";
import './project-select-header.styles.scss';

const ProjectSelectHeader = ({ filterText, setFilterText }) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '105px' }}>
            <div>
                <h1 className='project-select-header-title'>Choose a project from the list below</h1>
            </div>

            <span className="p-input-icon-left project-select-header-search-container">
                <i className="pi pi-search" />
                <InputText className='project-select-header-search' placeholder="Search" onChange={e => setFilterText(e.target.value)} value={filterText} />
            </span>
        </div>
    );
}

export default ProjectSelectHeader;
