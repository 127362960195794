import { ENDPOINTS } from "../../../api/identity-service";
import useIdentityApi from "../../../api/utils/identity-hook.utils";
import { RolePermission } from "../components/dialogs/create-dialogs/create-user.component";

export interface MetaField {
    page: number;
    pageCount: number;
    take: number;
    itemCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export function usePermissions(doFetch: boolean = true, take: number = 12) {
    const result = useIdentityApi<RolePermission>(
        ENDPOINTS.PERMISSIONS,
        doFetch,
        take
    );

    return {
        meta: result.meta,
        permissions: result.data,
        createPermission: result.create,
        updatePermission: result.update,
        removePermission: result.remove,
        dataLoading: result.isLoading,
        nextPage: result.nextPage,
        previousPage: result.previousPage,
        setPage: result.setPage,
    };
}