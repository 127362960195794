/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ResourceManagementService } from "../../../../../api/resource-management-service";
import "../dialog.styles.scss";

interface CreateProjectDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateProjectDialog = (props: CreateProjectDialogProps) => {

    // Form state.
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

    // Form fields.
    const [projectName, setProjectName] = React.useState<string>('');
    const [projectDescription, setProjectDescription] = React.useState<string>('');

    // Form submission.
    const submitForm = () => {
        setFormIsSubmitting(true);

        const resourceManagementService = new ResourceManagementService();
        resourceManagementService.createProject(projectName, projectDescription).then((res: any) => {
            console.log("Created project", res);
            props.onClose();
        });
    }

    return (
        <Dialog  header='Create new project' visible={props.isOpen} onHide={props.onClose}  >

            <div style={{ width: '350px' }}>

                <div style={{ marginTop: '10px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Name:</p>
                    <InputText
                        style={{ width: '100%' }}
                        value={projectName} onChange={(e) => setProjectName(e.target.value)}
                    />
                </div>

                <div style={{ marginTop: '15px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Description:</p>
                    <InputText
                        style={{ width: '100%' }}
                        value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} />
                </div>

                <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                    <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => props.onClose()}/>

                    {
                        formIsSubmitting ?
                            ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                            ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitForm()} />)
                    }

                </div>
            </div>
        </Dialog>
    )
}

export default CreateProjectDialog;