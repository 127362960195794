import styled from "styled-components";

export const CenteredBase = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
`;

export const FitBody = styled.div`
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
`;

export const TopCenteredBase = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: start;
  flex-direction: column;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
}
  
  .p-dropdown {
    min-width: 260px;
    background-color: ${({ theme }) => theme.button};
  }
`;

export const CollapseSubItem = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  dd {
    min-width: fit-content;
    display: flex;
    align-items: center;
  }

  span {
    font-size: 42px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 14px;
  }
`;

export const DisplayBody = styled.pre`
  display: flex;
  padding: 16px;
  background-color: #2f3b4d;
  align-items: center;
  color: white;
  border-radius: 6px;
  margin-bottom: 12px;
  
  /* Make text selectable. */
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;

  .string { color: #0b84de; }
  .number { color: #e62984; }
  .boolean { color: #cf9a17; }
  .null { color: #c5d4d4; }
  .key { color: #23cfaf; }
`;
