const DialogTitle = ({ title, description, icon }) => {
    return (
        <div className="heading">
            <div className="title">
                <div className="icon-container">
                    <i className={icon} />
                </div>
                <div className="text">
                    <h2>{title}</h2>
                    <p className="description">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default DialogTitle;
