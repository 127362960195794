import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import "../dialog.styles.scss";
import DialogTitle from "../dialog-title";
import { IProject } from "../../../../../interfaces/project.interface";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    initialData?: IProject;
    updateProject: (data: IProject) => Promise<any>;
}

const EditProjectDialog = ({
                               isOpen,
                               onClose,
                               initialData,
                               updateProject,
                           }: Props) => {
    const { data, updateField, setData } = useDialogData<IProject>(
        initialData ?? {
            id: "",
            name: "",
            description: "",
        }
    );

    useEffect(() => {
        if (initialData) setData(initialData);
    }, [initialData, setData]);

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Edit Project"
                    description="Edit a project in the Devote platform."
                    icon="pi pi-pencil"
                />
            }
            visible={isOpen}
            style={{ width: "45rem" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(data, ["name", "description"], onClose, updateProject);
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="project-name" className="block">
                            Name
                        </label>
                        <InputText
                            id="project-name"
                            aria-describedby="project-name"
                            onChange={(e) => {
                                updateField("name", e.target.value);
                            }}
                            value={data.name}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default EditProjectDialog;