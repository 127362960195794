import { MetaField } from "./identity-hook.utils";

export function getAccessToken() {
    return localStorage.getItem("devote_access_token");
}

export function getDefaultHeaders() {
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
    };
}

export async function genericGetRequest<T>(url: string): Promise<{
    data: T;
    meta: MetaField;
}> {
    const response = await fetch(url, {
        method: "GET",
        headers: getDefaultHeaders(),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const result = await response.json();

    return result;
}