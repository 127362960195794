import {create} from 'zustand';
import { devtools, persist } from "zustand/middleware";
import { IProject } from "../../interfaces/project.interface";



export interface ProjectState {
    selectedProject: IProject | undefined;

    setSelectedProject: (project: IProject) => void;

    reset: () => void;
}

let projectStore: any = (set): ProjectState => ({
    selectedProject: undefined,

    setSelectedProject: (project: IProject) => set((state) => ({ ...state, selectedProject: project })),

    reset: () => set({ 
        selectedProject: undefined 
    }),
});


projectStore = devtools(projectStore);
projectStore = persist(projectStore, { name: 'devote_project_store' });

const useProjectStore = create(projectStore);

export default useProjectStore;
