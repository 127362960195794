/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { springbok_logo_black } from "../../../assets";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from 'primereact/message';
import * as helpers from "../../../store/auth-store/auth-store-helpers";
import "./springbok-gpt-home.styles.scss"


// This is the landing page, this is what is shown when the user clicks on the SpringbokGPT icon in the sidebar.
const SpringbokGPTLandingPage = ({ setCreateConversationDialogVisible }) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', paddingBottom: '100px' }}>
            <i style={{ color: '#4287f5', fontSize: '92px' }} className="fa-solid fa-comment-dots"></i>
            <p style={{ marginTop: '25px', fontSize: '24px' }}>Welcome to SpringbokGPT</p>

            <p style={{ marginTop: '20px', lineHeight: '32px'}}>
                SpringbokGPT is a chatbot build upon OpenAI tools that helps you connect to various data sources. <br />
                A friendly assistant will guide you through your data, help you write queries and interpret the meaning behind it.
            </p>

            <div>
            <p style={{ marginTop: '50px', fontSize: '12' }}>Click the button below to intialize your first conversation.</p>
            </div>
            <Button onClick={() => setCreateConversationDialogVisible(true)} style={{ height: '50px', marginTop: '20px' }} label="New Chat" />
              
       </div>
    )
}


// This is the modal for creating new conversations.
const SpringbokGPTCreateConversationModal = ({ setDialogVisible, startNewConversation, isLoading }) => {

    // Form state.
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

    // Form fields.
    const [title, setTitle] = React.useState<string>('');
    const [project, setProject] = React.useState<string>('');
    const [dataset, setDataset] = React.useState<string>('');
    const [table, setTable] = React.useState<string>('');
    const [credentials, setCredentials] = React.useState<string>('');

    // Form submission.
    const submitForm = () => {
        startNewConversation(title, project, dataset, table, credentials);
        setTitle('');
        setProject('');
        setDataset('');
        setTable('');
        setCredentials('');
    }

    return (
        <div style={{ width: '350px' }}>
            {
                isLoading ? 
                <ProgressSpinner /> :
                    
                <>
                    <p style={{ fontSize: '12px'}}>
                        In order to create a new conversation we need to couple SpringbokGPT to a table in BigQuery.
                    </p>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>
                        Due to it's early stage of development we will need you to fill out the form by hand.
                    </p>

                    <div style={{ marginTop: '35px', minWidth: '200px' }}>
                        <p style={{ fontSize: '13px', marginBottom: '3px' }}>Title:</p>
                        <InputText
                            style={{ width: '100%' }}
                            value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>

                    <div style={{ marginTop: '10px', minWidth: '200px' }}>
                        <p style={{ fontSize: '13px', marginBottom: '3px' }}>Project:</p>
                        <InputText
                            style={{ width: '100%' }}
                            value={project} onChange={(e) => setProject(e.target.value)} />
                    </div>

                    <div style={{ marginTop: '10px', minWidth: '200px' }}>
                        <p style={{ fontSize: '13px', marginBottom: '3px' }}>Dataset:</p>
                        <InputText
                            style={{ width: '100%' }}
                            value={dataset} onChange={(e) => setDataset(e.target.value)} />
                    </div>

                    <div style={{ marginTop: '10px', minWidth: '200px' }}>
                        <p style={{ fontSize: '13px', marginBottom: '3px' }}>Table:</p>
                        <InputText
                            style={{ width: '100%' }}
                            value={table} onChange={(e) => setTable(e.target.value)} />
                    </div>

                    <div style={{ marginTop: '10px', minWidth: '200px' }}>
                        <p style={{ fontSize: '13px', marginBottom: '3px' }}>Credentials:</p>
                        <InputTextarea
                            style={{ width: '100%' }}
                            value={credentials} onChange={(e) => setCredentials(e.target.value)} />
                    </div>


                    <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                        <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                        {
                            formIsSubmitting ?
                                ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                                ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitForm()} />)
                        }
                        
                    </div>
                </>
            }
        </div>
    )
};


// This is the component that renders the chat entries.
const SpringbokGPTChatEntry = (props) => {

    const editorRef = React.useRef<any>(null);

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;
        updateEditorHeight();
    }

    const updateEditorHeight = () => {
        if (editorRef.current) {
            const editorContentHeight = editorRef.current.getContentHeight();
            editorRef.current.layout({ height: editorContentHeight });

            editorRef.current.updateOptions({
                viewInfo: {
                  marginViewInfo: {
                    renderOverlays: false, // Set renderOverlays to false to turn off margin-view-overlays
                  },
                },
              });
        }
    }

    return (
        <div style={{ padding: '20px 0', border: props.message.sender_type === 'ai' ? '1px solid rgba(0,0,0,.1)': 'none', background: props.message.sender_type === 'human' ? 'rgb(255, 255, 255)' : 'rgb(246, 246, 247)', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%',  position: 'relative', alignItems: 'center' }}>

    
                <div style={{ marginLeft: '50px' }}>
                    { 
                        props.message.sender_type === 'ai' ? <img src={springbok_logo_black} style={{ width: '40px', height: '40px' }} /> :
                        props.message.sender_type === 'human' ? <div style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#42b9f5' }}></div> :
                        <div style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#000' }}></div>
                    }
                </div>

                <div style={{ marginLeft: '50px', maxWidth: '600px', minWidth: '600px'}}>
                    {
                        props.message.message_type === 'conversation' ? 
                            <p style={{ whiteSpace: 'pre-wrap', lineHeight: '22px', fontSize: '15px', color: 'rgb(55, 65, 81)'  }}>{props.message.content.message}</p> :

                        props.message.message_type === 'table_analysis' ?
                            <div style={{ maxWidth: '500px' }}>
                                <p style={{ whiteSpace: 'pre-wrap', lineHeight: '22px', fontSize: '15px', color: 'rgb(55, 65, 81)'  }}>{props.message.content.table_analysis}</p>
                                <p style={{ whiteSpace: 'pre-wrap', lineHeight: '22px',fontSize: '15px', color: 'rgb(55, 65, 81)', marginTop: '10px' }}>{props.message.content.table_improvements}</p>
                            </div> :

                        props.message.message_type === 'query_suggestion' ?
                            <div>
                                <SyntaxHighlighter language="sql" style={vs2015} showLineNumbers>
                                    {props.message.content.query}
                                </SyntaxHighlighter>
                    
                    
                                <p style={{ whiteSpace: 'pre-wrap',lineHeight: '22px', fontSize: '15px', color: 'rgb(55, 65, 81)', marginTop: '10px'  }}>{props.message.content.explanation}</p>
                            </div> :
                        null
                    }
                </div>
       
    

        </div>
    );
};


// This is the component in the sidebar that shows a single conversation entry.
const SpringbokGPTConversationEntry = ({ conversation, setConversation, fetchConversation }) => {

    const params: any = useParams();
    const navigate = useNavigate();

    const isActive = params.id === conversation.conversation_id;

    const openConversation = () => {
        navigate(`/springbok-gpt/${conversation.conversation_id}`);
        fetchConversation(conversation.conversation_id);
    }

    return (
        <div onClick={() => openConversation()} style={{ cursor: 'pointer', backgroundColor: isActive ? 'rgb(50,50,50)' : 'rgb(29,30,32)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '260px', minHeight: '60px', position: 'relative' }}>
            <i className="pi pi-comment" style={{ color: 'rgb(255, 255, 255)', fontSize: '20px', marginLeft: '25px' }}></i>
            <p style={{ color: '#fff', marginLeft: '10px', fontSize: '11px' }}>{ conversation.title }</p>
        </div>
    )
};


// This is the main component for the Springbok GPT page.
const SpringbokGPTHome = () => {
    
    // Converation id
    const params: any = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    // The list of conversations.
    const [conversations, setConversations] = React.useState([]);
    const [conversation, setConversation] = React.useState<any>(null);

    // This question asked property in only used to trigger a temporary text entry in the chat while waiting for an answer.
    const [questionAsked, setQuestionAsked] = React.useState<string | undefined>(undefined);
    const [isLoadingAnswer, setIsLoadingAnswer] = React.useState<boolean>(false);


    // Message is what is typed in the input field of the chat.
    const [message, setMessage] = React.useState<string>('');

    // Create conversation dialog
    const [createConversationDialogVisible, setCreateConversationDialogVisible] = React.useState<boolean>(false);

    // Check if a conversation id is passed in the url.
    React.useEffect(() => {
        fetchConversations();
        if (params.id) {
            setIsLoading(true);
            fetchConversation(params.id);
        } 
    }, []);

    const fetchConversations = async () => {
        const userId = helpers.getAccessTokenDecoded()['sub'];
        const response = await fetch(`https://staging-devote.springbokagency.com/api/springbokgpt/conversations?user_id=${userId}`);
        const data = await response.json();
        setConversations(data);
    };

    const fetchConversation = async (conversationId) => {
        const userId = helpers.getAccessTokenDecoded()['sub'];
        const response = await fetch(`https://staging-devote.springbokagency.com/api/springbokgpt/conversations/${conversationId}?user_id=${userId}`);
        const data = await response.json();
        setConversation(data);
        setIsLoading(false);
        setIsLoadingAnswer(false);
        setQuestionAsked(undefined);
        setCreateConversationDialogVisible(false);
    };

    const sendMessage = async () => {
        const content = message;
        const userId = helpers.getAccessTokenDecoded()['sub'];

        // Set the question property to the message that is typed in the input field.
        setQuestionAsked(content);

        // Clear the input field.
        setMessage('');

        // Setup the loading state for an answer.
        setIsLoadingAnswer(true);

        // Send the question to the Springbok GPT API.
        const response = await fetch(`https://staging-devote.springbokagency.com/api/springbokgpt/conversations/${conversation.conversation_id}?user_id=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({content: content})
        });

        // Refresh the conversation when the answer is given.
        fetchConversation(conversation.conversation_id);
    };

    const startNewConversation = async (title, project, dataset, table, credentials) => {
        // Set the loading property, so the loading spinner is shown.
        setIsLoading(true);

        // Create a new conversation.
        const response = await fetch(`https://staging-devote.springbokagency.com/api/springbokgpt/conversations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                userId: helpers.getAccessTokenDecoded()['sub'],
                tableId: `${project}:${dataset}.${table}`,
                credentials: JSON.parse(credentials)
            })
        });

        // Get the conversation id from the response.
        const conversation = await response.json();
        fetchConversations();
        fetchConversation(conversation.conversation_id);
    };


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', minHeight: '100vh', position: 'relative' }}>

                {/* Chat selection */}
                <div style={{ backgroundColor: 'rgb(29,30,32)', display: 'flex', flexDirection: 'column', width: '260px', minHeight: '100vh', position: 'relative' }}>
                    <Button onClick={() => setCreateConversationDialogVisible(true)} style={{ height: '50px' }} label="New Chat" icon="pi pi-plus" iconPos="right" />
                
                    { conversations.map((conversation) => <SpringbokGPTConversationEntry conversation={conversation} setConversation={setConversation} fetchConversation={fetchConversation} /> ) }
                </div>

                {/* Chat area */}
                <div style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh', position: 'relative' }}>
                    { conversation === null ? 
                        <SpringbokGPTLandingPage setCreateConversationDialogVisible={setCreateConversationDialogVisible} /> :
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '10px', height: '5vh', backgroundColor: '#ccc' }}>
                                <p>{ isLoading ? 'loading...' : 'This chat is about: ' + conversation.table_id }</p>
                            </div>
                            
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 0, margin: 0, minHeight: '85vh', maxHeight: '85vh' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                                {
                                    isLoading ? <p>Loading...</p> : conversation.messages.map((message) => {
                                        return (
                                            <SpringbokGPTChatEntry message={message} />
                                        );
                                    })
                                }
                                {
                                    questionAsked && <SpringbokGPTChatEntry message={{sender_type: 'human', message_type: 'conversation', content: {message: questionAsked}}} />
                                }
                                {
                                    questionAsked && <SpringbokGPTChatEntry message={{sender_type: 'ai', message_type: 'conversation', content: {message: 'Loading...'}}} />
                                }
                                </div>
                            </div>
                

                            {/* Chat Input Box */}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '10vh', width: '100%' }}>
                                <span className="p-input-icon-right">
                                    <i onClick={() => sendMessage()} className="pi pi-send" style={{ marginRight: '5px', marginTop: '-7px', cursor: 'pointer' }} />
                                    <InputText onKeyDown={(e) => { if (e.key === 'Enter') { sendMessage() } } } value={message} onChange={(e) => setMessage(e.target.value)} style={{ paddingLeft: '10px', width: '800px', height: '50px', boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.3)' }} placeholder="Type your message here" />
                                </span>
                            </div>
                        </>
                    }
                </div>
            </div>

            <Dialog header='Create new conversation' visible={createConversationDialogVisible} onHide={() => {setCreateConversationDialogVisible(false)}}>
                <SpringbokGPTCreateConversationModal startNewConversation={startNewConversation} setDialogVisible={setCreateConversationDialogVisible} isLoading={isLoading} />
            </Dialog>
        </>
    );
};

export default SpringbokGPTHome;