import styled from "styled-components";

export const CTitle = styled.div`
  
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    letter-spacing: 20px;
    position: relative;
    cursor: pointer;
    &:before {
      content: "Connectors";
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      max-width: 400px;
      color: transparent;
      letter-spacing: 10px;
      text-shadow: none;
    }
    font: 900 60px Montserrat;
    text-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  }
  &:hover h1:before {
    transition: 0.6s ease;
    color: inherit;
    animation: jump-out 0.6s ease forwards;
  }


  @keyframes jump-out {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

export const CBaseTitle = styled.div`
  position: relative;
  padding: 2em;
  overflow: hidden;
  scroll-snap-align: center;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
  &:hover:before {
    opacity: 0.5;
  }
`;