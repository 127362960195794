import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import {
    RolePermission,
    UserRole,
} from "../create-dialogs/create-user.component";
import DialogTitle from "../dialog-title";
import "../dialog.styles.scss";
import { MultiSelect } from "primereact/multiselect";
import { usePermissions } from "../../../hooks/use-permissions.hook";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    initialData?: UserRole;
    updateRole: (data: UserRole) => Promise<any>;
}

const EditRoleDialog = ({
                            isOpen,
                            onClose,
                            initialData,
                            updateRole,
                        }: Props) => {
    const { data, updateField, setData } = useDialogData<UserRole>(
        initialData ?? {
            id: "",
            name: "",
            description: "",
            permissions: [],
        }
    );

    const { permissions } = usePermissions(true, -1);

    useEffect(() => {
        if (initialData) setData(initialData);
    }, [initialData, setData]);

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Edit Role"
                    description="Edit a role in the Devote platform."
                    icon="pi pi-pencil"
                />
            }
            visible={isOpen}
            style={{ width: "45rem" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(data, ["name", "description"], onClose, updateRole);
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="role-name" className="block">
                            Name
                        </label>
                        <InputText
                            id="role-name"
                            aria-describedby="role-name"
                            onChange={(e) => {
                                updateField("name", e.target.value);
                            }}
                            value={data.name}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="role-description" className="block">
                            Description
                        </label>
                        <InputText
                            id="role-description"
                            aria-describedby="role-description"
                            onChange={(e) => {
                                updateField("description", e.target.value);
                            }}
                            value={data.description}
                        />
                    </div>
                </div>

                <div className="input-field">
                    <label htmlFor="role-permissions" className="block">
                        Permissions
                    </label>
                    <MultiSelect
                        value={data.permissions ?? []}
                        options={permissions}
                        onChange={(e) => updateField("permissions", e.value)}
                        optionLabel="name"
                        placeholder="Select Permissions"
                        filter
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default EditRoleDialog;