import React from 'react';

class GoogleOAuth2Service extends React.Component {

    static BASEOAUTH2CODEURL = 'https://accounts.google.com/o/oauth2/v2/auth';

    static requestOAuth2Code = (client_id, redirect_uri, scope) => {
        let responseType = 'code';
        let prompt = 'consent';

        let endpoint = `${this.BASEOAUTH2CODEURL}?client_id=${encodeURI(client_id)}&redirect_uri=${encodeURI(redirect_uri)}&response_type=${encodeURI(responseType)}&scope=${encodeURI(scope)}&prompt=${encodeURI(prompt)}`;
        window.location.replace(endpoint);
    }
}
export default GoogleOAuth2Service;