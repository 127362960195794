import React from "react";
import {EnvironmentService} from "../../../environment/environment.service";
import GetAllProductsResponse from "../types/GetAllProductsResponse";
import GetProductPropertiesResponse from "../types/GetProductPropertiesResponse";
import EditFileResponse from "../types/EditFileResponse";
import ExecuteTerraformResponse from "../types/ExecuteTerraformResponse";

class CloudFormerApiService extends React.Component {

    static API_URL = new EnvironmentService().getEnvironment().backends.cloudFormerService;

    static async getAllProducts(): Promise<GetAllProductsResponse> {
        return await (await fetch(`${this.API_URL}/products`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async getProductConfiguration(product: string): Promise<GetProductPropertiesResponse> {
        return await (await fetch(`${this.API_URL}/products/${product}/config`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async createDirectory(product: string): Promise<EditFileResponse> {
        return await (await fetch(`${this.API_URL}/products/${product}/create-dir`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async copyFiles(product: string, directory: string): Promise<EditFileResponse> {
        return await (await fetch(`${this.API_URL}/products/${product}/copy-files/${directory}`, {
            method: 'GET',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
        })).json();
    }

    static async terraformGenerate(product: string, properties: any): Promise<any> {
        return (await fetch(`${this.API_URL}/${product}/generate`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(properties),
        }));
    }

    static async terraformInit(product: string, properties: any, directory: string): Promise<ExecuteTerraformResponse> {
        const requestBody = new URLSearchParams();
        Object.entries(properties).forEach(([key, value]) => {
            requestBody.append(key, String(value));
        });
        return await (await fetch(`${this.API_URL}/${product}/init/${directory}`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }

    static async terraformPlan(product: string, properties: any, directory: string): Promise<ExecuteTerraformResponse> {
        const requestBody = new URLSearchParams();
        Object.entries(properties).forEach(([key, value]) => {
            requestBody.append(key, String(value));
        });
        return await (await fetch(`${this.API_URL}/${product}/plan/${directory}`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }

    static async terraformApply(product: string, properties: any, directory: string): Promise<ExecuteTerraformResponse> {
        const requestBody = new URLSearchParams();
        Object.entries(properties).forEach(([key, value]) => {
            requestBody.append(key, String(value));
        });
        return await (await fetch(`${this.API_URL}/${product}/apply/${directory}`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }

    static async terraformDestroy(product: string, properties: any, directory: string): Promise<ExecuteTerraformResponse> {
        const requestBody = new URLSearchParams();
        Object.entries(properties).forEach(([key, value]) => {
            requestBody.append(key, String(value));
        });
        return await (await fetch(`${this.API_URL}/${product}/destroy/${directory}`, {
            method: 'POST',
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            cache: "no-cache",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: requestBody,
        })).json();
    }
}
export default CloudFormerApiService;