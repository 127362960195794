import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect } from "react";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import { useRoles } from "../../../hooks/use-roles.hook";
import { useUsers } from "../../../hooks/use-users";
import { UserEntry } from "../../../pages/users/admin-users.component";
import { UserRole } from "../create-dialogs/create-user.component";
import DialogTitle from "../dialog-title";
import "../dialog.styles.scss";

interface EditUserRole extends UserRole {
    value: string;
    checked: boolean;
}

export interface EditUserData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: EditUserRole[];
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    initialData?: EditUserData;
    updateUser: (data: UserEntry) => Promise<any>;
}

const EditUserDialog = ({
                            isOpen,
                            onClose,
                            initialData,
                            updateUser,
                        }: Props) => {
    const { roles } = useRoles();

    const { data, updateField, setData } = useDialogData<EditUserData>(
        initialData ?? {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            roles: [],
        }
    );

    useEffect(() => {
        if (initialData) setData(initialData);
    }, [initialData, setData]);

    const submit = useCallback((user: EditUserData) => {
        const parsedUser: UserEntry = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            roles: user.roles,
        };
        return updateUser(parsedUser);
    }, []);

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Edit User"
                    description="Edit a user in the Devote platform."
                    icon="pi pi-user-edit"
                />
            }
            visible={isOpen}
            style={{ width: "45rem" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(
                                data,
                                ["firstName", "lastName", "email"],
                                onClose,
                                submit
                            );
                        }}
                    >
                        Save
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="user-first-name" className="block">
                            First name
                        </label>
                        <InputText
                            id="user-first-name"
                            aria-describedby="user-first-name"
                            onChange={(e) => {
                                updateField("firstName", e.target.value);
                            }}
                            value={data.firstName}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="user-last-name" className="block">
                            Last name
                        </label>
                        <InputText
                            id="user-last-name"
                            aria-describedby="user-last-name"
                            onChange={(e) => {
                                updateField("lastName", e.target.value);
                            }}
                            value={data.lastName}
                        />
                    </div>
                </div>

                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="user-email" className="block">
                            Email
                        </label>
                        <InputText
                            id="user-email"
                            aria-describedby="user-email"
                            onChange={(e) => {
                                updateField("email", e.target.value);
                            }}
                            value={data.email}
                        />
                    </div>
                </div>

                <div className="roles-selector input-field">
                    <label htmlFor="roles" className="block">
                        Roles
                    </label>

                    <div className="roles-selector__container">
                        {roles.map((role) => {
                            const isChecked =
                                data.roles.find((r) => r.value === role.name)?.checked || false;

                            return (
                                <div className="roles-selector__item" key={role.name}>
                                    <label htmlFor={`checkbox-${role.name}`}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${role.name}`}
                                            checked={isChecked}
                                            onChange={(e) => {
                                                const roles = data.roles.filter(
                                                    (r) => r.value !== role.name
                                                );
                                                roles.push({
                                                    ...role,
                                                    value: role.name,
                                                    checked: e.target.checked,
                                                });
                                                updateField("roles", roles);
                                            }}
                                        />
                                        <span className="p-checkbox-label">{role.name}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default EditUserDialog;