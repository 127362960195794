import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import useDialogData, { submitData } from "../../../hooks/dialog-data.hook";
import DialogTitle from "../dialog-title";
import "../dialog.styles.scss";
import { UserRole } from "./create-user.component";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    createRole: (data: UserRole) => Promise<void>;
}

const CreateRoleDialog = ({ isOpen, onClose, createRole }: Props) => {
    const { data, updateField } = useDialogData<UserRole>({
        name: "",
        description: "",
        id: "",
        permissions: [],
    });

    return (
        <Dialog
            header={
                <DialogTitle
                    title="Create Role"
                    description="Add a new role to the Devote platform."
                    icon="pi pi-calendar-plus"
                />
            }
            visible={isOpen}
            style={{ width: "45vw" }}
            footer={
                <div>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            submitData(data, ["name", "description"], onClose, createRole);
                        }}
                    >
                        Create
                    </Button>
                </div>
            }
            onHide={onClose}
        >
            <div className="form-container">
                <div className="field-group-row">
                    <div className="input-field">
                        <label htmlFor="role-name" className="block">
                            Role Name
                        </label>
                        <InputText
                            id="role-name"
                            aria-describedby="role-name"
                            onChange={(e) => {
                                updateField("name", e.target.value);
                            }}
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="role-description" className="block">
                            Role Description
                        </label>
                        <InputText
                            id="role-description"
                            aria-describedby="role-description"
                            onChange={(e) => {
                                updateField("description", e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateRoleDialog;