import { useEffect, useState } from "react";

export default function useTableFilter<T>(value: T[], query: string) {
    const [filteredEntries, setFilteredEntries] = useState<T[]>(value);

    useEffect(() => {
        if (query === "") {
            setFilteredEntries(value);
            return;
        }

        const filtered = value.filter((entry) => {
            if (typeof entry !== "object" || entry === null) return false;
            const values = Object.values(entry);

            return values.some((value) => {
                if (typeof value !== "string") return false;
                return value.toLowerCase().includes(query.toLowerCase());
            });
        });

        setFilteredEntries(filtered);
    }, [query, value]);

    return filteredEntries;
}