/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React, { useState } from "react";
import AdminTable from "../../components/admin-table/admin-table.component";
import AdminTitle from "../../components/admin-title/admin-title.component";
import useTableFilter from "../../hooks/table-filter.hook";
import useCopyToClipboard from "../../hooks/copy-to-clipboard.hook";
import CreateProjectDialog from "../../components/dialogs/create-dialogs/create-project.component";
import EditProjectDialog from "../../components/dialogs/edit-dialogs/edit-project.component";
import useDetailsPane from "../../hooks/use-details-pane";
import { DetailsPaneDataType } from "../../../../store/details-pane-store";
import { IProject } from "../../../../interfaces/project.interface";
import { useProjects } from "../../hooks/use-projects.hook";


const AdminProjectsPage = () => {
    const {
        projects,
        removeProject,
        updateProject,
    } = useProjects();

    const [filterValue, setFilterValue] = useState<string>("");
    const tableEntries = useTableFilter(projects, filterValue);
    const [createProjectDialogVisible, setCreateProjectDialogVisible] = useState<boolean>(false);
    const detailsPane = useDetailsPane<IProject>(DetailsPaneDataType.project, projects);
    const { copy } = useCopyToClipboard();

    return (
        <>
            <div className="admin-layout-application-window">

                <AdminTitle
                    title="Manage Projects"
                    description="View, Create, Update and Delete projects within Devote."
                />


                <div className={`content-grid ${!detailsPane.isOpen && "collapsed"}`} style={{ marginTop: '15px', padding: '0px 50px' }}>

                    {/* The table */}
                    <div>
                        <AdminTable
                            column_names={[
                                { key: "name", header: "Project Name", },
                                { key: "description", header: "Project Description", },
                            ]}
                            value={tableEntries}
                            amountOfRows={10}
                            onRowSelect={detailsPane.open}
                            selection={detailsPane.data}
                            isActive={detailsPane.isOpen}
                            selectedRowKey="id"
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            buttonLabel="Create Project"
                            buttonIcon="pi pi-plus"
                            onButtonClick={() => setCreateProjectDialogVisible(true)}
                        />
                    </div>

                    <div className="project-details-pane details-pane">
                        <div className="pane-title-container">
                            <p className="pane-title">Project Details</p>
                            <i className="pi pi-times" onClick={detailsPane.close}></i>
                        </div>

                        <div className="pane-segment">
                            <div className="top-row">
                                <div>
                                    <h3 className="pane-heading">Project</h3>
                                    <p>{detailsPane.data?.name}</p>
                                </div>
                                <div className="actions-container">
                                    <p className="edit-link" onClick={detailsPane.edit.open}>
                                        Edit
                                    </p>
                                    <p
                                        className="edit-link"
                                        onClick={() =>
                                            detailsPane.data && removeProject(detailsPane.data.id)
                                        }
                                    >
                                        Delete
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="pane-segment">
                            <h3 className="pane-heading">Description</h3>
                            <div className="identifier-container">
                                <p className="identifier">{detailsPane.data?.description}</p>
                            </div>
                        </div>

                        <div className="project-identifier pane-segment">
                            <h3 className="pane-heading">Project Identifier</h3>
                            <div
                                className="identifier-container"
                                onClick={() => detailsPane.data && copy(detailsPane.data?.id)}
                            >
                                <p className="identifier">{detailsPane.data?.id}</p>
                                <i className="pi pi-copy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CreateProjectDialog
                isOpen={createProjectDialogVisible}
                onClose={() => setCreateProjectDialogVisible(false)}
            />

            <EditProjectDialog
                isOpen={detailsPane.edit.isOpen}
                onClose={detailsPane.edit.close}
                initialData={detailsPane.data}
                updateProject={updateProject}
            />
        </>
    );
};

export default AdminProjectsPage;