import * as React from 'react';
import ConnectorsApiService from "../services/ConnectorsApi.service";
import OAuth2Service from "../services/OAuth2.service";
import DataSharingService from "../../../services/DataSharing.service";
import {useLocation, useNavigate} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import {DivRow, TopCenteredBase} from "./styles";
import {Button} from "primereact/button";
import {Field, Form, Formik} from "formik";

class ConnectorsSchemaConfig extends React.Component {

    initialValues = {};
    constructor(props) {
        super(props);
        this.state = {
            serviceAccount: props.serviceAccount ?? undefined,
            config: props.config ?? undefined,
            selectedSource: props.selectedSource,
            schemas: null,
            selectedSchema: null
        }
    }

    async componentDidMount() {
        await ConnectorsApiService.getAllSchemasForSource(this.state.selectedSource.id).then(
            configs => {
                this.setState({schemas: configs});
            }
        )
    }

    handleChangeOnSelect = (event) => {
        if (event.target.value !== undefined && event.target.value.id !== 0) {
            this.setState({
                selectedSchema: event.value,
            });
        } else {
            this.setState({selectedSchema: null});
        }
    }

    onSubmit = async (values) => {
        let requestURL = this.state.selectedSchema.base_URL + this.state.selectedSchema.URL_extension;
        this.state.selectedSchema.request_parameters.forEach((param) => {
            requestURL = requestURL.replace(param.parameter, values[param.parameter]);
        });
        DataSharingService.setAttribute(requestURL, 'requestUrl');
        DataSharingService.setObject(values.request_body ?? null, 'requestBody');
        DataSharingService.setObject(this.state.config, 'config');
        DataSharingService.setObject(this.state.selectedSchema, 'selectedSchema');
        if (this.state.serviceAccount) {
            const token = await ConnectorsApiService.requestAccessTokenWithServiceAccount(this.state.serviceAccount);
            this.props.navigate('/connectors/service-account', { state: { token: token}});
            return;
        }
        OAuth2Service.requestOAuth2Code(
            this.state.config.client_id,
            'https://staging-devote.springbokagency.com/connectors/oauthorization',
            this.state.config.scope
        );
    }

    render() {
        if (this.state.selectedSchema && this.state.selectedSchema.request_parameters) {
            this.state.selectedSchema.request_parameters.forEach((param) => {
                this.initialValues[param.parameter] = '';
            });
        }

        return (
            <TopCenteredBase>
                <DivRow style={{
                    marginTop: 20 + "px",
                    borderBottom: "2px solid white",
                    paddingBottom: 10 + "px",
                    marginBottom: 10 + "px"
                }}>
                    <Dropdown value={this.state.selectedSchema}
                              options={this.state.schemas}
                              onChange={(e) => this.handleChangeOnSelect(e)}
                              optionLabel="name"
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select a data schema"
                              style={{width: 100 + "%"}}/>
                </DivRow>

                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={this.initialValues}
                    onSubmit={(values) => {
                        this.onSubmit(values);
                    }}>
                    {({handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                            {(() => {
                                let elements = [];
                                if (this.state.selectedSchema && this.state.selectedSchema.request_parameters) {
                                    this.state.selectedSchema.request_parameters.forEach((param) => {
                                        elements.push(
                                            <div key={param.id} style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginBottom: "12px"
                                            }}>
                                                <label style={{marginLeft: "4px"}}>{param.parameter}:</label>
                                                <Field style={{
                                                    borderRadius: "4px",
                                                    fontSize: "1rem",
                                                    padding: "0.43rem"
                                                }}
                                                       id={param.parameter}
                                                       name={param.parameter}
                                                       placeholder={param.parameter}
                                                       required/>
                                            </div>
                                        );
                                    });
                                }

                                if (this.state.selectedSchema && this.state.selectedSchema.request_body) {
                                    elements = elements.concat(
                                        <div key={"request_body"} style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "12px"
                                        }}>
                                            <label style={{marginLeft: "4px"}}>Request body (JSON format):</label>
                                            <Field as="textarea"
                                                   style={{
                                                       borderRadius: "4px",
                                                       fontSize: "1rem",
                                                       padding: "0.43rem"
                                                   }}
                                                   id="request_body"
                                                   name="request_body"
                                                   defaultValue={JSON.stringify(this.state.selectedSchema.request_body, undefined, 4)}
                                                   placeholder="Request body"
                                                   rows="18" cols="100"
                                                   required>
                                            </Field>
                                        </div>
                                    );
                                }
                                return <div>{elements}</div>;
                            })()}

                            <div>
                                <Button type="submit" disabled={!this.state.selectedSchema}>Submit</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </TopCenteredBase>
        );
    }
}

function WithNavigate() {
    if (useLocation().state.serviceAccount) {
        return <ConnectorsSchemaConfig serviceAccount={useLocation().state.serviceAccount} selectedSource={useLocation().state.selectedSource} navigate={useNavigate()}></ConnectorsSchemaConfig>
    }
    return <ConnectorsSchemaConfig config={useLocation().state.config} selectedSource={useLocation().state.selectedSource} navigate={useNavigate()}></ConnectorsSchemaConfig>
}

export default WithNavigate
