import {LoaderBase, LoaderIcon} from "./styles";
import React from "react";

const Loader = (props) => {
    return (
        <LoaderBase>
            <LoaderIcon></LoaderIcon>
            <h3 style={{marginTop:"12px", color:"black"}}>{ props.message }</h3>
        </LoaderBase>
    )
}

export default Loader