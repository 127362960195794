import styled from "styled-components";

export const LoaderBase = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  min-height: 40vh;
  width: 100%;
  margin:0;
  padding:0;
`;