import { useCallback, useEffect, useState } from "react";
import { ENDPOINTS, identityService } from "../identity-service";

export interface MetaField {
    page: number;
    pageCount: number;
    take: number;
    itemCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

function useIdentityApi<T>(
    endpoint: ENDPOINTS,
    doFetch: boolean = true,
    take: number = 12
) {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<T[]>([]);
    const [meta, setMeta] = useState<MetaField | null>(null);

    // Runs the get function for the endpoint at a provided page with a provided take
    const fetchFunction = useCallback(
        (options: { page: number; take: number }) => {
            return identityService.endpoints[endpoint].get(options);
        },
        [endpoint]
    );

    // Fetch the data for the current page
    const fetch = async (
        pageOverwrite: number | undefined = undefined,
        overWrite: boolean = true
    ) => {
        if (!doFetch) return;
        setIsLoading(true);

        try {
            const { data, meta } = await fetchFunction({
                page: pageOverwrite ? pageOverwrite : currentPage,
                take: take === -1 ? 12 : take,
            });
            if (overWrite) {
                setData(data as any);
            } else {
                setData((prev) => [...prev, ...data] as any);
            }
            setMeta(meta);

            if (
                meta &&
                (pageOverwrite
                    ? pageOverwrite < meta.pageCount
                    : meta.page < meta.pageCount) &&
                take === -1
            ) {
                fetch(meta.page + 1, false);
            }
        } catch {
            setError("Failed to fetch data");
        }

        setIsLoading(false);
    };

    // Increment the page counter
    const nextPage = useCallback(() => {
        if (meta && meta.hasNextPage) {
            setCurrentPage(currentPage + 1);
        }
    }, [meta, currentPage]);

    // Decrement the page counter
    const previousPage = useCallback(() => {
        if (meta && meta.hasPreviousPage) {
            setCurrentPage(currentPage - 1);
        }
    }, [meta, currentPage]);

    // Set the page counter to a specific page
    const setPage = useCallback(
        (page: number) => {
            if (meta && page >= 1 && page <= meta.pageCount) {
                setCurrentPage(page);
            }
        },
        [meta]
    );

    // Create a new item for the endpoint
    const create = useCallback(
        (data: T) => {
            return identityService.endpoints[endpoint]
                .create(data as any)
                .then(() => fetch());
        },
        [endpoint]
    );

    // Update an existing item for the endpoint
    const update = useCallback(
        (data: T) => {
            return identityService.endpoints[endpoint]
                .update(data as any)
                .then(() => fetch());
        },
        [endpoint]
    );

    // Delete an existing item for the endpoint
    const remove = useCallback(
        (id: string) => {
            const isConfirmed = window.confirm(
                "Are you sure you want to delete this item?"
            );
            if (!isConfirmed) return;
            return identityService.endpoints[endpoint].delete(id).then(() => fetch());
        },
        [endpoint]
    );

    // Fetch the data on mount and when the current page changes
    useEffect(() => {
        fetch();
    }, [currentPage]);

    return {
        data,
        meta,
        currentPage,
        isLoading,
        error,
        fetch,
        nextPage,
        previousPage,
        setPage,
        create,
        update,
        remove,
    };
}

export default useIdentityApi;