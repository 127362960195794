/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import { IEnvironment } from "./environment";
import { Environment as developmentEnvironment} from "./environment.development";
import { Environment as stagingEnvironment} from "./environment.staging";
import { Environment as productionEnvironment} from "./environment.production";

/**
 * This is the environment service. It is used to get the environment variables.
 */
export class EnvironmentService {

    /**
     * Get the environment variables.
     */
    public getEnvironment(): IEnvironment {
        switch (process.env.NODE_ENV) {
            case "development":
                return developmentEnvironment;
            case "test":
                return stagingEnvironment;
            case "production":
                return productionEnvironment;
            default:
                return developmentEnvironment;
        }
    }
}
