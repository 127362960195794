/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from 'primereact/card';
import { useProjectStore, useCampaignStore } from "../../../../store";
import { AdvantivService } from "../../../../api/advantiv-service";
import { ApiResponse } from "../../../../api/base/api-response";
import { ApiError } from "../../../../api/base/api-error";
import { PaginatedResponse } from "../../../../api/base/paginated-response";
import { EditChannelRequest } from "../../models/edit-channel-request";
import { EditTVBaselineRequest } from "../../models/edit-tvbaseline-request";
import { IProject } from "../../../../interfaces/project.interface";
import { ICampaign, IChannel, ITVBaseline } from "../../models/campaign";
import { AdvantivReport } from "../../models/report";
import Navbar from "../../../../components/navbar/navbar.component";
import CreateCampaignDialogComponent from "../../components/create-campaign-dialog/create-campaign-dialog.component";
import EditCampaignDialogComponent from "../../components/edit-campaign-dialog/edit-campaign-dialog.component";
import AdvantivChart from "../../components/advantiv-chart/advantiv-chart.component";
import './advantiv-campaign-overview.styles.scss'


const AdvantivCampaignOverview = () => {

    const navigate = useNavigate();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const advantivCampaignStore = useCampaignStore();

    const [renderReport, setRenderReport] = React.useState(false);
    const [globalChannelFilter, setGlobalChannelFilter] = React.useState('')
    const [globaltTvBaselineFilter, setGlobaltTvBaselineFilter] = React.useState('')
    const [channelNewItemDialog, setChannelNewItemDialog] = React.useState(false);
    const [displayCreateCampaignDialog, setDisplayCreateCampaignDialog] = React.useState(false);
    const [displayEditCampaignDialog, setDisplayEditCampaignDialog] = React.useState(false);
    const [selectedCampaign, setSelectedCampaign] = React.useState<ICampaign | null>(null);
    const [loadCampaigns, setLoadCampaigns] = React.useState<boolean>(true)
    const [campaigns, setCampaigns] = React.useState<ICampaign[]>([]);
    const [filtertedChannels, setFilterdChannels] = React.useState<{ label: string, value: string }[]>([])
    const [channels, setChannels] = React.useState<IChannel[]>([]);
    const [reachPerc, setReachPerc] = React.useState<any>({});
    const [reachAbs, setReachAbs] = React.useState<any>({});
    const [reachChannelPerc, setReachChannelPerc] = React.useState<any>({});
    const [users, setUsers] = React.useState<any>();
    const [cost, setCost] = React.useState<any>();
    const [plan, setPlan] = React.useState<any>([]);
    const [usage, setUsage] = React.useState<any>();
    const [dau, setDau] = React.useState<any>();
    const [reachStats, setReachStats] = React.useState<any>({});
    const [costStats, setCostStats] = React.useState<any>({});
    const [visibleCPMInfo, setVisibleCPMInfo] = React.useState(false)
    const [visibleTVFInfo, setVisibleTVFInfo] = React.useState(false)
    const [visibleScalefactorInfo, setVisibleScalefactorInfo] = React.useState(false)
    const [visiblebudgetLockedfactorInfo, setVisiblesetBudgetLockedfactorInfo] = React.useState(false)
    const [visibleFrequencyCappingInfo, setVisibleFrequencyCappingInfo] = React.useState(false)
    const [visibleEnabledInfo, setVisibleEnabledInfo] = React.useState(false)
    const [newCampaignChannel, setNewCampaignChannel] = React.useState(null)
    const [selectedChannels, setSelectedChannels] = React.useState<IChannel[]>([]);
    const [selectedTVBaseline, setSelectedTVBaseline] = React.useState<ITVBaseline[]>([]);
    const [loadingChannels, setLoadingChannels] = React.useState(true);
    const [loadingTvBaselineItems, setLoadingTvBaselineItems] = React.useState(true);

    React.useEffect(() => {
        // Always load campaigns when the component is mounted.
        setLoadCampaigns(true)
        collectCampaigns();

        // If a campaign has been selected before, reload the dashboard and pre-select this campaign.
        if (advantivCampaignStore.selectedCampaignId) {
            selectChartData();
        }
    }, []);

    const generateReachStats = (advantivReport: AdvantivReport): void => {
        setReachStats({
            targetAudience: {
                ages: {
                    min: Math.min(...advantivReport.report.age_active),
                    max: Math.max(...advantivReport.report.age_active),
                },
                abs: advantivReport.report.results.target_population,
                perc: 100
            },
            digitalReach: {
                abs: advantivReport.report.results.reach_fact_base,
                perc: advantivReport.report.results.reach_fact_perc * 100
            },
            improvement: {
                abs: advantivReport.report.results.reach_fact_base,
                perc: advantivReport.report.results.reach_fact_perc_base * 100
            }
        });
    }

    const generateCostStats = (advantivReport: AdvantivReport): void => {
        let costs: any[] = []
        for (const key in advantivReport.report.cost_sum) {
            const value = advantivReport.report.cost_sum[key];
            let label = advantivReport.report.plan_parameters.channels[key].label
            let percentage = (Math.round(advantivReport.report.cost_sum_perc[key] * 100 * 100)/100).toString() + "%"
            let color = hexToRGB(advantivReport.report.plan_parameters.channels[key].color_code.substring(1))

            costs.push({
                label: label,
                value: value,
                color: color,
                perc: percentage
            })
        }

        setCostStats({
            total: advantivReport.report.cost_sum_total,
            costs: costs
        });
    }

    const generatePlanStats = (advantivReport: AdvantivReport): void => {
        let items: any[] = []
        for (let key in advantivReport.report.plan_parameters.channels) {
            const data = advantivReport.report
            const plan_parameters = data.plan_parameters.channels[key];
            const channel_pop_reach = data.channel_pop_reach;
            const tvf1 = data.channel_pop_reach_tvf1;
            const itemCpm = plan_parameters.cpm
            const impressions = (data.cost_sum[key] / itemCpm) * 1000

            const costSum = data.cost_sum[key];
            const test = data.channel_pop_reach_tvf1.pop_reach_abs[key];
            const dgcfValue = ((costSum / itemCpm) * 1000) / test;

            let item = {
                label: plan_parameters.label,
                cost: costSum,
                cpm: plan_parameters.cpm,
                tvf: plan_parameters.tv_factor,
                tv_reach_abs: channel_pop_reach.pop_reach_abs[key],
                tv_reach_perc: channel_pop_reach.pop_reach_perc[key] * 100,
                dimpr: impressions,
                dr_abs: tvf1.pop_reach_abs[key],
                dr_perc: tvf1.pop_reach_perc[key],
                dgcf: dgcfValue ? Math.round(dgcfValue * 10) / 10 : '-'
            }
            items.push(item)

        }
        setPlan(items);
    }

    const renderAbsolute = (value: number) => {
        return value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }

    const plan_cost_template = (rawData) => {
        return formatCurrency(rawData['cost'])
    }

    const plan_cpm_template = (rawData) => {
        return formatCurrency(rawData['cpm'])
    }

    const plan_tvf_template = (rawData) => {
        return renderAbsolute(rawData['tvf'])
    }

    const plan_tv_reach_abs_template = (rawData) => {
        return renderAbsolute(rawData['tv_reach_abs'])
    }

    const plan_tv_reach_perc_template = (rawData) => {
        return renderPercentage(rawData['tv_reach_perc'])
    }

    const plan_dimpr_template = (rawData) => {
        return renderAbsolute(rawData['dimpr'])
    }

    const plan_dr_abs_template = (rawData) => {
        return renderAbsolute(rawData['dr_abs'])
    }

    const plan_dr_perc_template = (rawData) => {
        return renderPercentage(rawData['dr_perc'])
    }

    const plan_DGCF_template = (rawData) => {
        return renderAbsolute(rawData['dgcf'])
    }

    const handle_response_data = (data) => {
        setReachPerc(generateChartData(data.report, 'reach_plot_perc', "Reach TV + digital (%)", "Reach (%)", true, true, true, false));
        setReachAbs(generateChartData(data.report, 'reach_plot_abs', "Reach TV + digital (#)", "Reach (#)", true, true, false, false));
        setReachChannelPerc(generateChartData(data.report, 'reach_plot_channel_perc', "Reach", "Reach", true, true, true, false));
        setCost(generateChartData(data.report, 'cost', "Cost", "Cost (€)", true, true, false));
        setUsers(generateChartData(data.report, 'users', "Users", "Users", false, false, false));
        setUsage(generateChartData(data.report, 'usage', "Usage", "Usage (%)", false, false, true, false, false));
        setDau(generateChartData(data.report, 'daily_active_users_percentage', "Daily Active Users %", "Daily Active Users (%)", false, false, true, false, true));
        generateReachStats(data);
        generateCostStats(data);
        generatePlanStats(data);
        setRenderReport(true);
    }

    const selectChartData = () => {
        const advantivService = new AdvantivService();
        const currentProjectId = selectedProject.id;
        const currentCampaignId = selectedCampaign?.id!;

        advantivService.getCampaignStatistics(currentProjectId, currentCampaignId).then((apiResponse: ApiResponse<AdvantivReport>) => {
            setRenderReport(false);
            if (apiResponse.hasError()) {
                apiResponse.getError().then((error) => {
                    console.error('[Advantiv] Failed API call to fetch campaign statistics: ', error);
                });
                return;
            }else{
                apiResponse.getBody().then((data) => {
                    handle_response_data(data)
                });
            }
        });
    };
    
    const log = (pr, datakey) =>{
        if(datakey == 'reach_plot_channel_perc'){
            console.log(pr)
        }
    }

    const generateChartData = (rawData, dataKey, title, yTtile, stacked = false, fill = false, perc_data = false, display_total_line = true, remove_total = false) => {
        // labels
        let ageLabels = rawData.age_all
        log(rawData['reach_plot_channel_perc'], dataKey)

        // datasets
        let endDatasets: any[] = []
        let targetFound: boolean = false
        let totalDataset: any[] = []


        for (let i in ageLabels) {
            totalDataset.push(0)
        }
        for (let key in rawData[dataKey]) {
            
            log(key, dataKey)
            let datasets: any[] = []

            let dataIsZero = true;
            for (let index = 0; index < rawData[dataKey][key].length; index++) {
                let element: number = (rawData[dataKey][key][index] as number);
                if(element != 0){
                    dataIsZero = false;
                }
                if (perc_data) {
                    element = element * 100
                }
                datasets.push(element)
                if (key != 'target') {
                    totalDataset[index] += element
                }
            }

            if (key == 'target') {
                let dataset = {
                    'type': 'line',
                    'yAxisId': 'y_right',
                    'label': key,
                    'data': datasets,
                    'fill': false,
                    stacked: false,
                    backgroundColor: hexToRGB("808080"),
                    borderColor: hexToRGB("808080"),
                }
                if (datasets[0] != 0) {
                    endDatasets.push(dataset)
                    targetFound = true
                }
            }
            else if (key != 'target') {
                let datasetItem = {
                    'type': 'line',
                    'yAxisID': 'y',
                    'label': key,
                    'data': datasets,
                    'fill': fill,
                    backgroundColor: '',
                    borderColor: ''
                }
                if (rawData.plan_parameters.channels[key] != undefined) {
                    
                    datasetItem.label = rawData.plan_parameters.channels[key].label
                    datasetItem.backgroundColor = hexToRGB(rawData.plan_parameters.channels[key].color_code.substring(1))
                    datasetItem.borderColor = hexToRGB(rawData.plan_parameters.channels[key].color_code.substring(1))
                } else {
                    let items = {
                        "tv only": "rgba(235, 33, 97, 0.7)",
                        "overlap": "rgba(231, 235, 33, 0.7)",
                        "not reached": "rgba(235, 192, 40, 0.7)",
                        "digital only": "rgba(47, 247, 104, 0.7)",
                        "tv": "rgba(200, 200, 200, 1)",
                        "target": "rgba(16, 16, 16, 1)"
                    }
                    datasetItem.backgroundColor = items[key]
                    datasetItem.borderColor = items[key]
                }
                if(!dataIsZero){
                    endDatasets.push(datasetItem)
                }
            }

        }

        let datasetTotal = {
            'type': 'line',
            'yAxisId': 'y_right',
            'label': 'Total',
            'data': totalDataset,
            'fill': false,
            stacked: false,
        }

        if(!display_total_line){
            datasetTotal['backgroundColor']= "rgba(255,255,255, 0)"
            datasetTotal['borderColor'] = "rgba(255,255,255, 0)"
        }
        if (endDatasets[0] != 0 && remove_total == false) {
            endDatasets.push(datasetTotal)
        }
        let dataObj: {
            [key: string]: any;
        } = {
            labels: ageLabels,
            datasets: endDatasets
        }

        let optionsObj = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Age'
                    }
                },
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                title: {
                    display: true,
                    text: title,
                },
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
        }
        if (targetFound) {
            optionsObj['scales']['y_right'] = {
                stacked: false,
                type: 'linear',
                display: true,
                position: 'right',
            }
        }
        optionsObj['scales']['y'] = {
            stacked: stacked,
            title: {
                display: true,
                text: yTtile
            }
        }

        if (perc_data) {
            // @ts-ignore
            optionsObj.scales.y.min = 0
            // @ts-ignore
            optionsObj.scales.y.max = 100
            if (targetFound) {
                // @ts-ignore
                optionsObj.scales.y_right.min = 0
                // @ts-ignore
                optionsObj.scales.y_right.max = 100
            }
        }

        return {
            "data": dataObj,
            "options": optionsObj
        }

    }

    const runCampaign = () => {
        const advantivService = new AdvantivService();
        advantivService.runCampaign(selectedProject.id, selectedCampaign?.id!).then((response: ApiResponse<AdvantivReport>) => {
            if (response.hasError()) {
                let errors = ''
                response.getError().then((error: ApiError) => {
                    let messages = error.message
                    for (let key in messages) {
                        errors += messages[key] + "\n"
                    }
                    console.log("campaign run error", error);
                    if (error.message.length >= 1) {
                        alert('The campaign has multiple errors \n' + errors)
                    }
                    else {
                        alert('The campaign has a error: \n' + errors)
                    }
                })
            } else {
                selectChartData()
            }
        })
    }

    const setSelectedCampaignAction = (campaign: ICampaign) => {
        const advantivService = new AdvantivService();
        campaign.channels = []
        campaign.tvbaseline = []
        setLoadingChannels(true);
        setLoadingTvBaselineItems(true);

        advantivService.listCampaignChannels(selectedProject.id, campaign.id).then((response: ApiResponse<PaginatedResponse<IChannel[]>>) => {
            if (!response.hasError()) {
                response.getBody().then((paginatedResponse: PaginatedResponse<IChannel[]>) => {
                    campaign.channels = paginatedResponse.data
                    setSelectedCampaign(campaign)

                    advantivService.listLineItems(campaign.countryCode).then(async resp => {
                        let data = await resp.getBody()
                        setChannels(data['data'])
                        filterChannels(campaign, data['data'])
                        advantivCampaignStore.setSelectedCampaignId(campaign.id)

                    })
                    setLoadingChannels(false);
                })
            }
        })

        advantivService.listCampaignTvBaselineItems(selectedProject.id, campaign.id).then((response: ApiResponse<PaginatedResponse<ITVBaseline[]>>) => {
            if (!response.hasError()) {
                response.getBody().then(async (paginatedResponse: PaginatedResponse<ITVBaseline[]>) => {
                    campaign.tvbaseline = paginatedResponse.data
                    setSelectedCampaign(campaign)
                    setLoadingTvBaselineItems(false);
                })
            }
        })

        advantivService.getCampaignStatistics(selectedProject.id, campaign.id).then((resp) => {
            setRenderReport(false);
            if (resp.hasError()) {
                resp.getError().then((error: ApiError) => {
                    console.error("[Advativ] Failed to fetch campaign statistics", error);
                });
                return;
            }
            else {
                resp.getBody().then((body: AdvantivReport) => {
                    let data = body;
                    handle_response_data(data)                    
                });
            }

        });
    }

    const formatCurrency = (value) => {
        // return value
        if (value != undefined) {
            return value.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
        }
    }

    const formatNumber = (value) => {
        if (value != undefined) {
            return value.toLocaleString('nl-NL');
        }
    }

    const collectCampaigns = () => {
        const advantivService = new AdvantivService();

        advantivService.listCampaigns(selectedProject.id).then((response: ApiResponse<PaginatedResponse<ICampaign[]>>) => {
            if (!response.hasError()) {
                response.getBody().then((paginatedResponse: PaginatedResponse<ICampaign[]>) => {
                    setCampaigns(paginatedResponse.data);
                    setLoadCampaigns(false)
                    if (advantivCampaignStore.selectedCampaignId != undefined) {
                        let filterdCampaign = paginatedResponse.data.find(e => e.id == advantivCampaignStore.selectedCampaignId)
                        if (filterdCampaign != undefined) {
                            setSelectedCampaignAction(filterdCampaign)
                        }
                    }
                })
            }
        });
    }

    const refreshCampaign = () => {
        collectCampaigns()
    }

    const confirmDeleteItem = (item) => {
        // TODO
        // setItem(item);
        // setDeleteItemDialog(true);
    }

    const wrappedCurrencyTemplate = (item) => {
        let rowData = item['rowData']
        let key = item['key']
        return (<span>{formatCurrency(rowData[key])}</span>)
    }

    const wrappedNumberTemplate = (item) => {
        let rowData = item['rowData']
        let key = item['key']
        return (<span>{formatNumber(rowData[key])}</span>)
    }

    const costBodyTemplate = (rowData) => { return wrappedCurrencyTemplate({ "rowData": rowData, 'key': 'cost' }) }

    const tvfBodyTemplate = (rowData) => { return wrappedNumberTemplate({ "rowData": rowData, 'key': 'TVF' }) }

    const cpmBodyTemplate = (rowData) => { return wrappedNumberTemplate({ "rowData": rowData, 'key': 'CPM' }) }

    const scaleFactorBodyTemplate = (rowData) => { return wrappedNumberTemplate({ "rowData": rowData, 'key': 'scaleFactor' }) }

    const booleanTemplate = (value) => {
        if (value) {
            return <i className="pi pi-check" style={{ color: 'green' }}></i>
        } else {
            return <i className="pi pi-times" style={{ color: 'red' }}></i>
        }
    }

    const enabledBodyTemplate = (rowData) => { 
        return booleanTemplate(rowData['enabled']) 
    }

    const cappingBodyTemplate = (rowData) => { 
        return booleanTemplate(rowData['capping']) 
    }

    const lockedBodyTemplate = (rowData) => { 
        return booleanTemplate(rowData['locked'])
    }

    const textEditor = (options) => {
        return <InputText style={{ width: '80%' }} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const numberEditor = (options, min = 0, max = 100) => {
        return <InputNumber className="inputOnDataTableSmall" min={min} max={max} type="decimal" locale="nl-NL" maxFractionDigits={4} value={options.value} onChange={(e) => options.editorCallback(e.value)} />;
    }

    const currencyEditor = (options) => {
        return <InputNumber className="inputOnDataTableSmall" value={options.value} onChange={(e) => options.editorCallback(e.value)} required autoFocus mode="currency" currency="EUR" locale="nl-NL" maxFractionDigits={0} />
    }

    const booleanOptions = [
        {
            label: 'True',
            value: true
        },
        {
            label: 'False',
            value: false
        }
    ]
    
    const booleanEditor = (options) => {
        return <Dropdown style={{ width: '5em' }} options={booleanOptions} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    const deleteCampaign = () => {
        const advantivService = new AdvantivService();
        advantivService.deleteCampaign(selectedProject.id, selectedCampaign?.id!).then((response: ApiResponse<void>) => {
            if (response.hasError()) {
                alert("Failed to delete the campaign");
            } else {
                navigate(0);
            }
        });
    }

    const confirmDeleteCampaign = () => {
        confirmDialog({
            header: 'Delete campaign',
            message: 'Are you sure you want to delete the campaign? Doing so removes the campaign irreversibly',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-secondary',
            accept: () => { deleteCampaign() },
            reject: () => { }
        });
    }

    const addChannel = () => {
        setChannelNewItemDialog(true)
    }

    const TvfHeader = () => {
        return (
            <div>
                <label htmlFor="tvf">TVF
                    <i className="pi pi-question" onClick={(e) => setVisibleTVFInfo(true)}></i>
                </label>
                <Sidebar visible={visibleTVFInfo} onHide={() => setVisibleTVFInfo(false)} position="right">
                    The TV factor is the number of impressions needed to have the same impact as one TV commercial.
                    The TV factor therefore translates the online reach into a TV reach.
                    With a TV factor of 1, the TV reach is equal to the online reach.
                    The higher the TV factor, the more costs have to be incurred for the same TV range
                </Sidebar>
            </div>
        )
    }

    const CpmHeader = () => {
        return (
            <div>
                <label htmlFor="cpm">CPM
                    <i className="pi pi-question" onClick={(e) => setVisibleCPMInfo(true)}></i>
                </label>
                <Sidebar visible={visibleCPMInfo} onHide={() => setVisibleCPMInfo(false)} position="right">
                    Cost Per Mille, cost per 1000 impressions. <br />
                    A higher CPM leads to proportionally fewer impressions and therefore to a lower reach at the same cost level
                </Sidebar>
            </div>
        )
    }

    const ScaleFactorHeader = () => {
        return (
            <div>
                <label htmlFor="scaleFactor">Scalefactor
                    <i className="pi pi-question" onClick={(e) => setVisibleScalefactorInfo(true)}></i>
                </label>
                <Sidebar visible={visibleScalefactorInfo} onHide={() => setVisibleScalefactorInfo(false)} position="right">
                    This factor allows to scale down the audience. For instance.
                    If the B2B users are to be reached and 20% of the channel or placements audience is expected to belong to B2B. This factor should be set to 0.2
                </Sidebar>
            </div>
        )
    }

    const BudgetLockerHeader = () => {
        return (
            <div>
                <label htmlFor="locked">Budget Locked
                    <i className="pi pi-question" onClick={(e) => setVisiblesetBudgetLockedfactorInfo(true)}></i>
                </label><br />
                <Sidebar visible={visiblebudgetLockedfactorInfo} onHide={() => setVisiblesetBudgetLockedfactorInfo(false)} position="right">
                    To distribute the costs as optimally as possible, an algorithm is used within Advantiv to make this distribution.
                    This algorithm takes the selected channel and age group into account and then spreads the cost across channels and age groups.
                    You can also choose to set a maximum on the number of costs per channel.
                    The algorithm will take into account the ceiling you set and distribute the rest of the budget as optimally as possible.
                </Sidebar>
            </div>
        )
    }

    const hexToRGB = (hex) => {
        hex = '0x' + hex

        let r = hex >> 16 & 0xFF
        let g = hex >> 8 & 0xFF
        let b = hex & 0xFF

        return `rgb(${r}, ${g}, ${b})`
    }

    const FrequencyCappingHeader = () => {
        return (
            <div>
                <label htmlFor="frequency_capping">Frequency capping
                    <i className="pi pi-question" onClick={(e) => setVisibleFrequencyCappingInfo(true)}></i>
                    <Tooltip target=".custom-target-icon" />
                </label><br />
                <Sidebar visible={visibleFrequencyCappingInfo} onHide={() => setVisibleFrequencyCappingInfo(false)} position="right">
                    By setting the frequency capping, a maximum is added to the number of impressions that users are allowed to receive.
                    When this maximum is reached, the following number of impressions are assigned to other users, which increases the total reach.
                    As soon as the frequency capping reaches enable, you will see which maximum impressions are used.
                    In combination with the waste factor, this number is calculated in order to achieve your goals as optimally as possible.
                </Sidebar>
            </div>
        )
    }

    const CostEnabledHeader = () => {
        return (
            <div>
                <label htmlFor="enabled">Enabled
                    <i className="pi pi-question" onClick={(e) => setVisibleEnabledInfo(true)}></i>
                </label><br />
                <Sidebar visible={visibleEnabledInfo} onHide={() => setVisibleEnabledInfo(false)} position="right">
                    By setting the enabled, you make it possible by excluding this channel from the model without removing the data.
                </Sidebar>
            </div>
        )
    }

    const channelNewItemDialogHide = () => {
        setChannelNewItemDialog(false)
    }

    const channelNewItemDialogAdd = () => {
        let newChannel = channels.find(e => e.id == newCampaignChannel)
        if (newChannel !== undefined && selectedCampaign != undefined) {
            let advantivService = new AdvantivService()
            advantivService.createCampaignChannel(selectedProject.id, selectedCampaign.id, {
                name: newChannel.name,
                TVF: 1,
                CPM: 1,
                cost: 0,
                capping: false,
                type: newChannel.id,
                enabled: true,
                scaleFactor: 0,
                locked: false
            } as EditChannelRequest).then(resp => {
                if (!resp.hasError()) {
                    resp.getBody().then(e => {
                        selectedCampaign.channels.push(e)
                        setSelectedCampaign(selectedCampaign)
                        filterChannels(selectedCampaign, channels)
                    })
                }
            })
        }
        setChannelNewItemDialog(false)
    }

    const filterChannels = (campaign: ICampaign, channels: IChannel[]) => {
        const tmpFilterdChannels: { label: string, value: string }[] = []
        channels.forEach(item => {
            let k = {
                label: item['name'] as string,
                value: item['id'] as string
            }
            if (campaign?.channels.findIndex(e => e.type == k.value) == -1) {
                tmpFilterdChannels.push(k)
            }
        })
        setFilterdChannels(tmpFilterdChannels)
    }

    const channelNewItemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={channelNewItemDialogHide} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={channelNewItemDialogAdd} />
        </React.Fragment>
    )

    const onRowEditCompleteChannels = (editObject) => {
        const advantivService = new AdvantivService();
        let editChannelIndex = selectedCampaign?.channels.findIndex(e => e.id == editObject.newData.id)

        if (editObject.newData.id != undefined && selectedCampaign != undefined && editChannelIndex != undefined) {
            selectedCampaign.channels[editChannelIndex] = editObject.newData
            editObject.newData.projectId = selectedCampaign.id
            advantivService.editCampaignChannel(selectedProject.id, selectedCampaign.id, editObject.newData).then(e => {
                // Display message successful update
                // toast.current.show({severity:'success', summary: 'Success Message', detail:'Message Content', life: 3000});
            })
            setSelectedCampaign(selectedCampaign)
        } else {
            console.log("ERROR 3 zoek dit op")
        }
    }

    const onRowEditCompleteTvBaseline = (editObject) => {
        const advantivService = new AdvantivService();
        let tvBaselineIndex = selectedCampaign?.tvbaseline.findIndex(e => e.id == editObject.newData.id)
        if (editObject.newData.id != undefined && selectedCampaign != undefined && tvBaselineIndex != undefined) {
            selectedCampaign.tvbaseline[tvBaselineIndex] = editObject.newData
            editObject.newData.projectId = selectedCampaign.id
            advantivService.editCampaignTVBaseline(selectedProject.id, selectedCampaign.id, editObject.newData).then(e => {

            })
            setSelectedCampaign(selectedCampaign)
        }

    }

    const deleteSelectedChannel = () => {
        const advantivService = new AdvantivService();
        let _channels = selectedCampaign?.channels.filter(val => !selectedChannels.includes(val));
        for (let index = 0; index < selectedChannels.length; index++) {
            const element = selectedChannels[index];
            advantivService.deleteCampaignChannel(selectedProject.id, selectedCampaign?.id, element.id)
        }
        setSelectedChannels([])
        if (selectedCampaign?.channels != undefined && _channels != undefined) {
            selectedCampaign.channels = _channels
            setSelectedCampaign(selectedCampaign)
            setSelectedChannels([])
        }
    }

    const addTVBaseline = () => {
        if (selectedCampaign != undefined) {
            let advantivService = new AdvantivService()
            if (selectedCampaign.tvbaseline.length <= 14) {
                let lastAge = 6
                let label = "agegroup"
                if (selectedCampaign.tvbaseline.length < 9) {
                    label += "0" + (selectedCampaign.tvbaseline.length + 1)
                } else {
                    label += (selectedCampaign.tvbaseline.length + 1)
                }
                let agePlus = 10
                if (selectedCampaign.tvbaseline.length >= 1) {
                    lastAge = selectedCampaign.tvbaseline[selectedCampaign.tvbaseline.length - 1].minimumAge
                }
                if (lastAge >= 65) {
                    agePlus = 1
                }
                let age = lastAge + agePlus
                if (selectedCampaign.tvbaseline)
                    advantivService.createCampaignTVBaseline(selectedProject.id, selectedCampaign.id, {
                        minimumAge: age,
                        label: label,
                        percentage: 50
                    } as EditTVBaselineRequest).then(resp => {
                        if (!resp.hasError()) {
                            resp.getBody().then(e => {
                                let campaign = selectedCampaign
                                campaign.tvbaseline.push(e)
                                setSelectedCampaign(campaign)
                            })

                        }
                    })
            }

        }
    }

    const addNewCampaign = () => {
        setDisplayCreateCampaignDialog(true)
    }

    const deleteTvBaseline = (e) => {
        if (selectedCampaign != undefined && selectedCampaign?.tvbaseline.length > 1) {
            if (e.id == selectedCampaign?.tvbaseline[selectedCampaign?.tvbaseline.length - 1].id) {
                return <Button className="p-button-danger" label="Delete" onClick={() => deleteLastTvBaselineItem(e.id)}></Button>
            }
        }
        return ""
    }

    const deleteLastTvBaselineItem = (id) => {
        const advantivService = new AdvantivService();
        let _items = selectedCampaign?.tvbaseline.filter(val => val.id != id);
        if (selectedCampaign != undefined) {
            advantivService.deleteCampaignTVBaseline(selectedProject.id, selectedCampaign?.id, id)
        }

        if (selectedCampaign?.tvbaseline != undefined && _items != undefined) {
            selectedCampaign.tvbaseline = _items
            setSelectedCampaign(selectedCampaign)
        }
    }

    const channelHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Manage Channels</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onChange={(e) => setGlobalChannelFilter(e.target.value)} placeholder="Search..." />
            </span>
            <Button icon="pi pi-plus" className="p-button p-button-success mr-2" onClick={() => addChannel()} value="Add channel" />
            <Button icon="pi pi-minus" className="p-button p-button-danger mr-2" onClick={() => deleteSelectedChannel()} disabled={!selectedChannels || !selectedChannels.length} value="Delete channels" />
        </div>
    )

    const tvBaselineHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Manage Tv Baselines</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onChange={(e) => setGlobaltTvBaselineFilter(e.target.value)} placeholder="Search..." />
            </span>
            <Button icon="pi pi-plus" className="p-button p-button-success mr-2" onClick={() => addTVBaseline()} value="Add TV Baseline" />
        </div>
    )

    const renderPercentage = (percentage: number, fractionDigits: number = 2): string => {
        return Number(percentage).toLocaleString(undefined, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        }) + '%';
    }

    const colorTemplate = (rowData: any) => {
        return <div style={{ width: '20px', height: '20px', backgroundColor: rowData.color, borderRadius: '50%' }}></div>
    }

    const currencyTemplate = (rawData: any) => {
        return formatCurrency(rawData?.value)
    }
    return (
        <>
            <Navbar activeTool={'Advantiv'} activePage={'campaign'} />
            <div className="layout-application-window" style={{ padding: '0px 40px' }}>


                {/* The top section of the campaigns page. */}
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                    <h1 style={{ margin: '50px 0 0 0', padding: '0', fontWeight: '500', color: '#000' }}>Explore your campaigns</h1>
                    <h5 style={{ margin: '5px 0 0 0', padding: '0', fontWeight: '400', color: '#525252' }}>Select a campaign from the list below to view it.</h5>

                    <Button style={{ width: '160px', marginTop: '20px', marginRight: "5px", paddingTop: '8px', paddingBottom: '8px' }} className="p-button-secondary" label="Add new campaign" onClick={() => addNewCampaign()} />

                    <Dropdown
                        value={selectedCampaign}
                        options={campaigns.map((campaign) => ({ label: campaign.name, value: campaign }))}
                        filter
                        filterBy="label"
                        placeholder="Campaign"
                        onChange={(e) => {
                            setSelectedCampaignAction(e.target.value as ICampaign)
                        }}
                        style={{ marginTop: '20px' }}
                    />
                </div>


                {/* This is the dashboard section. */}
                {
                    selectedCampaign && !loadCampaigns && !loadCampaigns && !loadingTvBaselineItems ?
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            


                            {/* These are the 4 cards at the top.  */}
                            <div className="advantiv-overview-row">

                                <Card className="advantiv-overview-card" title="Campaign Overview" subTitle='View your campaign details'>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>

                                            {/*  Header (With icon + name)  */}
                                            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                                <p>
                                                    <span><i className='fa-solid fa-bullseye' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                                    <span style={{ marginLeft: '10px' }}>{selectedCampaign?.name}</span>
                                                </p>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <Tooltip target=".run-campaign" content='Click here to run the campaign' position="top" />
                                                    <Tooltip target=".edit-campaign" content='Click here to edit the campaign' position="top" />
                                                    <Tooltip target=".delete-campaign" content='Click here to delete the campaign' position="top" />

                                                    <i onClick={() => runCampaign()} className='fa fa-play run-campaign' style={{ cursor: 'pointer', marginLeft: 'auto', color: 'rgba(27,118,183,0.76)' }} />
                                                    <i onClick={() => setDisplayEditCampaignDialog(true)} className="fa-regular fa-pen-to-square edit-campaign" style={{ cursor: 'pointer', marginLeft: '10px', color: 'rgba(27,118,183,0.76)' }} />
                                                    <i onClick={() => { confirmDeleteCampaign() }} className="fa-regular fa-trash-can delete-campaign" style={{ cursor: 'pointer', marginLeft: '10px', color: 'rgba(27,118,183,0.76)' }} />
                                                </div>
                                            </div>

                                            {/* Campaign Description */}
                                            <div style={{ padding: '20px 0', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                                <p style={{ color: '#636c79' }}>
                                                    {selectedCampaign?.description ?? "                                        " +
                                                        "Here is a description about the campaign, why it is created and what it is used for.\n" +
                                                        "You can use this space to write a description about the campaign. This is some filler " +
                                                        "content to show how the description will look like."}

                                                </p>
                                            </div>

                                            {/*  Property List  */}
                                            <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>

                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Type</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.type}</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Value</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.value}</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Start Date</p>
                                                    <p style={{ marginLeft: 'auto' }}>{new Date(selectedCampaign?.date!).toUTCString()}</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Duration</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.duration} weeks</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Age range</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.minimumAge} - {selectedCampaign?.maximumAge}</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Contact Frequency</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.minimalTVF}</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                                    <p style={{ minWidth: '20%', color: '#636c79' }}>Country</p>
                                                    <p style={{ marginLeft: 'auto' }}>{selectedCampaign?.countryCode}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </Card>

                                { renderReport ?
                                    <div className="advantiv-number-cards">

                                        <Card className="advantiv-number-card" title="Target Audience" subTitle={`ages ${reachStats.targetAudience.ages.min}-${reachStats.targetAudience.ages.max}`}>
                                            <span>{renderAbsolute(reachStats.targetAudience.abs)} ({renderPercentage(100)})</span>
                                        </Card>

                                        <Card className="advantiv-number-card" title="Digital Reach" subTitle='TV equivalent'>
                                            <span>{renderAbsolute(reachStats.digitalReach.abs)} ({renderPercentage(reachStats.digitalReach.perc)})</span>
                                        </Card>

                                        <Card className="advantiv-number-card" title="Digital Reach Improvement">
                                            <span>{renderAbsolute(reachStats.improvement.abs)} ({renderPercentage(reachStats.improvement.perc)})</span>
                                        </Card>
                                    </div>
                                :  <div style={{marginBottom: '10px'}}>
                                        <button onClick={() => runCampaign()} className="p-button p-button-warning mr-2">Calculate campaign <span className='fa fa-play run-campaign' style={{ cursor: 'pointer', marginLeft: '10px' }} /></button>
                                    </div>
                                }
                                { renderReport ?
                                    <Card className="advantiv-cost-table-card" title="Cost" subTitle="View your budget">
                                        {costStats.costs ?
                                            (
                                                <DataTable value={costStats.costs} size='small' showGridlines={true}
                                                    stripedRows={true}>
                                                    <Column header="Color" body={colorTemplate}></Column>
                                                    <Column field="label" header="Label"></Column>
                                                    <Column field="value" header="Value" body={currencyTemplate}></Column>
                                                    <Column field="perc" header="Pecentage"></Column>
                                                </DataTable>
                                            ) : null
                                        }
                                        <p style={{ marginTop: '20px' }}><b>Total: {formatCurrency(costStats.total)}</b></p>
                                    </Card>
                                : null}
                            </div>
                            
                            { renderReport ?
                            <div>
                                {/* Reach */}
                                <div className="advantiv-chart-row">
                                    <AdvantivChart 
                                        title="Reach TV + Digital (In percentages)" 
                                        options={reachPerc.options}
                                        data={reachPerc.data}
                                    />

                                    <AdvantivChart 
                                        title="Reach TV + Digital (In numbers)"
                                        options={reachAbs.options}
                                        data={reachAbs.data}
                                    />
                                </div>

                                {/* Users */}
                                <div className="advantiv-chart-row">
                                    <AdvantivChart 
                                        title="Users per channel (In numbers)" 
                                        options={users.options}
                                        data={users.data}
                                    />

                                    <AdvantivChart 
                                        title="Users per channel (In percentages)" 
                                        options={usage.options}
                                        data={usage.data}
                                    />
                                </div>

                                {/* Daily active users */}
                                <div className="advantiv-chart-row">
                                    <AdvantivChart 
                                        title="Reach per Channel (In percentages)" 
                                        options={reachChannelPerc.options}
                                        data={reachChannelPerc.data}
                                    />

                                    <AdvantivChart 
                                        title="Daily active users per channel (In percentages)" 
                                        options={dau.options}
                                        data={dau.data}
                                    />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                                    <Card style={{ width: '100%', marginBottom: '10px'}} title="Digital Reach Improvement">
                                        <DataTable value={plan} responsiveLayout="scroll" showGridlines={true} size="small"
                                            stripedRows={true}>
                                            <Column field="label" header="Channel"></Column>
                                            <Column field="cost" header="Cost" body={plan_cost_template}></Column>
                                            <Column field="cpm" header="CPM" body={plan_cpm_template}></Column>
                                            <Column field="tvf" header="TVF" body={plan_tvf_template}></Column>
                                            <Column field="tv_reach_abs" header="TV Reach#" body={plan_tv_reach_abs_template}></Column>
                                            <Column field="tv_reach_perc" header="TV Reach%" body={plan_tv_reach_perc_template}></Column>
                                            <Column field="dimpr" header="Dimpr" body={plan_dimpr_template}></Column>
                                            <Column field="dr_abs" header="DR#" body={plan_dr_abs_template}></Column>
                                            <Column field="dr_perc" header="DR%" body={plan_dr_perc_template}></Column>
                                            <Column field="dgcf" header="DGCF" body={plan_DGCF_template}></Column>
                                        </DataTable>
                                    </Card>

                                </div>
                            </div>
                            : null }
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                                <Card style={{ width: '100%', marginBottom: '10px' }} title="Channels">

                                    <DataTable value={selectedCampaign.channels} editMode="row" dataKey="id" onRowEditComplete={onRowEditCompleteChannels}
                                        responsiveLayout="scroll" size='small' header={channelHeader} paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                        globalFilterFields={['name']} globalFilter={globalChannelFilter}
                                        showGridlines={true}
                                        stripedRows={true}
                                        selection={selectedChannels} onSelectionChange={(e) => setSelectedChannels(e.value as any)}>
                                        <Column selectionMode="multiple" headerStyle={{ width: '2rem' }} exportable={false}></Column>
                                        <Column field="name" header="Name" style={{ width: '10rem' }} />
                                        <Column field="TVF" header={TvfHeader} editor={(options) => numberEditor(options, 0.005, 130)} body={tvfBodyTemplate} />
                                        <Column field="CPM" header={CpmHeader} editor={(options) => numberEditor(options, 0.001, 100)} body={cpmBodyTemplate} />
                                        <Column field="scaleFactor" header={ScaleFactorHeader} editor={(options) => numberEditor(options, 0, 1)} body={scaleFactorBodyTemplate} />
                                        <Column field="cost" header="Cost" editor={(options) => currencyEditor(options)} body={costBodyTemplate} />
                                        <Column field="locked" header={BudgetLockerHeader} editor={(options) => booleanEditor(options)} body={lockedBodyTemplate} />
                                        <Column field="capping" header={FrequencyCappingHeader} editor={(options) => booleanEditor(options)} body={cappingBodyTemplate} />
                                        <Column field="enabled" header={CostEnabledHeader} editor={(options) => booleanEditor(options)} body={enabledBodyTemplate} />
                                        <Column rowEditor header bodyStyle={{ textAlign: 'center' }}></Column>
                                    </DataTable>

                                    <Dialog visible={channelNewItemDialog} style={{ width: '450px' }} header="Add a channel" modal className="p-fluid" footer={channelNewItemDialogFooter} onHide={channelNewItemDialogHide}>
                                        <h1>Select a Channel</h1>
                                        <Dropdown style={{ width: '15em' }}
                                            options={filtertedChannels}
                                            value={newCampaignChannel}
                                            placeholder={"Select a channel"}
                                            onChange={(e) => {
                                                setNewCampaignChannel(e.target.value)
                                            }} />

                                    </Dialog>
                                </Card>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

                                <Card style={{ width: '100%', marginBottom: '10px' }} title="TV Baseline">

                                    <DataTable value={selectedCampaign.tvbaseline}
                                        editMode="row"
                                        dataKey="id"
                                        showGridlines={true}
                                        stripedRows={true}
                                        responsiveLayout="scroll"
                                        size='small'
                                        selection={selectedTVBaseline} onSelectionChange={(e) => setSelectedTVBaseline(e.value as any)}
                                        onRowEditComplete={onRowEditCompleteTvBaseline}
                                        header={tvBaselineHeader} paginator rows={16}
                                        globalFilter={globaltTvBaselineFilter} globalFilterFields={['label']}
                                    >
                                        <Column field="label" header="Label" style={{ width: '12rem' }} />
                                        <Column field="minimumAge" header="From Age" editor={(options) => numberEditor(options)} style={{ width: '7rem' }} />
                                        <Column field="percentage" header="Percentage" editor={(options) => numberEditor(options)} style={{ width: '7rem' }} />
                                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column body={deleteTvBaseline} headerStyle={{ minWidth: '2rem' }}></Column>
                                    </DataTable>
                                </Card>
                            </div>

                        </div> : null
                }

                <Dialog header='Create new campaign' visible={displayCreateCampaignDialog} onHide={() => { setDisplayCreateCampaignDialog(false) }}>
                    <CreateCampaignDialogComponent setDialogVisible={setDisplayCreateCampaignDialog} />
                </Dialog>

                <Dialog header='Edit campaign' visible={displayEditCampaignDialog} onHide={() => { setDisplayEditCampaignDialog(false); refreshCampaign(); }}>
                    <EditCampaignDialogComponent campaign={selectedCampaign!} setDialogVisible={setDisplayEditCampaignDialog} />
                </Dialog>

                {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
                <ConfirmDialog />
            </div>
        </>
    )

}


export default AdvantivCampaignOverview;
