/**
 * Copyright 2022 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import { ApiError } from "./api-error";

/**
 * This is a generic response interface that will contain the response of an API request.
 *
 * It contains a generic type, which is the type of response that will be generated in the success case.
 * In case of a failure, our standard error model is return.
 */
export class ApiResponse<T> {

    private readonly response: Response;

    constructor(response: Response) {
        this.response = response;
    }

    public hasError = (): boolean => {
        // Checks if the response is in the 200-299 status code range, if not then it's an error.
        return !this.response.ok;
    }

    public async getBody(): Promise<T> {
        return await this.response.json() as T;
    }

    public async getError(): Promise<ApiError> {
        return await this.response.json() as ApiError;
    }

}
